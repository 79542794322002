import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  getDocs,
  query,
  collection,
  getDoc,
  doc
} from "firebase/firestore";
import { useAuth } from "../../context/context";
import { Table, Space, Button } from "antd";
import { useNavigate } from "react-router-dom";
import '../checkout/SelectPaymentType.css';

const Tickets = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [rifas, setRifas] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let queryRifasIds = query(collection(db, `user/${user.uid}/events`));
      const querySnapshot = await getDocs(queryRifasIds);
      let idRifas = [];
      querySnapshot.forEach((doc) => {
        idRifas.push({ ...doc.data(), id: doc.id });
      });
      let data = [];
      for(let i = 0; i < idRifas.length; i++) {
        const docRef = doc(db, "event", idRifas[i].id);
        const docSnap = await getDoc(docRef);
        data.push({ id: docSnap.id, ...docSnap.data() })
      }
      setRifas(data)
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: "Fecha y Hora de Sorteo",
      key: "endDate",
      dataIndex: "endDate",
      render: (value, record, index) => {
        let endDate = record.endDate;
        let time;
        const fireBaseTime = new Date(
          endDate.seconds * 1000 + endDate.nanoseconds / 1000000
        );
        const date = fireBaseTime.toLocaleDateString("es-MX");
        const atTime = fireBaseTime.toLocaleTimeString("es-MX");
        time = atTime.slice(0, 5);
        return (
          <>
            {date} a las {time}
          </>
        );
      },
    },
    {
      title: "Opciones",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button 
            onClick={() =>
              navigate(`/tickets/${record.id}`, {
                state: {
                  ...record,
                },
              })
            } 
            type="link"
          >
            Ver detalles
          </Button>
        </Space>
      ),
    }
  ];

  return (
    <div style={{padding: "2rem 2rem 17rem 2rem"}}>
      <h1>Mis tickets</h1>
      <Table dataSource={rifas} columns={columns} rowKey="id" pagination={{ pageSize: 10, showSizeChanger: false }}/>      
    </div>
  )
};

export default Tickets;