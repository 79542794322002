import React, { useEffect, useState } from "react";
import { Table, Space, Button, Switch, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  query,
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import "./EventListUsersAdmin.css";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../functions/user";

const EventListUsersAdmin = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);

  const getData = async () => {
    const queryEvents = query(
      collection(db, "user")
    );
    const querySnapshot = await getDocs(queryEvents);
    let newDocs = [];
    querySnapshot.forEach((doc) => {
      newDocs.push({ ...doc.data(), id: doc.id });
    });
    setUser(newDocs);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleUserState = async (user) => {
    let check;
    if (user.isActive === false) {
      check = true;
    } else {
      check = false
    }
    await updateUser(check, user.id);
    getData()
  };

  const columns = [
    {
      title: 'Nombre Completo',
      dataIndex: 'fullName',
      key: 'fullName',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div>
            <Input
              placeholder=""
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <button
              onClick={() => {
                confirm();
              }}
              className="btnSearch"
            >
              Buscar
            </button>
            <button
              onClick={() => {
                clearFilters();
                confirm({ closeDropdown: true });
              }}
              className="btnClear"
            >
              Limpiar
            </button>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        if(record.fullName != null){
          return record.fullName.toLowerCase().includes(value.toLowerCase());
        }
        
      },
    },
    {
      title: 'Correo',
      dataIndex: 'email',
      key: 'email',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div>
            <Input
              placeholder=""
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <button
              onClick={() => {
                confirm();
              }}
              className="btnSearch"
            >
              Buscar
            </button>
            <button
              onClick={() => {
                clearFilters();
                confirm({ closeDropdown: true });
              }}
              className="btnClear"
            >
              Limpiar
            </button>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        if(record.email != null){
          return record.email.toLowerCase().includes(value.toLowerCase());
        }
      },
    },
    {
      title: 'Teléfono',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div>
            <Input
              placeholder=""
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <button
              onClick={() => {
                confirm();
              }}
              className="btnSearch"
            >
              Buscar
            </button>
            <button
              onClick={() => {
                clearFilters();
                confirm({ closeDropdown: true });
              }}
              className="btnClear"
            >
              Limpiar
            </button>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        if(record.phoneNumber != null){
          return record.phoneNumber.includes(value);
        }
      },
    },
    {
      title: "Detalles",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() =>
              navigate(`/admin-events-users/${record.id}`, {
                state: {
                  ...record,
                },
              })
            }
            type="link"
          >
            Ver detalles
          </Button>
        </Space>
      ),
    },
    {
      title: "Activar/Desactivar",
      dataIndex: "isActive",
      key: "isActive",
      render: (text, record) => (
        <Space size="middle">
          <Switch
            checked={record.isActive}
            onChange={() => handleUserState(record)}
          >
          </Switch>
        </Space>
      ),
    },
  ];

  return (
    <div className="container-list-users-admin">
      <h1>Usuarios</h1>
      <Table
        dataSource={user}
        columns={columns}
        rowKey="id" />
    </div>
  )
};

export default EventListUsersAdmin;
