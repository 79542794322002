import React from "react";
import "../LandingPage.css";

const ItemHowWorks = ({image, desc, alt}) => {
  return (
    <div className="item-container-how-works">
      <img src={image} className="img-how-works" alt={alt}/>
      <p className="txt-how-works">{desc}</p>
    </div>
  )   
};

export default ItemHowWorks;
