import React, { createContext, useContext, useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
} from "firebase/auth";
import { auth, db } from "../firebase";
import { getDoc, doc } from "firebase/firestore";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { message } from "antd";

export const authContext = createContext();

export const useAuth = () => {
  const context = useContext(authContext);
  return context;
};

function setUpRecaptcha(number) {
  if(!window.recaptchaVerifier){
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container", 
      {'size': 'invisible'},
    );
    window.recaptchaVerifier.render();    
  }
  else{
    window.recaptchaVerifier.render();
  }
  return signInWithPhoneNumber(auth, number, window.recaptchaVerifier);
}  

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [cart, setCart] = useState(null);

  const saveTickets = (values) => {
    setCart(values);
  };

  const getUserInfo = (uid) => {
    getRol(uid).then((userData) => {
      setUserInfo(userData);
    });
  };

  const signup = (email, password) =>
    createUserWithEmailAndPassword(auth, email, password);

  const login = (email, password) =>
    signInWithEmailAndPassword(auth, email, password);

  const logout = () => signOut(auth);

  const loginWithGoogle = () => {
    const googleProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleProvider);
  };

  const loginWithFacebook = () => {
    const fbProvider = new FacebookAuthProvider();
    return signInWithPopup(auth, fbProvider);
  };

  async function getRol(uid) {
    const docRef = doc(db, "user", uid);
    const docSnap = await getDoc(docRef);
    const userData = docSnap.data();
    const userExists = docSnap.exists();
    if(userExists) {
      if(!userData.isActive) {
        setUser(null);      
        setUserInfo(null);
        logout();
        setLoading(false);
        message.error("Error al iniciar sesión");
        return;
      }
    }    
    return docSnap.data();
  };

  function setUserWithFirebaseAndRol(usuarioFirebase) {
    getRol(usuarioFirebase.uid).then((userData) => {
      setUserInfo(userData);
      setUser(usuarioFirebase);
      setLoading(false);
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setLoading(true);
        if (!user) {
          setUserWithFirebaseAndRol(currentUser);
        }
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <authContext.Provider
      value={{
        signup,
        login,
        loading,
        logout,
        user,
        userInfo,
        loginWithGoogle,
        loginWithFacebook,
        cart,
        saveTickets,
        getUserInfo,
        setUpRecaptcha
      }}
    >
      {children}
    </authContext.Provider>
  );
};