const passwordValidator = (rule, value) => {
  let lowerCase = new RegExp("(?=.*[a-z])");
  let upperCase = new RegExp("(?=.*[A-Z])");
  let number = new RegExp("(?=.*[0-9])");
  return new Promise((resolve, reject) => {
    if (!value.match(lowerCase)) {
      return reject("La contraseña debe tener una letra minúscula");
    }
    if (!value.match(upperCase)) {
      return reject("La contraseña debe tener una letra mayuscula");
    }
    if (!value.match(number)) {
      return reject("La contraseña debe tener un número");
    }
    if (value.length < 6) {
      return reject(`Faltan ${6 - value.length} caractéres`);
    }
    resolve();
  });
};

export { passwordValidator };