import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Modal,
    message,
} from 'antd';
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import "./RegisterEvent.css";
import "./RegisterEventCustomer.css";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { createInteresedUser } from '../../functions/user';

const RegisterEventCustomer = () => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [emailModal, setEmailModal] = useState("");

    const onFinish = async (values) => {
        if (values.email === "") {
            setModalError(true);
            return;
        }
        if (values.phoneNumber.length !== 10) {
            message.error("Número de teléfono inválido", 7);
            setModalError("Número de teléfono inválido");
            return
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            message.error("Dirección de correo inválida", 7);
            setModalError("Dirección de correo inválida");
            return;
        } else {
            setModalError(false);
            setEmailModal("");
            setModalVisible(true)
            const id = uuidv4();
            const data = {
                email: values.email,
                name: values.name,
                phoneNumber: "+52" + values.phoneNumber,
            }
            await createInteresedUser(data, id);
        }
    }

    return (

        <div className='container-create-rifa-custom'>
            <Grid item xs={12} md={6} lg={6}>
                <Paper sx={{ maxHeight: 400 }} style={{ paddingBottom: 10, alignItems: "center", borderRadius: 20, boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)", backgroundColor: "#FFFFFF", borderColor: "#000000" }}
                    onClick={(e) => ("/usersPanel")}>
                    <Stack direction="row" justifyContent="initial" spacing={1} sx={{ ml: 2, pt: 2.5 }}>
                        <Typography sx={{ fontSize: 18, pt: .5 }} color={'#000000'} align="left" >
                            Gracias por interesarte en la creación de una rifa dentro de nuestra plataforma,
                            para continuar con el proceso es necesario que ingreses tus datos de contacto.
                        </Typography>
                    </Stack>
                </Paper>
            </Grid><br></br>
            <Form
                name="registerInteresed"
                layout="vertical"
                onFinish={onFinish}
                requiredMark={false}
            >
                <Form.Item
                    label="Nombre"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Ingresa tu nombre completo",
                        },
                    ]}
                >
                    <Input className='inputRegister' />
                </Form.Item>
                <Form.Item
                    label="Número télefonico"
                    name="phoneNumber"
                    rules={[
                        {
                            required: true,
                            message: "Ingrese su número de télefono",
                        },
                        () => ({
                            validator(_, value) {
                                if (!value) {
                                    return Promise.reject();
                                }
                                if (isNaN(value)) {
                                    return Promise.reject("Solo debe contener números");
                                }
                                if (value.toString().length !== 10) {
                                    return Promise.reject(
                                        "El número debe ser de 10 dígitos"
                                    );
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Input
                        className="inputRegister"
                        prefix="+52"
                    />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Ingresa tu correo electrónico",
                        },
                    ]}
                >
                    <Input className='inputRegister' />
                </Form.Item>
                {modalError && (
                    <span style={{ marginTop: "1rem", color: "#ff0000" }}>
                        {emailModal}
                    </span>
                )}

                <div className='container-row-custom '>
                    <div>
                        <Form.Item>
                            <Button htmlType="submit" className='btn-create-event-customer'>
                                Enviar
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
            <Modal
                title={false}
                open={modalVisible}
                closable={true}
                footer={false}
            >
                <p className='span-option-payment-title'>
                    Atte: Hay Rifa...
                </p>
                <p className='span-option-payment'>
                    Gracias nuestro equipo se pondrá comunicación contigo lo antes posible para continuar con tu proceso de creación de la rifa.
                </p>
                <div style={{ textAlign: "center" }}>
                    <button
                        key="finishInteresed"
                        className='btnChooseThis'
                        onClick={() => { navigate("/landing") }}
                    >
                        Continuar
                    </button>
                </div>
            </Modal>
        </div>

    )
};

export default RegisterEventCustomer;