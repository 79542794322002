import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  message,
} from "antd";
import "./Login.css";
import Typography from '@mui/material/Typography';
import { createUser, getUser } from "../functions/user";
import { auth, db } from "../firebase";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useAuth } from "../context/context";
import email from "../assets/email.png";
import { query, collection, where, getDocs } from "firebase/firestore";

const PhoneSignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showButton, setShowButton] = useState(false)
  const [showButtonName, setShowButtonName] = useState(true)
  const [countDown, setCountDown] = useState(60);
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [fullName, setFullName] = useState();
  const [result, setResult] = useState("");
  const [blockResendBttn, setBlockResendBttn] = useState(false);
  const { setUpRecaptcha, getUserInfo } = useAuth();

  const timerId = useRef();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  var confirmationResult;

  useEffect(() => {
    if (open) {
      timerId.current = setInterval(() => {
        setCountDown(prev => prev - 1);
      }, 1000)
      return () => clearInterval(timerId.current);
    }
  }, [open]);

  useEffect(() => {
    if (countDown <= 0) {
      clearInterval(timerId.current);
      handleClose();
    }
  }, [countDown]);

  const redirectUser = (uid) => {
    getUserInfo(uid);
    message.success("Sesión iniciada con exito", 5);
    if (location.state?.from?.pathname) {
      navigate(location.state.from.pathname);
      return;
    } else {
      return navigate("/landing", { replace: true });
    }
  };

  const requestOTP = async (value) => {
    var phoneNumberMx = "+52" + value.phoneNumber;
    const phone = parseInt(value.phoneNumber);
    const queryCellphone = query(
      collection(db, "user"),
      where("phoneNumber", "==", phone)
    );
    const querySnapshot = await getDocs(queryCellphone);
    if (!querySnapshot.empty) {
      return message.error("El número de celular ya esta en uso", 3);
    }
    setShowButton(true)
    setShowButtonName(false)
    setFullName(value.fullName)
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    try {
      setPhoneNumber(phoneNumberMx)
      confirmationResult = await signInWithPhoneNumber(auth, phoneNumberMx, appVerifier)
      setResult(confirmationResult)
    } catch (error) {
      message.error(error)
    }
    handleOpen();
  }

  const verifyOtp = async (value) => {
    result.confirm(value.otpCode).then(async (result) => {
      const uid = result.user.uid
      const userValid = await getUser(uid);
      const userExists = userValid.exists();
      if (userExists) {
        if(!userValid.data().isActive) {
          return;
        }
        return redirectUser(uid);
      }
      const values = {
        phoneNumber: phoneNumber,
        fullName: fullName,
        email: null
      }
      await createUser(values, "customer", uid);
      return redirectUser(uid);
    }).catch((error) => {
      console.log(error)
    });
  }

  const resendOtp = async (e) => {
    e.preventDefault();
    setBlockResendBttn(true);
    try {
      const response = await setUpRecaptcha(phoneNumber);
      setResult(response);
    } catch (err) {
      switch (err.code) {
        case "auth/invalid-phone-number":
          console.log(err.message);
          break;
        default:
        //window.location.reload(false);
      }
    }
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
      }
    })
  }



  return (
    <div className="containerRegister">
      <div className="container-phone-login">
        <div className="registerForm">
          {showButtonName && (
            <Form
              name="verifyNumber"
              layout="vertical"
              onFinish={requestOTP}
              requiredMark={false}>
              <Form.Item style={{ marginBottom: 0 }}>
              </Form.Item>
              <Form.Item
                label="Nombre Completo"
                name="fullName"
                htmlFor="fullName"
              >
                <Input className="inputRegister" id="fullName" />
              </Form.Item>
              <Form.Item style={{ marginBottom: 30 }}>
                <Form.Item
                  label="Número télefonico"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese su número de télefono",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject();
                        }
                        if (isNaN(value)) {
                          return Promise.reject("Solo debe contener números");
                        }
                        if (value.toString().length !== 10) {
                          return Promise.reject(
                            "El número debe ser de 10 dígitos"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    className="inputRegister"
                    prefix="+52"
                  />
                </Form.Item>
                <Button
                  className="btnLoginL"
                  style={{ marginTop: 0 }}
                  htmlType="submit"
                >
                  Verificar Número
                </Button>
              </Form.Item>
            </Form>
          )}
          {showButton && (
            <Form
              name="verifyOtp"
              layout="vertical"
              onFinish={verifyOtp}
              requiredMark={false}>
              <div>
                <Form.Item
                  label="OTP"
                  name="otpCode"
                  htmlFor="otpCode"
                >
                  <Input className="inputRegister" id="otpCode" />
                </Form.Item>
                <Form.Item style={{ marginBottom: 30 }}>
                  {open ?
                    <Typography sx={{ mt: 2 }} textAlign="center" component="h1" variant="h5" align="center" style={{ fontSize: "14px", color: 'GrayText' }}>
                      Reenviar código : {countDown}
                    </Typography> :
                    <>
                      {!blockResendBttn ?
                        <Button
                          className="btnLoginSocialOtp"
                          style={{ marginTop: 0 }}
                          onClick={(e) => resendOtp(e)}
                        >
                          Reenviar código
                        </Button> :
                        <Button
                          className="btnLoginSocialOtp"
                          style={{ marginTop: 0 }}
                          onClick={(e) => resendOtp(e)}
                        >
                          Código reenviado
                        </Button>
                      }
                    </>
                  }
                  &nbsp;
                  <Button
                    className="btnLoginL"
                    style={{ marginTop: 0 }}
                    htmlType="submit"
                  >
                    Verificar código OTP
                  </Button>
                </Form.Item>
              </div>
            </Form>
          )}
          <Link to="/login">
            <Button
              className="btnLoginSocialR"
              style={{ marginTop: 0 }}
              icon={<img src={email} alt="fb-logo" style={{ heigth: 35, width: 35, paddingRight: "10px", paddingBottom: "3px" }} />}
            >
              Ingresa con correo electrónico
            </Button>
          </Link>
          <div id="recaptcha-container"></div>
        </div>
      </div>
    </div>
  )
};

export default PhoneSignUp;
