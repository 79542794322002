import React, { useState, useEffect } from "react";
import CardWinnerItem from "../landingPage/components/CardWinnerItem";
import "../landingPage/LandingPage.css";
import "./Winners.css"
import { query, collection, getDocs, where } from "firebase/firestore";
import { db } from "../../firebase";

const WinnersEvents = () => {
    const [events, setEvents] = useState([]);
    useEffect(() => {
        const getData = async () => {
            const queryEvents = query(
                collection(db, "event"), where("status", "==", "closed")
            );
            const querySnapshot = await getDocs(queryEvents);
            let newDocs = [];
            querySnapshot.forEach((doc) => {
                newDocs.push({ ...doc.data(), id: doc.id });
            });
            setEvents(newDocs);
            
        };
        getData();
    }, []);

    return (
        <div className="container-all-events-winners">
        <div className="scrolling-wrapper-vertical">
            {events.map((item, index) => {
                return (
                    <div key={index}><CardWinnerItem item={item} /></div>
                )
            })}
        </div>
    </div>
        
    )
};

export default WinnersEvents;
