import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/context";
import fb from "../../assets/new-fb.png";
import whatsApp from "../../assets/new-whatsapp.png";
import { InstagramOutlined } from '@ant-design/icons'; 

const LeftMenu = ({ isUserAuthenticated, showDrawer }) => {
  const navigate = useNavigate();
  const { logout, userInfo, user } = useAuth();

  const closeDrawer = () => {
    showDrawer();
  };

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <div style={{display: "flex", flexDirection: "column", flex: 1, height: "100%"}}>
      <div style={{ flex: 4, paddingLeft: "20px" }}>
        <ul className="ul-menu">
          {(user === null || userInfo?.type !== "admin") && (
            <li
              onClick={closeDrawer}
              className={splitLocation[1] === "events" ? "active" : ""}
              style={{ paddingTop: "1rem" }}
            >
              <Link to='/events'>Rifas</Link>
            </li>
          )}
          {(isUserAuthenticated && userInfo?.type === "admin") && (
            <>
              <li
                onClick={closeDrawer}
                style={{ paddingTop: "1rem" }}
                className={splitLocation[1] === "register-event" ? "active" : ""}>
                <Link to='/register-event'>Registrar rifa</Link>
              </li>
              <li
                style={{ paddingTop: "1rem" }}
                onClick={closeDrawer}
                className={splitLocation[1] === "admin-dashboard" ? "active" : ""}>
                <Link to='/admin-dashboard'>Dashboard</Link>
              </li>
              <li
                style={{ paddingTop: "1rem" }}
                onClick={closeDrawer}
                className={splitLocation[1] === "admin-events" ? "active" : ""}>
                <Link to='/admin-events'>Rifas</Link>
              </li>
              <li style={{ paddingTop: "1rem" }} onClick={closeDrawer} className={splitLocation[1] === "admin-events-users" ? "active" : ""}>
                <Link to='/admin-events-users'>Usuarios</Link>
              </li>
            </>
          )} 
          {(isUserAuthenticated && userInfo?.type !== "admin") && (
            <>
              <li style={{ paddingTop: "1rem" }} onClick={closeDrawer} className={splitLocation[1] === "tickets" ? "active" : ""}>
                <Link to='/profile'>Mis Tickets</Link>
              </li>
              <li style={{ paddingTop: "1rem" }} onClick={closeDrawer} className={splitLocation[1] === "tickets" ? "active" : ""}>
                <Link to='/movements'>Mis Movimientos</Link>
              </li>
            </>
          )}
          {(isUserAuthenticated && userInfo?.type === "partner") && (
            <>
              <li
                onClick={closeDrawer}
                style={{ paddingTop: "1rem" }}
                className={splitLocation[1] === "register-event" ? "active" : ""}>
                <Link to='/register-event'>Registrar rifa</Link>
              </li>
              <li
                style={{ paddingTop: "1rem" }}
                onClick={closeDrawer}
                className={splitLocation[1] === "admin-dashboard" ? "active" : ""}>
                <Link to='/partner-dashboard'>Dashboard</Link>
              </li>
            </>
          )}       
        </ul>
      </div>
      
      <div style={{ flex: 2, justifyItems: "flex-end", flexDirection: "column", paddingBottom: "10px"}}>
        {!isUserAuthenticated && (
          <>
            <button className="btn-signin" onClick={() => {closeDrawer(); navigate("/login");}}>
              Ingresar
            </button>

            <button className="btn-register" onClick={() => {closeDrawer(); navigate("/register");}}>
              Registrate
            </button>
          </>
        )}
        {isUserAuthenticated && (
          <button className="btn-signin" onClick={() => { logout(); closeDrawer(); }}>
            Cerrar Sesión
          </button>
        )}
      </div>
      
      <div style={{flex: 1, paddingLeft: "20px"}}>
        <span className="txt-follow">Síguenos</span>
        <div className="follow-section">
          <a href="https://www.facebook.com/profile.php?id=100087474559601" target="_blank" rel="noreferrer">
            <img src={fb} alt="fb" style={{width: "26px", height: "25.75px"}}/>
          </a>
          <a href="https://www.instagram.com/hay.rifa/" target="_blank" style={{paddingLeft: "25px"}} rel="noreferrer">
            <InstagramOutlined style={{ color: "#000000", fontSize: '28px'}} />
          </a>
          <a href="https://api.whatsapp.com/send?phone=+523314683131&text=Buen día, necesito ayuda." target="_blank" style={{paddingLeft: "25px"}} rel="noreferrer">
            <img src={whatsApp} alt="whatsApp" style={{width: "25.88px", height: "26px"}}/>
          </a>
        </div>
      </div>
      
    </div>
  );
};

export default LeftMenu;
