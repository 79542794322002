import React, { useState, useEffect } from "react";
import CardWinnerItem from "../landingPage/components/CardWinnerItem";
import "../landingPage/LandingPage.css";
import "./Winners.css"
import { query, collection, getDocs, where } from "firebase/firestore";
import { db } from "../../firebase";
import alert from "../../assets/alert.png";

const CurrentEvents = () => {
    const [events, setEvents] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    useEffect(() => {
        const getData = async () => {
            const queryEvents = query(
                collection(db, "event"), where("status", "==", "closed")
            );
            const querySnapshot = await getDocs(queryEvents);
            let newDocs = [];
            querySnapshot.forEach((doc) => {
                newDocs.push({ ...doc.data(), id: doc.id });
            });
            validateEvent(newDocs)
            setEvents(newDocs);

        };
        getData();
    }, []);
    const validateEvent = (event) => {
        if (event.length < 1) {
          setModalVisible(true)
        }
      }

    return (
        <div className="container-all-past-events">
            {modalVisible &&
                <div style={{ textAlign: "center", paddingTop: "10em"}}>
                    <img src={alert} alt="google-logo" style={{ heigth: 45, width: 45, paddingRight: "10px" }} />
                    <h2 className='span-option-payment'>
                        Por el momento, no tenemos rifas disponibles.
                    </h2>
                </div>
            }
            <div className="container-all-events">
                <div className="scrolling-wrapper-vertical">
                    {events.map((item, index) => {
                        return (
                            <div key={index}><CardWinnerItem item={item} /></div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
};

export default CurrentEvents;
