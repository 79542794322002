const metadata = {
  contentType: "image/jpeg",
};

const statusTickets = {
  process: {
    color: "#FFBE0B",
    title: "Proceso"
  },
  paid: {
    color: "#009933",
    title: "Pagado"
  },
  expired: {
    color: "#ff0000",
    title: "Vencido"
  },
  deleted: {
    color: "#ff0000",
    title: "Pago no recibido"
  }
};

const statusEvent = {
  pending: {
    color: "#FFBE0B",
    title: "Pendiente",    
  },
  open: {
    color: "#009933",
    title: "Activa",
  },
  closed: {
    color: "#FB5607",
    title: "Cerrada",
  }
};

const nextEvents = [
  {
    "id": "c809b0db-e27c-4fd4-9d5d-1ca2219ab266",
    "title": "Play Station 5",
    "file": require("../assets/nextEvents/play5.png")
  },
  {
    "id": "b21014d6-7113-4eef-830b-fe497cc02212",
    "title": "iPhone 14 Pro Max",
    "file": require("../assets/nextEvents/iphone14.png")
  }
];

const categories = [
  "autos",
  "celulares",
  "motos",
  "bocinas",
  "televisiones",
  "computadoras",
  "videojuegos",
  "otro"
];

const paymentMethods = {
  spei: "0123 2002 8868 823823",
  oxxo: "4152 3138 7943 8286" 
};

export {
  metadata,
  statusTickets,
  nextEvents,
  categories,
  paymentMethods,
  statusEvent
};
