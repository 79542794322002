import {
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from '../firebase';

export const getEvent = async (uid) => {
  const docRef = doc(db, "event", uid);
  const docSnap = await getDoc(docRef);
  return { ...docSnap.data(), id: docSnap.id };
};