import { doc, setDoc, Timestamp, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

export const createUser = async (user, user_type, uid) => {
  const newUser = {
    email: user.email,
    fullName: user.fullName || null,
    phoneNumber: user.phoneNumber || null,
    bankAccount: null,
    balance: 0,
    status: true,
    address: null,
    type: user_type,
    lastSignIn: Timestamp.fromDate(new Date()),
    isActive: true
  };
  try {
    await setDoc(doc(db, "user", uid), newUser);
  } catch (err) {
    return err;
  }
};

export const createInteresedUser = (user, id) => {
  const newUser = {
    email: user.email,
    name: user.name,
    phoneNumber: user.phoneNumber || null,
  };
  return setDoc(doc(db, "Interesed", id), newUser);
};

export const getUser = (uid) => {
  const docRef = doc(db, "user", uid);
  return getDoc(docRef);
};

export const updateUser = (check, uid) => {
  const newData = {
    isActive: check
  };
  return updateDoc(doc(db, "user", uid), newData);
};
