import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Modal } from 'antd';
import dayjs from 'dayjs';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { statusTickets } from "../../utils/contants";
import { CopyOutlined } from '@ant-design/icons';
import { copyText } from '../../utils/general';
import { paymentMethods } from '../../utils/contants';
import shareIcon from "../../assets/compartir.png";
import infoIcon from "../../assets/info-icon.png";
import './PendingTicketsDetails.css';

const PendingTicketsDetails = () => {
  let { state } = useLocation();
  const [modalVisible, setModalVisible] = useState(false);

  let whatsappMessage =
    state.pendingTickets.length === 1 ?
    `https://api.whatsapp.com/send?phone=+523314683131&text=Buen día, les comparto el comprobante de mi boleto ${state.pendingTickets[0].ticket} para la rifa ${state.title}` : 
    `https://api.whatsapp.com/send?phone=+523314683131&text=Buen día, les comparto el comprobante de mis boletos: ${state.pendingTickets.map((item)=> item.ticket)} para la rifa ${state.title}`;

  const fireBaseTime = new Date(
    state.endDate.seconds * 1000 +
    state.endDate.nanoseconds / 1000000
  );
  state.day = dayjs(fireBaseTime).format("dddd D");
  state.month = dayjs(fireBaseTime).format("MMMM");
  state.year = dayjs(fireBaseTime).format("YYYY");
  state.time = dayjs(fireBaseTime).format("h:mm a");

  const columns = [
    {
      title: 'Ticket',
      dataIndex: 'ticket',
      key: 'ticket',
    },
    {
      title: 'Status del Pago',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (value, record, index) => {
        const status = statusTickets[value];
        return (
          <p style={{ color: status.color }}>
            {status.title}
          </p>
        );
      },
    },
    {
      title: "Fecha y Hora de Apartado",
      key: "buy_date",
      dataIndex: "buy_date",
      render: (value, record, index) => {
        let buy_date = record.buy_date;
        let time;
        const fireBaseTime = new Date(
          buy_date.seconds * 1000 + buy_date.nanoseconds / 1000000
        );
        const date = fireBaseTime.toLocaleDateString("es-MX");
        const atTime = fireBaseTime.toLocaleTimeString("es-MX");
        time = atTime.slice(0, 5);
        return (
          <>
            {date} a las {time}
          </>
        );
      },
    },
  ];

  return (
    <div className='container-body-pending'>
      <div className='container-row-pending'>
        <h1 className='title-pending-mobile'>{state.title}</h1>
        <div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: "column" }}>
          {/* Carrousel */}
          <div className='size-carrousel-pending'>
            <div className='container-pending-carrousel'>
              <Carousel
                showStatus={false}
              >
                {state.articleUrls.map((item, index) => {
                  return (
                    <div key={index}>
                      <img src={item} alt={index} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
        {/* Tickets */}
        <div style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "flex-start" }}>
          <div className='container-desc-pending'>
            <h1 className='title-pending-desktop'>{state.title}</h1>
            <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
              <p className='event-pending-price'>{`$${state.ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
              <img alt='icon share' src={shareIcon} className='icon-pending-share' onClick={() => copyText("https://hayrifa.mx/events/" + state.id)} />
            </div>
            <p className='txt-ticktets-pending'>{state.totalTickets} boletos, <b>quedan {state.availableTickets.length}</b></p>
            <p className='txt-ticktets-pending'>Sorteo el <b>{state.day} de {state.month} {state.year}</b></p>
            <p className='txt-ticktets-pending' style={{ paddingBottom: "20px" }}>Creado por <span style={{ color: "black", fontWeight: "bold" }}>{state.nameOwner}</span></p>
            <Table dataSource={state.pendingTickets} columns={columns} rowKey="id" pagination={{ pageSize: 5, showSizeChanger: false }}/>
            <div className='container-pending-total'>
              <p className='txt-total-pending'>Costo total</p>
              <p className='txt-calc-pending'>{`$${state.pendingTickets.length * state.ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
            </div>                        
            <button className='btn-pay-pending-tickets' onClick={() => setModalVisible(true)}>
              ¿Cómo pagar?
            </button>
            <div className='container-info-pending-tickets'>
              <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <img src={infoIcon} alt="info icon" style={{height: "18px", width: "18px"}}/>
                <span className='txt-title-info-pending'>Importante</span>
              </div>
              <p className='txt-description-info-pending'>
                Después de realizar el pago y enviarnos el comprobante por medio de WhatsApp en un lapso de <b>48 hrs</b> serán activados tus boletos.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={modalVisible}
        closable={true}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <div>
          <h2 className='title-checkout'>Opciones de pago</h2>
          <div className='title-payment-option'>
            <p>Transferencia electrónica (SPEI)</p>
          </div>
          <div className='container-payment-option'>
            <div style={{ borderBottom: "solid 1px #ededed" }}>
              <div className="checkout-body-left">
                <span>CLABE</span>
              </div>
              <div className="checkout-body-right">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <span>{paymentMethods.spei}</span>
                  </div>
                  <div>
                    <p
                      className='copy-item-checkout'
                      onClick={() => copyText(paymentMethods.spei)}
                    >
                      <CopyOutlined />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ borderBottom: "solid 1px #ededed", paddingTop: ".4em" }}>
              <div className="checkout-body-left">
                <span>Beneficiario </span>
              </div>
              <div className="checkout-body-right">
                <span>LEOPOLDO MORALES GONZALEZ</span>
              </div>
            </div>
            <div style={{ paddingTop: ".4em" }}>
              <div className="checkout-body-left">
                <span>Banco</span>
              </div>
              <div className="checkout-body-right">
                <span>BBVA</span>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "1em" }}>
            <div className='title-payment-option'>
              <p>Efectivo en puntos de pago</p>
            </div>
            <div className='container-payment-option'>
              <div style={{ borderBottom: "solid 1px #ededed" }}>
                <div className="checkout-body-left">
                  <span>No. de tarjeta</span>
                </div>
                <div className="checkout-body-right">
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <span>{paymentMethods.oxxo}</span>
                    </div>
                    <div>
                      <p
                        className='copy-item-checkout'
                        onClick={() => copyText(paymentMethods.oxxo)}
                      >
                        <CopyOutlined />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: ".4em" }}>
                <div className="checkout-body-left">
                  <span>Comercio </span>
                </div>
                <div className="checkout-body-right">
                  <span>Oxxo</span>
                </div>
              </div>
            </div>
          </div>
          <div className='container-send-invoice'>
            <a 
              href={whatsappMessage}
              target="_blank" 
              rel="noreferrer" 
              style={{width: "100%"}}
            >
              <button className='btn-send-invoice'>
                Enviar comprobante de pago
              </button>
            </a>            
          </div>            
        </div>
      </Modal>
    </div>
  )
};

export default PendingTicketsDetails;
