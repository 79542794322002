import React, { useState, useEffect } from 'react';
import { query, collection, getDocs, where } from "firebase/firestore";
import { db } from "../../firebase";
import TitleSection from '../landingPage/components/TitleSection';
import CardEventItem from '../landingPage/components/CardEventItem';
import { useParams, useNavigate } from 'react-router-dom';
import { message, InputNumber } from 'antd';
import dayjs from 'dayjs';
import { getEvent } from '../../functions/event';
import { useAuth } from '../../context/context';
import { Carousel } from 'react-responsive-carousel';
import Loading from "../../components/Loading";
import { Spin } from 'antd';
import Sheet from 'react-modal-sheet';
import {
  Button
} from "antd";
import compartir from "../../assets/compartir.png";
import { copyText } from '../../utils/general';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './EventDetails.css';

const EventDetails = () => {
  const [isOpen, setOpen] = useState(false);
  const [isOpenInput, setOpenInput] = useState(false);
  const { saveTickets, user } = useAuth();
  let { eventId } = useParams();
  const navigate = useNavigate();
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [ticketsTotal, setTicketsTotal] = useState([]);
  const [total, setTotal] = useState(0);
  const [event, setEvent] = useState(null);
  const [messageLuckyNumber, setMessageLuckyNumber] = useState(null);
  const [randomTicket, setRandomTicket] = useState(null);
  const [LuckyNumber, setLuckyNumber] = useState(false);
  const [events, setEvents] = useState([]);
  const [buttonStatus, setButtonStatus] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)

  const getData = async () => {
    let data = await getEvent(eventId);
    const fireBaseTime = new Date(
      data.endDate.seconds * 1000 +
      data.endDate.nanoseconds / 1000000
    );
    const day = dayjs(fireBaseTime).format("dddd D");
    const month = dayjs(fireBaseTime).format("MMMM");
    const year = dayjs(fireBaseTime).format("YYYY");
    const time = dayjs(fireBaseTime).format("h:mm a");

    let tickets = [];
    for (let i = 0; i < data.purchasedTickets.length; i++) {
      tickets.push({ ticket: data.purchasedTickets[i], available: false });
    }

    for (let i = 0; i < data.availableTickets.length; i++) {
      tickets.push({ ticket: data.availableTickets[i], available: true });
    }

    tickets.sort(function (a, b) {
      const ticketA = a.ticket;
      const ticketB = b.ticket;
      if (ticketA < ticketB) {
        return -1;
      }
      if (ticketA > ticketB) {
        return 1;
      }
      return 0;
    });

    setEvent({
      ...data,
      day,
      month,
      year,
      time,
      tickets
    });
    setTicketsTotal(tickets);

    const queryEvents = query(
      collection(db, "event"), where("status", "==", "open")
    );
    const querySnapshot = await getDocs(queryEvents);
    let newDocs = [];
    querySnapshot.forEach((doc) => {
      if(doc.data().category === data.category && doc.id !== data.id) {
        newDocs.push({ ...doc.data(), id: doc.id });
      }      
    });
    setEvents(newDocs);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);



  const searchTicket = (value) => {
    var tickets = [];
    var filter = [];
    if (value != null) {
      for (let i = 0; i < event.tickets.length; i++) {
        tickets.push(event.tickets[i])
      }

      var PATTERN = value
      filter = tickets.filter(function (str) { return str.ticket.indexOf(PATTERN) !== -1; });
      setTicketsTotal(filter)
    } else {
      setTicketsTotal(event.tickets)
    }
  };

  const handleTicketFromList = (ticket) => {
    const alreadyAddTicket = selectedTickets.includes(ticket);
    if (alreadyAddTicket) {
      removeTicket(ticket);
      return;
    }
    const totalPrice = (selectedTickets.length + 1) * event.ticketPrice;
    setSelectedTickets([...selectedTickets, ticket]);
    setTotal(totalPrice);
  };

  const removeTicket = (value) => {
    const filteredTickets = selectedTickets.filter((item) => item !== value);
    const totalPrice = filteredTickets.length * event.ticketPrice;
    setSelectedTickets(filteredTickets);
    setTotal(totalPrice);
  };

  const generateRandomTicket = () => {
    const ticket = event.availableTickets[Math.floor(Math.random() * event.availableTickets.length)];
    const alreadyAddTicket = selectedTickets.includes(ticket);
    if (alreadyAddTicket) {
      return generateRandomTicket();
    }
    setDisabledButton(true)
    setLuckyNumber()
    setLuckyNumber(true)
    setMessageLuckyNumber()
    setRandomTicket();
    setTimeout(function () {
      setLuckyNumber(false);
      setMessageLuckyNumber("Tu boleto de la suerte es: ")
      setRandomTicket(ticket);
      setButtonStatus(true)
      setDisabledButton(true)
    }, 3000);
    setOpen(true);

  };

  const generateAnotherRandomTicket = () => {
    const ticket = event.availableTickets[Math.floor(Math.random() * event.availableTickets.length)];
    const alreadyAddTicket = selectedTickets.includes(ticket);
    if (alreadyAddTicket) {
      return generateAnotherRandomTicket();
    }
    if (setRandomTicket) {
      setRandomTicket(false);
    }

    setButtonStatus(false)
    setLuckyNumber(true)
    setRandomTicket();
    setMessageLuckyNumber()
    setTimeout(function () {
      setLuckyNumber(false);
      setMessageLuckyNumber("Tu boleto de la suerte es:")
      setButtonStatus(true)
      setRandomTicket(ticket);
    }, 3000);
  };

  const saveRandomTicket = () => {
    const totalPrice = (selectedTickets.length + 1) * event.ticketPrice;
    setSelectedTickets([...selectedTickets, randomTicket]);
    setTotal(totalPrice);
    setRandomTicket(null);
    setOpen(false)
    setDisabledButton(false)
    setButtonStatus(false)
  };

  const handleCancel = () => {
    setDisabledButton(false)
    setTicketsTotal(event.tickets)
    setOpenInput(false)
    setButtonStatus(false)
    setOpen(false)
  };

  const onCloseModal = () => {
    setOpen(false)
    setButtonStatus(false)
    setDisabledButton(false)
  }

  const goToCheckout = () => {
    const isUserAuthenticated = user === null ? false : true;
    const data = {
      id: event.id,
      selectedTickets,
      tickets: selectedTickets.length,
      title: event.title,
      amount: total,
    };
    saveTickets(data);
    if (!isUserAuthenticated) {
      message.info("Es necesario registrarte o iniciar sesión para poder apartar tus boletos", 3);
    }
    navigate(`/select-payment-type`)
  }

  if (!event) {
    return (
      <Loading />
    )
  }


  return (
    <>
      <div>
        <div className='container-event-details'>
          {/* Head */}
          <div className='containerHead'>
            <div className='title-mobile'>
              <h1 className='title'>{event.title}</h1>
            </div>
            {/* Carrousel */}
            <div className='container-size-carrousel'>
            <div className='size-carrousel'>
              <div className='containerCarrousel'>
                <Carousel
                style={{
                  display:"flex", justifyContent:"center"
                }}
                  showStatus={false}
                  showArrows={false}
                >
                  {event.articleUrlsMedium.map((item, index) => {
                    return (
                      <div style={{display:"flex", justifyContent:"center"}} key={index}>
                        <img className='image-size' src={item} alt={index} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
            </div>
            {/* Description */}
            <div className='containerDescription' style={{ display: 'contents' }}>
              <div className='containerDescriptionDesktop'>
                <div className='containerRow'>
                  <div className='container1'>
                    <div className='title-desktop'>
                      <h1 style={{ textAlign: "left" }} className='title'>{event.title}</h1>
                    </div>
                    <div style={{ display: "flex", textAlign: "center" }}>
                      <div style={{
                        display: "flex",
                        width: "100%"
                      }}>
                        <span className='style-price-ticket'>${event.ticketPrice}</span></div>
                      <div style={{ display: "flex", paddingRight: "5px" }}>
                        <Button
                          style={{ border: "#FFFFFF" }}
                          onClick={() => copyText("https://hayrifa.mx/events/" + event.id)}
                          target="_blank"
                          icon={<img src={compartir} alt="fb-logo" className='style-size-share' />}
                        >
                        </Button></div>
                    </div>
                    <div className='text-tickets'>{event.totalTickets} boletos, <span style={{ fontWeight: "bold" }}>quedan {event.availableTickets.length}</span></div>
                    <p className='text-tickets'>Sorteo el <span style={{ color: "black", fontWeight: "bold" }}>{event.day} de {event.month} {event.year}</span></p>
                    <p className='text-tickets'>Creado por <span style={{ color: "black", fontWeight: "bold" }}>{event?.nameOwner}</span></p>
                  </div>
                </div>
                <div className='choose-ticket-desktop'>
                  <div className='text-choose-ticket'>Elige tu boleto</div>
                  {event.status !== "closed" && (
                    <>
                      <div className='rowButtons'>
                        <div className='colButtons'>
                          <button className='btnManual' disabled={disabledButton} onClick={generateRandomTicket}>
                            Automático
                          </button>
                          <button className='btnManual' onClick={() => setOpenInput(true)}>
                            Manual
                          </button>
                        </div>
                        <div>
                          {total > 0 && (
                            <div style={{ textAlign: "center", color: "#A5A5A5", paddingTop: "1.2em", paddingBottom: "1.2em" }}>Tus boletos:</div>
                          )}
                        </div>
                        <div className='container-pay'>
                          {total > 0 && (
                            <div className='container-event-tickets'>
                              <div className='box-tickets'>
                                <div className='box-tickets-numeros'>Números</div>
                                <div className='container-tickets-array'>
                                  <div className='box-tickets-length'
                                    style={{ fontSize: "24px" }}>{selectedTickets + " "}
                                  </div>
                                </div>
                              </div>
                              <div style={{ height: "2px", background: "#CACACA" }}></div>
                              <div className='box-tickets-choose'>
                                <div className='box-tickets-numeros'>Total</div>
                                <div className='container-tickets-array'>
                                  <div style={{
                                    color: "rgb(255, 178, 8)",
                                    fontWeight: "bold",
                                    fontSize: "24px",
                                  }}>${total}
                                  </div>
                                </div>
                              </div>
                            </div>)}
                        </div>
                      </div>
                      <div className='containerSelect'>
                        {total > 0 && (
                          <button
                            className='btnSelect'
                            onClick={goToCheckout}
                          >
                            Apartar boletos
                          </button>
                        )}
                      </div>
                    </>
                  )}
                  {total < 1 && (
                    <button
                      className='btnSelectDisabled'
                      onClick={goToCheckout}
                      disabled
                    >
                      Apartar boletos
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='choose-ticket-mobile'>
            <div style={{ paddingTop: "1em", textAlign: "center", color: "#A5A5A5" }}>Elige tu boleto</div>
            {event.status !== "closed" && (
              <>
                <div className='rowButtons'>
                  <div className='colButtons'>
                    <button className='btnManual' disabled={disabledButton} onClick={generateRandomTicket}>
                      Automático
                    </button>
                    <button className='btnManual' onClick={() => setOpenInput(true)}>
                      Manual
                    </button>
                  </div>
                  <div>
                    {total > 0 && (
                      <div style={{ textAlign: "center", color: "#A5A5A5", paddingTop: "1.2em", paddingBottom: "1.2em" }}>Tus boletos:</div>
                    )}
                  </div>
                  <div className='container-pay'>
                    {total > 0 && (
                      <div className='container-event-tickets'>
                        <div className='box-tickets'>
                        <div className='box-tickets-numeros'>Números</div>
                        <div style={{ width: "100%", paddingRight: "1em" }}>
                            <div className='box-tickets-length'style={{ fontSize: "24px" }}>{selectedTickets + " "}
                            </div>
                          </div>
                        </div>
                        <div style={{ height: "2px", background: "#CACACA" }}></div>
                        <div className='box-tickets-choose'>
                        <div className='box-tickets-numeros'>Total</div>
                          <div style={{ width: "100%", paddingRight: "1em" }}>
                            <div style={{
                              color: "rgb(255, 178, 8)",
                              fontWeight: "bold",
                              fontSize: "24px",
                          
                            }}>${total}
                            </div>
                          </div>
                        </div>
                      </div>)}
                  </div>
                </div>
                <div className='containerSelect'>
                  {total > 0 && (
                    <button
                      className='btnSelect'
                      onClick={goToCheckout}
                    >
                      Apartar boletos
                    </button>
                  )}
                  {total < 1 && (
                    <button
                      className='btnSelectDisabledMobile'
                      disabled
                      onClick={goToCheckout}
                    >
                      Apartar boletos
                    </button>
                  )}
                </div>
                <div style={{ paddingBottom: "5em" }}></div>
                <Sheet isOpen={isOpen} onClose={() => onCloseModal()}
                  snapPoints={[600, 400, 100, 0]}
                  initialSnap={1}>
                  <Sheet.Container
                    style={{ borderRadius: "30px" }}>
                    <Sheet.Header />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Spin style={{ fontSize: "18px", paddingLeft: "2em", paddingRight: "2em" }} spinning={LuckyNumber} tip="Estamos calculando tu número de la suerte...">
                      </Spin>
                    </div>
                    <div className='modal-random-ticket-title'><p>{messageLuckyNumber}</p></div>
                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      <div className='modal-random-ticket'><b>{randomTicket}</b></div></div>
                    <Sheet.Content>
                      {buttonStatus && (
                        <div className='containerLuckyNumber'>
                          <div style={{ textAlign: "center", width: "100%" }}>
                            <div style={{ paddingBottom: "1em" }}>
                              <button key="submit" className='btnChooseThis' onClick={saveRandomTicket}>
                                Elegir este número
                              </button>
                            </div>
                            <div style={{ paddingBottom: "1em" }}>
                              <button key="submitAgain" className='btnChooseThisNewNumber' onClick={generateAnotherRandomTicket}>
                                Elegir nuevo número
                              </button>
                            </div>
                          </div>
                          <div className='button-back-modal'>
                            <button key="back" className='btnCancel2' onClick={handleCancel}>
                              Regresar
                            </button>
                          </div>
                        </div>)}
                    </Sheet.Content>
                  </Sheet.Container>
                  <Sheet.Backdrop />
                </Sheet>

                <Sheet isOpen={isOpenInput} onClose={() => setOpenInput(false)}>
                  <Sheet.Container
                    style={{ borderRadius: "30px 30px 0 0" }}>
                    <Sheet.Header />
                    <div style={{ fontSize: "19px", paddingLeft: "2em", paddingRight: "2em" }}>
                      <p>Boletos</p>
                    </div>
                    <Sheet.Content>
                      <div style={{ paddingLeft: "2em", paddingRight: "2em" }}>
                        <div style={{ paddingBottom: "2em" }}>
                          <InputNumber
                            style={{ paddingBottom: "2em" }}
                            className='inputRegister'
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            onChange={searchTicket}
                            placeholder='Buscador' />
                        </div>
                        <div style={{ paddingBottom: "2em", textAlign: "center" }}>
                          <div className='containerFooter'>
                            <div className='container-numbers-event-details-admin'>
                              {ticketsTotal.map((item, index) => {
                                if (item.available) {
                                  return (
                                    <button
                                      className={selectedTickets.includes(item.ticket) ? 'btn-ticket-selected-ed' : 'btn-ticket-available-ed'}
                                      key={`btn-${index}`}
                                      onClick={() => handleTicketFromList(item.ticket)}
                                    >
                                      {item.ticket}
                                    </button>
                                  );
                                } else {
                                  return (
                                    <button
                                      className={'btn-ticket-disable-ed'}
                                      key={`btn-${index}`}
                                      disabled={true}
                                    >
                                      {item.ticket}
                                    </button>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                        <div className='manual-btn'>
                          <button key="back" className='btnCancel2' onClick={handleCancel}>
                            Elegir boletos
                          </button>
                        </div>
                      </div>
                    </Sheet.Content>
                  </Sheet.Container>
                  <Sheet.Backdrop />
                </Sheet>
              </>
            )}
          </div>

        </div><div className="container-events">
          <TitleSection title="Rifas Similares" linkTxt="Ver todas" section="/events" />
          <div className="scrolling-wrapper-flexbox">
            {events.map((item, index) => {
              return (
                <div key={index}><CardEventItem item={item} /></div>
              );
            })}
          </div>
        </div>
      </div></>


  )
};

export default EventDetails;