import React, { useState, useEffect } from 'react';
import { query, collection, getDocs, where } from "firebase/firestore";
import { db } from "../../firebase";
import TitleSection from '../landingPage/components/TitleSection';
import CardEventItem from '../landingPage/components/CardEventItem';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import dayjs from 'dayjs';
import { getEvent } from '../../functions/event';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loading from "../../components/Loading";
import './EventDetails.css';
import {
    Button
} from "antd";
import compartir from "../../assets/compartir.png";

const EventDetails = () => {

    let { eventId } = useParams();
    const [event, setEvent] = useState(null);
    const [events, setEvents] = useState([]);
    const getData = async () => {
        const queryEvents = query(
            collection(db, "event"), where("status", "==", "open")
        );
        const querySnapshot = await getDocs(queryEvents);
        let newDocs = [];
        querySnapshot.forEach((doc) => {
            newDocs.push({ ...doc.data(), id: doc.id });
        });
        setEvents(newDocs);

        let data = await getEvent(eventId);
        const fireBaseTime = new Date(
            data.endDate.seconds * 1000 +
            data.endDate.nanoseconds / 1000000
        );
        const day = dayjs(fireBaseTime).format("dddd D");
        const month = dayjs(fireBaseTime).format("MMMM");
        const year = dayjs(fireBaseTime).format("YYYY");
        const time = dayjs(fireBaseTime).format("h:mm a");

        let tickets = [];
        for (let i = 0; i < data.purchasedTickets.length; i++) {
            tickets.push({ ticket: data.purchasedTickets[i], available: false });
        }

        for (let i = 0; i < data.availableTickets.length; i++) {
            tickets.push({ ticket: data.availableTickets[i], available: true });
        }

        tickets.sort(function (a, b) {
            const ticketA = a.ticket;
            const ticketB = b.ticket;
            if (ticketA < ticketB) {
                return -1;
            }
            if (ticketA > ticketB) {
                return 1;
            }
            return 0;
        });

        setEvent({
            ...data,
            day,
            month,
            year,
            time,
            tickets
        });
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId]);

    const copyPaymentOption = (text) => {
        let selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = text;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
        message.success("Copiado en el portapapeles!");
    };

    if (!event) {
        return (
            <Loading />
        )
    }

    return (
        <div style={{ paddingBottom: "0em" }}>
            <div className='container-event-details'>
                {/* Head */}
                <div className='containerHead'>
                    <div className='title-mobile'>
                        <h1 className='title'>{event.title}</h1>
                    </div>
                    {/* Carrousel */}
                    <div className='size-carrousel'>
                        <div className='containerCarrousel'>
                            <Carousel
                                showStatus={false}
                            >
                                {event.articleUrls.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <img style={{ width: "87%", fontSize: "5px", maxHeight: '450px' }} src={item} alt={index} />
                                        </div>
                                    );
                                })}
                            </Carousel>
                        </div>
                    </div>
                    {/* Description */}
                    <div className='containerDescription' style={{ display: 'contents' }}>
                        <div className='containerDescriptionDesktop'>
                            <div className='containerRow'>
                                <div className='container1'>
                                    <div className='title-desktop'>
                                        <h1 style={{ textAlign: "left" }} className='title'>{event.title}</h1>
                                    </div>
                                    <div style={{ display: "flex", textAlign: "center" }}>
                                        <div style={{
                                            display: "flex",
                                            width: "100%"
                                        }}>
                                        </div>
                                        <div style={{ display: "flex", paddingRight: "5px" }}>
                                            <Button
                                                style={{ border: "#FFFFFF" }}
                                                onClick={() => copyPaymentOption("https://hayrifa.mx/events/" + event.id)}
                                                target="_blank"
                                                icon={<img src={compartir} alt="fb-logo" className='style-size-share' />}
                                            >
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='text-tickets'>Ganador: <span style={{ fontWeight: "bold" }}> {event.nameWinner}</span></div>
                                    <div className='text-tickets'>Ticket ganador: <span style={{ fontWeight: "bold" }}> {event.ticketWinner}</span></div>
                                    <p className='text-tickets'>Creado por <span style={{ color: "black", fontWeight: "bold" }}>{event?.nameOwner}</span></p>
                                    <p className='text-tickets'>Finalizó el <span style={{ color: "black", fontWeight: "bold" }}>{event.day} de {event.month} {event.year}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div><div className="container-events">
                <TitleSection title="Rifas Similares" linkTxt="Ver todas" section="/events" />
                <div className="scrolling-wrapper-flexbox">
                    {events.map((item, index) => {
                        return (
                            <div key={index}><CardEventItem item={item} /></div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
};

export default EventDetails;
