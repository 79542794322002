import React, { useState, useEffect } from 'react';
import {
  query,
  collection,
  getDocs,
  where
} from "firebase/firestore";
import { db } from '../../firebase';
import "./EventList.css"
import alert from "../../assets/alert.png";
import CurrentEvents from './CurrentEvents';
import PastEvents from './PastEvents';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import Loading from '../../components/Loading';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#EA9F26',
  },
});

const EventList = () => {
  const [tabValue, setTabValue] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [events, setEvents] = useState(null)

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const getData = async () => {
      const queryEvents = query(
        collection(db, "event"), where("status", "==", "open")
      );
      const querySnapshot = await getDocs(queryEvents);
      let newDocs = [];
      querySnapshot.forEach((doc) => {
        newDocs.push({ ...doc.data(), id: doc.id });
      });
      validateEvent(newDocs)
      setEvents(newDocs)
    };
    getData();

    const validateEvent = (event) => {
      if (event.length < 1) {
        setModalVisible(true)
      }
    }
  }, []);

  if (!events) {
    return (
      <Loading />
    )
  }

  return (
    <div>
      {modalVisible &&
        <div style={{ textAlign: "center", paddingTop: "13rem", paddingBottom: "15em" }}>
          <img src={alert} alt="google-logo" style={{ heigth: 45, width: 45, paddingRight: "10px", paddingBottom: "3px" }} />
          <h2 className='span-option-payment'>
            Por el momento, no tenemos rifas disponibles.
          </h2>
        </div>
      }

      {!modalVisible &&
      <div style={{ justifyContent: "center" }}>
        <div className='container-event-list'>
          <Box
            sx={{
              marginTop: -3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid container direction="row" alignItems="center" justifyContent="center">
              <Grid item xs={15} md={14} lg={14} align="center" sx={{ mt: 4 }}>
                <StyledTabs
                  value={tabValue}
                  onChange={handleChange}
                  indicatorColor=""
                  textColor="inherit"
                  sx={{ width: "100%" }}
                >
                  <Tab label="Rifas Actuales" sx={{ width: "50%" }} style={{ back: "green" }} />
                  <Tab label="Rifas Pasadas" sx={{ width: "50%" }} />
                </StyledTabs>
              </Grid>
            </Grid>
          </Box>
        </div>
        <div className='container-event-list'>
          {tabValue === 0 && <CurrentEvents />}
          {tabValue === 1 && <PastEvents />}
        </div>
      </div>
      }
    </div>
  )
};

export default EventList;
