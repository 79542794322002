import React from "react";
import { useNavigate } from 'react-router-dom';
import "./CardTicketActive.css";

const CardTicketActive = ({event, index, style}) => {
  const navigate = useNavigate();
  const onClickCard = () => {
    navigate(`/purchase-ticket-details/${event.id}`, {
      state: {
        ...event,
      },
    })
  }

  return (
    <div className="card-ticket-active" key={index} style={style} onClick={onClickCard}>
      <div style={{display: "flex", flexDirection: "row", flex: 1}}>
        <div style={{display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between", paddingTop: "10px", paddingBottom: "3px"}}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <span className="txt-title-card-ticket-active">{event.title}</span>
            <span className="txt-price-card-ticket-active">{`$${event.ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
          </div>
          <div className="cont-numbers-card-ticket-active">
            <span className="txt-title-cont-ticket-active">Tus números</span>
            <p className="txt-numbers-cont-ticket-active">
              {event.activeTickets.map((item, index) => {                
                  return (index ? ', ' : '') + item.id
                })            
              }
            </p>
          </div>
        </div>
        <div style={{display: "flex", flex: 1, justifyContent: "center", alignItems: "center"}}>
          <img src={event.urlIcon} alt="xbox" style={{height: "130px", width: "130px", objectFit: "contain"}}/>
        </div>
      </div>
    </div>
  )
};

export default CardTicketActive;
