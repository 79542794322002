import React from "react";
import { Link } from "react-router-dom";
import "./TitleSection.css";

const TitleSection = ({title, linkTxt, section, tabIndex, items}) => {
  return (
    <div className="title-section">
      <span className="title-txt-sec">{title}</span>
      <Link to={section} state={{ tabIndex, items }} >
        <span className="link-txt-sec">{linkTxt}</span>
      </Link>
    </div>
  )
};

export default TitleSection;
