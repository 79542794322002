import React from "react";
import { LeftOutlined } from '@ant-design/icons';
import "./ReturnBtn.css";

const ReturnBtn = ({fn}) => {
  return (
    <button className="returnBtn" onClick={fn}>
      <LeftOutlined />
      <span>Regresar</span>
    </button>
  )
}

export default ReturnBtn;
