
import "./PoliticaPrivacidad.css";

const PoliticaPrivacidad = () => {

    return (
        <div className='container-privacidad'>
            <div className='container-text-privacidad'>
                <p style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold" }}>
                    Política de Privacidad
                </p>
                <p style={{ fontWeight: "bold" }}>
                    Fecha de entrada en vigor: 10 de Julio 2023
                </p>
                <p>
                    La siguiente Política de Privacidad describe cómo se recopila, utiliza, mantiene y divulga la información personal obtenida a través de nuestro sitio web www.hayrifa.mx .Esta política se aplica a todos los visitantes, usuarios y clientes que acceden o utilizan el sitio web.
                </p>
                <p style={{ fontWeight: "bold" }}>
                    Información personal que recopilamos
                </p>
                <p>
                    Podemos recopilar información personal de los usuarios de diferentes formas, incluyendo, pero no limitado a, cuando los usuarios visitan nuestro sitio, se registran en el sitio, realizan una compra, completan un formulario y en relación con otras actividades, servicios, características o recursos que ponemos a disposición en nuestro sitio web. La información personal que podemos solicitar incluye, entre otros, nombre, dirección de correo electrónico, dirección postal, número de teléfono y detalles de pago.
                </p>

                <p style={{ fontWeight: "bold" }}>
                    Uso de la información recopilada
                </p>
                <p>
                    La información personal que recopilamos de los usuarios se utiliza para los siguientes fines:
                </p>
                <p>
                    Procesar y completar transacciones: Utilizamos la información proporcionada por los usuarios para procesar y completar compras y pedidos realizados a través de nuestro sitio web.
                </p>
                <p style={{ fontWeight: "bold" }}>
                    Mejora de nuestro sitio web:
                </p>
                <p>
                    Podemos utilizar la retroalimentación que nos brindan los usuarios para mejorar nuestros productos y servicios, así como la experiencia general del sitio web.
                </p>
                <p>
                    Atención al cliente: La información proporcionada nos ayuda a responder a las solicitudes de atención al cliente y brindar un mejor soporte.
                </p>
                <p>
                    Envío de correos electrónicos periódicos: Podemos utilizar la dirección de correo electrónico proporcionada por el usuario para enviar información y actualizaciones relacionadas con su pedido, así como para responder a sus consultas y preguntas. Además, es posible que ocasionalmente enviemos correos electrónicos promocionales sobre productos, ofertas especiales u otra información que consideremos relevante para el usuario.
                </p>
                <p style={{ fontWeight: "bold" }}>
                    Protección de la información
                </p>
                <p>
                    Implementamos medidas de seguridad adecuadas para proteger la información personal de los usuarios contra el acceso no autorizado, la alteración, la divulgación o la destrucción de los datos almacenados en nuestro sitio web. Sin embargo, es importante tener en cuenta que ninguna transmisión de datos a través de Internet puede garantizar su seguridad al 100%.
                </p>
                <p style={{ fontWeight: "bold" }}>

                    Divulgación de información a terceros
                </p>
                <p>
                    No vendemos, comerciamos ni alquilamos información personal de los usuarios a terceros. Podemos compartir información demográfica agregada que no esté vinculada a ninguna información de identificación personal con nuestros socios comerciales, afiliados y anunciantes con el propósito de mejorar nuestros servicios y personalizar la experiencia del usuario.
                </p>
                <p style={{ fontWeight: "bold" }}>

                    Cambios a esta política de privacidad
                </p>
                <p>

                    Nos reservamos el derecho de actualizar o modificar esta Política de Privacidad en cualquier momento. Al utilizar nuestro sitio web, el usuario acepta estar sujeto a estas revisiones. Se recomienda a los usuarios que revisen periódicamente esta página para conocer los cambios realizados.

                </p>
                <p style={{ fontWeight: "bold" }}>
                    Eliminación de datos datos del usuario
                </p>
                <p>
                    Para eliminar permanentemente tus datos de la plataforma de <a href="https://hayrifa.mx/">hayRifa.mx</a> es necesario que envies un email a <a href="mailto:contacto@hayrifa.mx?Subject=Soporte%20">contacto@hayrifa.mx</a> con el aunto "Eliminar mis datos de usuario".
                </p>
                <p style={{ fontWeight: "bold" }}>

                    Aceptación de los términos
                </p>
                <p>
                    Al utilizar este sitio web, el usuario indica su aceptación de esta política. Si el usuario no está de acuerdo con esta política, se le solicita que no utilice nuestro sitio web. El uso continuado del sitio web después de la publicación de cambios a esta política se considerará como la aceptación de dichos cambios.
                </p>
                <p>
                    Si tiene alguna pregunta sobre esta Política de Privacidad, puede ponerse en contacto con nosotros a través de los medios proporcionados en nuestro sitio web.
                </p>

            </div>
        </div>
    )
};

export default PoliticaPrivacidad;
