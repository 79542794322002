import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Table, Space, Button } from "antd";
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import Loading from "../../components/Loading";
import { db } from "../../firebase";
// import { firestore } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const Dashboard  = () => {
    const usersCollectionRef = collection(db, "user");
    const eventCollectionRef = collection(db, "event");
    const [users, setUsers] = useState(0);
    const [events, setEvents] = useState(0);
    const [dataTable, setDataTable] = useState(0);
    const [eventsLive, setEventsLive] = useState(0);
    const [bets, setBets] = useState(0);
    const navigate = useNavigate();
    let nameEvent = [];
    let incomeData = [];


    const getAllUsers = async () => {
        const q = query(usersCollectionRef);

        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setUsers(items.length);
    };

    const getAllEvents = async () => {
        const q = query(eventCollectionRef);
        const querySnapshot = await getDocs(q);
        const items = [];
        const ticketPaid = [];
        const priceTicket = [];
        var totalIncome = 0;

        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });

        for (let i = 0; i < items.length; i++) {
            let eventCollectionEnrolled;
            let eventCollectionEnrolledProcess;

            eventCollectionEnrolled = collection(db, `event/${items[i].id}/enrolled`);
            eventCollectionEnrolledProcess = collection(db, `event/${items[i].id}/enrolled`);
            const queryPaid = query(eventCollectionEnrolled, where("payment_status", "==", "paid"));
            const queryProcess = query(eventCollectionEnrolledProcess, where("payment_status", "==", "process"));
            const querySnapshotPaid = await getDocs(queryPaid);
            const querySnapshotProcess = await getDocs(queryProcess);
            items[i].totalPaid = querySnapshotPaid.size
            items[i].totalProcess = querySnapshotProcess.size
            ticketPaid[i] = querySnapshotPaid.size
            priceTicket.push(items[i].ticketPrice)
            nameEvent.push(items[i].title)
        }

        for (let i = 0; i < ticketPaid.length; i++) {
            incomeData.push(ticketPaid[i] * priceTicket[i])
        }

        incomeData.forEach((cantidad) => {
            totalIncome += cantidad;
        });

        setBets(totalIncome)
        setEvents(items.length);
        setDataTable(items)
    };

    const getAllEventsLive = async () => {
        const q = query(eventCollectionRef, where("status", "==", "open"));

        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setEventsLive(items.length);
    };

    useEffect(() => {
        getAllUsers();
        getAllEvents();
        getAllEventsLive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        },
    };



    const [barBets] = useState({
        labels: nameEvent,
        datasets: [
            {
                data: incomeData,
                borderColor: 'rgb(54, 147, 255)',
                backgroundColor: 'rgba(54, 147, 255)',
                borderWidth: 2,
            },
        ],
    });

    const columns = [
        {
            title: 'Nombre Rifa',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Pagados',
            dataIndex: 'totalPaid',
            key: 'totalPaid',
        },
        {
            title: 'En proceso',
            dataIndex: 'totalProcess',
            key: 'totalProcess',
        },
        {
            title: "Detalle de boletos",
            key: "action",
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        onClick={() =>
                            navigate(`/admin-events/${record.id}`, {
                                state: {
                                    ...record,
                                },
                            })
                        }
                        type="link"
                    >
                        Ver
                    </Button>
                </Space>
            ),
        },
    ];

    if (!users) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <Container component="main" maxWidth="lg">
                <Box
                    sx={{
                        marginTop: 6,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Grid direction="row" justifyContent="center" container rowSpacing={4} columnSpacing={{ sx: 0, sm: 0, md: 2, lg: 2 }}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Link to="/admin-events-users">
                                <Paper sx={{ height: 140 }} style={{ borderRadius: 20, boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)" }}
                                    onClick={(e) => ("/usersPanel")}>
                                    <Stack direction="row" justifyContent="initial" spacing={2} sx={{ ml: 3, pt: 2 }}>
                                        <PersonOutlineIcon sx={{ fontSize: "45px", color: "#6750A4", pt: 1 }}
                                            style={{ backgroundColor: "#F8F6FD", borderRadius: 12, padding: "10px 10px", }} />
                                        <Typography sx={{ fontSize: 14, pt: 1.5 }} color={'#1C1B1F'} align="left" >
                                            Usuarios
                                        </Typography>
                                    </Stack>
                                    <Typography sx={{ fontSize: 28, mt: 2, ml: 4, fontWeight: "bold" }} color={'#332D41'} align="left" >
                                        {users}
                                    </Typography>
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Link to="/admin-events">
                                <Paper sx={{ height: 140 }} style={{ borderRadius: 20, boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)" }}
                                    onClick={(e) => ("/lista-peleas")}>
                                    <Grid direction="row" justifyContent="center" container>
                                        <Grid item xs={8} md={7} lg={7}>
                                            <Stack direction="row" justifyContent="initial" spacing={2} sx={{ ml: 3, pt: 2 }}>
                                                <EmojiEventsIcon sx={{ fontSize: "45px", color: "#DC362E", pt: 1 }}
                                                    style={{ backgroundColor: "#FAF2F2", borderRadius: 12, padding: "10px 10px", }} />
                                                <Typography sx={{ fontSize: 14, pt: 2 }} color={'#1C1B1F'} align="left" >
                                                    Total de eventos
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3} md={3} lg={3}>
                                            <Box sx={{ pt: 3, maxWidth: "120px" }}>
                                                <Typography sx={{ fontSize: 12, pt: 1.5, }} color={'white'} align="center"
                                                    style={{ borderRadius: 35, backgroundColor: "#B3261E", padding: "6px 30px", }}>
                                                    ABIERTAS
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={1} md={2} lg={2}>
                                            <Box sx={{ pt: 3 }} />
                                        </Grid>
                                        <Grid item xs={3} md={2} lg={2} sx={{ pt: 2 }}>
                                            <Typography sx={{ fontSize: 28, pl: 2, fontWeight: "bold" }} color={'#332D41'} align="center" >
                                                {events}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5} md={5} lg={5}>
                                            <Box sx={{ pt: 3 }} />
                                        </Grid>
                                        <Grid item xs={4} md={5} lg={5} >
                                            <Stack direction="row" spacing={2} sx={{ pt: 2 }}>
                                                <Typography sx={{ fontSize: 28, pl: 2, fontWeight: "bold" }} color={'#332D41'} align="left" >
                                                    {eventsLive}
                                                </Typography>
                                                <Box sx={{ maxWidth: "60px" }}>
                                                    <Typography sx={{ fontSize: 12, pt: 0.3 }} color={'#DF2451'} align="left" >
                                                        rifas activas
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid direction="row" justifyContent="center" container rowSpacing={4} columnSpacing={{ sx: 2, sm: 3, md: 4, lg: 4 }} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper sx={{ maxHeight: 460 }} style={{ borderRadius: 20, boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                                <Stack direction="row" justifyContent="initial" spacing={2} sx={{ ml: 3, pt: 2 }}>
                                    <PaidOutlinedIcon sx={{ fontSize: "45px", color: "#4858EA", pt: 1 }}
                                        style={{ backgroundColor: "#F6F9FF", borderRadius: 12, padding: "10px 10px", }} />
                                    <Typography sx={{ fontSize: 14, pt: 1.5 }} color={'#1C1B1F'} align="left" >
                                        Ingresos
                                    </Typography>
                                </Stack>
                                <Grid direction="row" justifyContent="center" container>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography sx={{ fontSize: 28, mt: 2, ml: 4, fontWeight: "bold" }} color={'#332D41'} align="left" >
                                            ${bets}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                                        <Bar options={options} data={barBets} style={{ maxHeight: 250 }} />
                                    </Grid>
                                    <Grid item xs={0} md={12} lg={12} sx={{ mt: 10, mb: 5 }}>
                                    </Grid>
                                    <Grid item xs={12} md={0} lg={0} sx={{ mt: 1, mb: 1 }}>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper sx={{ maxHeight: 720 }} style={{ marginBottom: 20, borderRadius: 20, boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)" }}
                                onClick={(e) => ("/lista-peleas")}>
                                <Stack direction="row" justifyContent="initial" spacing={2} sx={{ ml: 3, pt: 2 }}>
                                    <LocalActivityIcon sx={{ fontSize: "45px", color: "#8338EC", pt: 1 }}
                                        style={{ backgroundColor: "#FAF2F2", borderRadius: 12, padding: "10px 10px", }} />
                                    <Typography sx={{ fontSize: 14, pt: 2 }} color={'#1C1B1F'} align="left" >
                                        Boletos comprados
                                    </Typography>
                                </Stack><br></br>
                                <Grid justifyContent="center" container>
                                    <Table
                                        dataSource={dataTable}
                                        columns={columns}
                                        rowKey="idTable" />
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
};
export default Dashboard;
