import React from "react";
import {
    BoxT,
    ColumnFlex,
    FooterLink,
    ColumnFlexLogo
} from "./FooterStyles";
import "./Footer.css"
import logoFooter from "../assets/logo-footer.png";

const PreguntasRespuestasView = () => {
    return (
        <BoxT>
            <div className="footer-desktop-logo">
                <ColumnFlexLogo>
                    <div className="footer-link">
                        <img src={logoFooter} alt="fb-logo" className='footer-logo' />
                    </div>
                </ColumnFlexLogo>
            </div>
            <div className="footer-desktop">
                <ColumnFlex>
                    <div className="footer-link">
                        <FooterLink href="preguntas-respuestas">Preguntas Frecuentes</FooterLink>
                    </div>
                    <div className="footer-link">
                        <FooterLink href="politica-privacidad">Política de Privacidad</FooterLink>
                    </div>
                    <div className="footer-link">
                        <FooterLink href="terminos-condiciones">Términos y Condiciones</FooterLink>
                    </div>
                </ColumnFlex>
            </div>
        </BoxT>
    );
};
export default PreguntasRespuestasView;