import React from "react";
import NavigateBack from "../../components/NavigateBack/NabigateBack";
import { Form, Input, message } from "antd";
import { useAuth } from "../../context/context";
import { doc, updateDoc, query, collection, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import "./EditProfile.css";

const EditProfile = () => {

  const { userInfo, user, getUserInfo } = useAuth();

  const editProfileUser = async (values) => {
    const phoneNumber = `+52${values.phoneNumber.replace(/\s/g, '')}`;
    try {
      let newData = values;
      newData.phoneNumber = userInfo?.phoneNumber;
      if (userInfo?.phoneNumber !== phoneNumber) {
        const queryCellphone = query(
          collection(db, "user"),
          where("phoneNumber", "==", phoneNumber)
        );
        const querySnapshot = await getDocs(queryCellphone);
        if (!querySnapshot.empty) {
          message.error("El número de celular ya esta en uso", 3);
          return;
        }
        newData.phoneNumber = phoneNumber;
      }
      await updateDoc(doc(db, "user", user.uid), newData);
      getUserInfo(user.uid);
      message.success("Usuario actualizado");
    } catch (err) {
      message.error(err, 5);
    }
  };

  return (
    <div className="container-edit-profile">
      <NavigateBack txt="Mi cuenta" />
      <p className="txt-title-edit-profile">Editar cuenta</p>
      <Form
        layout="vertical"
        initialValues={{
          fullName: userInfo?.fullName,
          phoneNumber: userInfo?.phoneNumber !== null ? userInfo.phoneNumber.slice(3) : userInfo.phoneNumber,
          address: userInfo?.address,
        }}
        onFinish={editProfileUser}
        requiredMark={false}
      >
        <Form.Item
          label="Nombre"
          name="fullName"
          rules={[
            {
              required: true,
              message: "Ingresa tu nombre",
            },
          ]}
        >
          <Input className="input-edit-user" />
        </Form.Item>

        <Form.Item
          label="Dirección"
          name="address"
        >
          <Input className="input-edit-user" />
        </Form.Item>

        <Form.Item
          label="Teléfono"
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: "Ingrese su número de télefono",
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject();
                }
                if (isNaN(value)) {
                  return Promise.reject("Solo debe contener números");
                }
                if (value.toString().length !== 10) {
                  return Promise.reject(
                    "El número debe ser de 10 dígitos"
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            className="input-edit-user"
            prefix="+52"
          />
        </Form.Item>

        <Form.Item>
          <button
            className="btn-save-edit"
            htmlType="submit"
          >
            Guardar
          </button>
        </Form.Item>
      </Form>
    </div>
  )
};

export default EditProfile;
