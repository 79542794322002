import React from "react";
import NavigateBack from "../../components/NavigateBack/NabigateBack";
import { useLocation } from 'react-router-dom';
import CardWinnerItem from "../landingPage/components/CardWinnerItem";

const MyPrizes = () => {
  let { state } = useLocation();
  const earnedEvents = state.items;
  return (
    <div className="container-body">
      <div className="container-info-tickets">
        <NavigateBack txt="Mi cuenta" />
        <p className="txt-title-profile-tickets">Mis premios</p>
        <div className="container-cards">
          {earnedEvents.map((item) => {
            return (
              <CardWinnerItem item={item} />
            )
          })}
        </div>
      </div>      
    </div>
  )
};

export default MyPrizes;
