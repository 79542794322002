import React, { useState, useEffect } from 'react';
import {
  query,
  collection,
  getDocs,
  where
} from "firebase/firestore";
import { db } from '../../firebase';
import "./Winners.css"
import alert from "../../assets/alert.png";
import Box from '@mui/material/Box';
import WinnersEvents from './WinnersEvents';
import Loading from '../../components/Loading';

const Winners = () => {

  const [modalVisible, setModalVisible] = useState(false);
  const [events, setEvents] = useState(null)

  useEffect(() => {
    const getData = async () => {
      const queryEvents = query(
        collection(db, "event"), where("status", "==", "closed")
      );
      const querySnapshot = await getDocs(queryEvents);
      let newDocs = [];
      querySnapshot.forEach((doc) => {
        newDocs.push({ ...doc.data(), id: doc.id });
      });
      validateEvent(newDocs)
      setEvents(newDocs)
    };
    getData();

    const validateEvent = (event) => {
      if (event.length < 1) {
        setModalVisible(true)
      }
    }
  }, []);

  if (!events) {
    return (
      <Loading />
    )
  }

  return (
    <div style={{ backgroundColor: "#F1F3F6"}}>
      {modalVisible &&
        <div style={{ textAlign: "center", paddingTop: "13rem", paddingBottom:"15em"}}>
          <img src={alert} alt="google-logo" style={{ heigth: 45, width: 45, paddingRight: "10px"}} />
          <h2 className='span-option-payment'>
            Por el momento, no tenemos rifas disponibles.
          </h2>
        </div>
      }
      {!modalVisible &&
        <div style={{ justifyContent: "center" }}>
          <div className='label-events'>Ganadores</div>
          <div className='container-event-list-winners'>
            <Box
              sx={{
                marginTop: -3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: "100%",
              }}
            >
              <WinnersEvents />
            </Box>
          </div>
        </div>}
    </div>
  )
};

export default Winners;
