import React from "react";
import { useNavigate } from 'react-router-dom';
import "./CardEventItem.css";
import "../LandingPage.css";

const CardEventItem = ({ item, index }) => {
  const navigate = useNavigate();
  const onClickCard = () => {
    navigate(`/events/${item.id}`, {
      state: {
        ...item,
      },
    })
  }
  

  return (
    <div 
      className="card-events-item"
      onClick={onClickCard}
    >
      <div style={{display: "flex", flexDirection: "row", flex: 1}}>
        <div style={{display: "flex", flex: 1, flexDirection: "column", paddingTop: "12px", justifyContent: "space-between", maxWidth: "120px"}}>
          <div style={{display: "flex", flex: 1}}>
            <span className="title-card-event-item">{item.title}</span>
          </div>

          <div style={{display: "flex", flex: 1, flexDirection: "column", justifyContent: "flex-end", paddingBottom: "2px"}}>
            <span className="price-card-event-item" style={{paddingBottom: "5px"}}>{`$${item.ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            <span className="left-card-event-item">{item.totalTickets} boletos</span>
            <span className="left-card-event-item">quedan <b>{`${item.availableTickets.length}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></span>
          </div>
        </div>
        <div style={{flex: 1, maxWidth: "170px"}}>
          <img src={item.urlIcon} className="img-card-event-item" alt={index}/>
        </div>
      </div>
    </div>
  );
};

export default CardEventItem;
