import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEvent } from "../../functions/event";
import Loading from "../../components/Loading";
import dayjs from 'dayjs';
import { Carousel } from 'react-responsive-carousel';
import { Table, Input, InputNumber } from "antd";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from "react-router-dom";
import ReturnBtn from "../../components/ReturnBtn";
import { SearchOutlined } from "@ant-design/icons";
import { statusTickets } from "../../utils/contants";
import { copyText } from "../../utils/general";
import shareIcon from "../../assets/compartir.png";
import Sheet from 'react-modal-sheet';
import { getUser } from "../../functions/user";
import "../admin/EventDetailsAdmin.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const EventDetails = () => {
  let { eventId } = useParams();
  const navigate = useNavigate();

  const [event, setEvent] = useState(null);
  const [purchasedTickets, setPurchasedTickets] = useState(null);
  const [showModalTickets, setShowModalTickets] = useState(false);
  const [ticketsTotal, setTicketsTotal] = useState([]);

  const getData = async () => {
    const eventRef = collection(db, `event/${eventId}/enrolled`);
    const querySnapshot = await getDocs(eventRef);
    let newDocs = [];

    const currentDate = new Date();

    querySnapshot.forEach((doc) => {
      const ticket = doc.data();
      let expiredTicket = false;
      const buyDate = ticket.buy_date.toDate();
      const buyDateConverted = dayjs(buyDate);
      const buyDateWithDaysAdded = buyDateConverted.add(2, 'day');
      if (currentDate > buyDateWithDaysAdded && ticket.payment_status !== "paid") {
        expiredTicket = true;
      }

      newDocs.push({
        ...doc.data(),
        id: doc.id,
        payment_status: expiredTicket ? "expired" : ticket.payment_status,
      });
    });

    let usersIds = [];

    for (let i = 0; i < newDocs.length; i++) {
      if (!usersIds.includes(newDocs[i].user_id)) {
        usersIds.push(newDocs[i].user_id)
      }
    }

    let users = [];

    for (let i = 0; i < usersIds.length; i++) {
      const response = await getUser(usersIds[i]);
      const user = { ...response.data(), id: usersIds[i] };
      users.push(user);
    }

    for (let i = 0; i < newDocs.length; i++) {
      const currentUserData = users.find(u => u.id === newDocs[i].user_id);
      newDocs[i].email = currentUserData.email;
      newDocs[i].fullName = currentUserData.fullName;
      newDocs[i].phoneNumber = currentUserData.phoneNumber;
    }

    setPurchasedTickets(newDocs);

    let data = await getEvent(eventId);

    if (!data.nationalLotteryEven) {
      const fireBaseEndDate = new Date(
        data.endDate.seconds * 1000 +
        data.endDate.nanoseconds / 1000000
      );
      const fireBaseStartDate = new Date(
        data.startDate.seconds * 1000 +
        data.startDate.nanoseconds / 1000000
      );
      const eventsDates = {
        day: dayjs(fireBaseEndDate).format("dddd D"),
        month: dayjs(fireBaseEndDate).format("MMMM"),
        year: dayjs(fireBaseEndDate).format("YYYY"),
        time: dayjs(fireBaseEndDate).format("h:mm a"),
        startDateFormated: data.startDate.toDate().toLocaleDateString("es-MX"),
        endDateFormated: data.endDate.toDate().toLocaleDateString("es-MX"),
        fireBaseStartDate
      };
      Object.assign(data, eventsDates);
    }

    setTicketsTotal(data.availableTickets);

    setEvent({
      ...data,
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsWithoutActions = [
    {
      title: 'Boleto',
      dataIndex: 'ticket',
      key: 'ticket',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div>
            <Input
              placeholder=""
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <button
              onClick={() => {
                confirm();
              }}
              className="btnSearch"
            >
              Buscar
            </button>
            <button
              onClick={() => {
                clearFilters();
                confirm({ closeDropdown: true });
              }}
              className="btnClear"
            >
              Limpiar
            </button>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.ticket.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Nombre',
      dataIndex: 'fullName',
      key: 'fullName',
      responsive: ["md"],
    },
    {
      title: 'Teléfono',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      responsive: ["md"],
    },
    {
      title: 'Status del pago',
      dataIndex: 'payment_status',
      key: 'payment_status',
      filters: [
        {
          text: "Pagado",
          value: "paid",
        },
        {
          text: "Proceso",
          value: "process",
        },
        {
          text: "Vencido",
          value: "expired",
        },
      ],
      onFilter: (value, record) => record.payment_status.indexOf(value) === 0,
      render: (value, record, index) => {
        const status = statusTickets[value];
        return (
          <p style={{ color: status.color }}>
            {status.title}
          </p>
        );
      },
    },
    {
      title: "Fecha de compra",
      key: "buy_date",
      dataIndex: "buy_date",
      responsive: ["lg"],
      render: (value, record, index) => {
        let buy_date = record.buy_date;
        let time;
        const fireBaseTime = new Date(
          buy_date.seconds * 1000 + buy_date.nanoseconds / 1000000
        );
        const date = fireBaseTime.toLocaleDateString("es-MX");
        const atTime = fireBaseTime.toLocaleTimeString("es-MX");
        time = atTime.slice(0, 5);
        return (
          <>
            {date} a las {time}
          </>
        );
      },
    },
  ];

  const handleCancel = () => {
    setShowModalTickets(false);
  };

  const searchTicket = (value) => {
    if (value != null) {
      const filter = event.availableTickets.filter((str) => str.indexOf(value) !== -1);
      setTicketsTotal(filter);
    } else {
      setTicketsTotal(event.availableTickets);
    }
  };

  if (!event) {
    return (
      <Loading />
    )
  }

  return (
    <div className='container-body-pending'>
      <ReturnBtn fn={() => navigate(`/partner-dashboard`)} />
      <div className='container-row-pending'>
        <div className="row-container-title-mobile">
          <h1 className='title-pending-mobile'>{event.title}</h1>
        </div>

        {/* Carrousel */}
        <div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: "column" }}>
          <div className='size-carrousel-pending'>
            <div className='container-pending-carrousel'>
              <Carousel
                showStatus={false}
              >
                {event.articleUrls.map((item, index) => {
                  return (
                    <div key={index}>
                      <img src={item} alt={index} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
        {/* Description */}
        <div style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "flex-start" }}>
          <div className='container-desc-pending'>
            <div className="row-container-title-desktop">
              <h1 className='title-pending-desktop'>{event.title}</h1>
            </div>
            <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
              <p className='event-pending-price'>{`$${event.ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
              <img alt='icon share' src={shareIcon} className='icon-pending-share' onClick={() => copyText("https://hayrifa.mx/events/" + event.id)} />
            </div>
            <p className='txt-ticktets-pending'>{event.totalTickets} boletos, <b>quedan {event.availableTickets.length}</b></p>
            <p className='txt-ticktets-pending'>Sorteo el <b>{event.day} de {event.month} {event.year}</b></p>
            <p className='txt-ticktets-pending'>Mínimo de boletos vendidos para rifar el ganador: <b>{event.minimumSellTicket}</b></p>
            <p className='txt-ticktets-pending'>Termina con la loteria nacional: <b>{event.nationalLotteryEven ? 'Si' : 'No'}</b></p>
            <p className='txt-ticktets-pending'>Creado por: <b>{event.nameOwner}</b></p>
            <p className='txt-ticktets-pending'>{event.description}</p>
            {event.status === "closed" && (<p className='txt-ticktets-pending'>Ticket ganador: <b>{event.ticketWinner}</b></p>)}
            {event.status !== "closed" && (
              <div className='rowButtons'>
                <div className='colButtons'>
                  <button
                    className='btnManual'
                    onClick={() => {
                      setTicketsTotal(event.availableTickets);
                      setShowModalTickets(true);
                    }}
                  >
                    Boletos disponibles
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", paddingTop: "20px", paddingBottom: "10px" }}>
        <span className='subtitle-event-admin'>Boletos Comprados</span>
      </div>

      <div>
        <Table dataSource={purchasedTickets} columns={columnsWithoutActions} rowKey="id" />
      </div>

      <Sheet isOpen={showModalTickets} onClose={() => setShowModalTickets(false)}>
        <Sheet.Container
          style={{ borderRadius: "30px 30px 0 0" }}>
          <Sheet.Header />
          <div style={{ fontSize: "19px", paddingLeft: "2em", paddingRight: "2em" }}>
            <p>Boletos</p>
          </div>
          <Sheet.Content>
            <div style={{ paddingLeft: "2em", paddingRight: "2em" }}>
              <div style={{ paddingBottom: "2em" }}>
                <InputNumber
                  style={{ paddingBottom: "2em" }}
                  className='inputRegister'
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={searchTicket}
                  placeholder='Buscador' />
              </div>
              <div style={{ paddingBottom: "2em", textAlign: "center" }}>
                <div className='containerFooter'>
                  <div className='containerNumbers'>
                    {ticketsTotal.map((item, index) => {
                      return (
                        <button
                          className={'btn-ticket-available-ed'}
                          key={`btn-${index}`}
                          disabled={true}
                        >
                          {item}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className='manual-btn'>
                <button key="back" className='btnCancel2' onClick={handleCancel}>
                  Cerrar
                </button>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </div>
  )
};

export default EventDetails;
