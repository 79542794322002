import React from "react";
import {
	Box,
	Container,
	Row,
	Column,
	FooterLink,
	HeadingContact
} from "./FooterStyles";
import {
	Button
} from "antd";
import facebookRedes from "../assets/FaceBookRedes.png";
import whatsAppRedes from "../assets/WhatsAppRedes.png";
import instagramRedes from "../assets/instagram.png";

const Footer = () => {
	return (
		<div>
			<div>
				<Column style={{ textAlign: "center" }}>
					<div style={{ paddingLeft: "5em", paddingRight: "5em", alignItems: "center", paddingBottom: "1em" }}>
						<FooterLink style={{ fontSize: "16.5px" }} href="terminos-condiciones">Términos y Condiciones</FooterLink>
					</div>
					<div style={{ paddingLeft: "5em", paddingRight: "5em", alignItems: "center", paddingBottom: "1em" }}>
						<FooterLink style={{ fontSize: "16.5px" }} href="preguntas-respuestas">Preguntas y Respuestas</FooterLink>
					</div>
				</Column>
			</div>
			<Box>
				<Container>
					<Row>
						<div className="footer-desktop-contacto">
							<Column>
								<HeadingContact>Contáctanos</HeadingContact>
								<div className="contacto-desktop">
									<FooterLink href="mailto:contacto@hayrifa.mx?Subject=Soporte%20">contacto@hayrifa.mx</FooterLink>
								</div>
								<div className="contacto-desktop">
									<FooterLink href="tel:+523314683131">3314683131</FooterLink>
								</div>
							</Column>
						</div>
						<div className="footer-desktop-redes">
							<div className="redes-mobile">
								<Column style={{ textAlign: "center", paddingBottom: "1em" }}>
									<FooterLink>Síguenos</FooterLink>
								</Column>
							</div>
							<div style={{ textAlign: "center" }}>
								<div style={{ display: "inline-flex", paddingTop: "1em" }}>
								<div className="redes-desktop">
								<Column style={{ textAlign: "center", paddingBottom: "1em" }}>
									<FooterLink>Síguenos</FooterLink>
								</Column>
							</div>
									<div style={{ paddingRight: "30px", }}>
										<Button
											className="btnLoginSocialRedes"
											style={{ marginTop: 0 }}
											href="https://www.facebook.com/profile.php?id=100087474559601"
											target="_blank"
											icon={<img src={facebookRedes} alt="fb-logo" style={{ heigth: 17, width: 17, paddingTop: "8px" }} />}
										>
										</Button>
									</div>
									<div style={{ paddingRight: "30px" }}>
										<Button
											className="btnLoginSocialWhatsApp"
											style={{ marginTop: 0 }}
											href="https://www.instagram.com/hay.rifa/"
											target="_blank"
											icon={<img src={instagramRedes} alt="fb-logo" style={{ heigth: 37, width: 37 }} />}
										>
										</Button>
									</div>
									<div >
										<Button
											className="btnLoginSocialWhatsApp"
											style={{ marginTop: 0 }}
											href="https://api.whatsapp.com/send?phone=+523314683131&text=Buen día, necesito ayuda."
											target="_blank"
											icon={<img src={whatsAppRedes} alt="fb-logo" style={{ heigth: 37, width: 37 }} />}
										>
										</Button>
									</div>
								</div>
							</div>
						</div>
					</Row>
				</Container>
			</Box>
		</div>
	);
};
export default Footer;