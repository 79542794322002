import React, { useState } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Upload,
  Button,
  Checkbox,
  DatePicker,
  message,
  Spin,
  Select
} from 'antd';
import { subDays } from 'date-fns';
import { UploadOutlined } from "@ant-design/icons";
import locale from "antd/lib/date-picker/locale/es_ES";
import moment from "moment";
import { db, storage } from "../../firebase";
import {
  Timestamp,
  setDoc,
  doc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { uploadBytes, ref, getDownloadURL } from 'firebase/storage';
import { categories } from "../../utils/contants";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../context/context';
import "./RegisterEvent.css";

const RegisterEvent = () => {

  const { user, userInfo } = useAuth();
  const navigate = useNavigate();
  const [tickets, setTickets] = useState(0);
  const [ticketPrice, setTicketPrice] = useState(0);
  const [minimumSellTickets, setMinimumSellTickets] = useState(0);
  // const [endsWithNationalLottery, setEndsWithNationalLottery] = useState(false);
  const endsWithNationalLottery = false;
  const [iconImage, setIconImage] = useState({});
  const [promotionImage, setPromotionImage] = useState({});
  const [articleImages, setArticleImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateEvent, setDateEvent] = useState();
  const [promotionEvent, setPromotionEvent] = useState (false);

  const onChange = (values) => {
    if(values){
      setDateEvent(values._d)
    }
  }
  const onFinish = async (values) => {
    if(userInfo?.type !== "admin" && (userInfo?.fullName === null || userInfo?.fullName === "")) {
      message.error("Antes de crear una rifa completa tu información", 3);
      return;
    }
    if (values.minimumSellTicket > values.totalTickets) {
      message.error("El mínimo de boletos vendidos no debe ser mayor al total de boletos", 3);
      return;
    }

    setLoading(true);
    let startDate, endDate;
    if (!endsWithNationalLottery) {
      const mStartDate = moment(values.startDate).format("YYYY-MM-DD");
      startDate = new Date(mStartDate);
      const mEndDate = moment(values.endDate).format("YYYY-MM-DD");
      endDate = new Date(mEndDate);
    }

    const totalTickets = values.totalTickets;
    const totalDigits = totalTickets.toString().length;
    let availableTickets = [];
    let articleUrls = [];
    
    for (let i = 0; i < totalTickets; i++) {
      const currentNumber = i;
      const restTotal = totalDigits - currentNumber.toString().length;
      const ticket = restTotal === 0 ? `${currentNumber}` : `${"0".repeat(restTotal)}${currentNumber}`;
      availableTickets.push(ticket);
    }

    const id = uuidv4();
    const storageRefIcon = ref(storage, `event/${id}/icon.png`);
    const storageRefPromotion = ref(storage, `event/${id}/promotion.png`);
    const metadata = {
      contentType: "image/jpeg",
      customMetadata: {
        eventId: id
      }

    };
    try {
      // Upload icon
      await uploadBytes(storageRefIcon, iconImage, metadata);
      const urlIcon = await getDownloadURL(storageRefIcon);
      // Upload promotional
      await uploadBytes(storageRefPromotion, promotionImage, metadata);
      const urlPromotion = await getDownloadURL(storageRefPromotion);
      // Upload article images
      for (let i = 0; i < articleImages.length; i++) {
        let storageRefArticle = ref(storage, `event/${id}/article${i}.png`);
        await uploadBytes(storageRefArticle, articleImages[i], metadata);
        let urlArticle = await getDownloadURL(storageRefArticle);
        articleUrls.push(urlArticle);
      }

      const bodyData = {
        eventId: id,
      };

      const data = {
        title: values.title,
        description: values.description,
        totalTickets: values.totalTickets,
        ticketPrice: values.ticketPrice,
        availableTickets,
        ticketWinner: null,
        purchasedTickets: [],
        minimumSellTicket: values.minimumSellTicket,
        nationalLotteryEven: endsWithNationalLottery,
        startDate: endsWithNationalLottery ? '' : Timestamp.fromDate(startDate),
        endDate: endsWithNationalLottery ? '' : Timestamp.fromDate(endDate),
        createDate: Timestamp.fromDate(new Date()),
        urlIcon,
        urlPromotion,
        urlPromotionSmall:null,
        articleUrls,
        articleUrlsMedium: [],
        articleUrlsSmall:[],
        category: values.category,
        status: "pending",
        promotionEvent: promotionEvent,
        idOwner: user.uid,
        nameOwner: userInfo?.type === "admin" ? "Hay Rifa" : userInfo?.fullName,
      };
      
      await setDoc(doc(db, "event", id), data);
      fetch(`${process.env.REACT_APP_API_URL}/optimize-images`, {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      setLoading(false);
      message.success("Rifa creada con exito", 3);
      navigate("/landing")

    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const handleRemove = (file, type) => {
    if (type === "icon") setIconImage({});
    if (type === "promotion") setPromotionImage({});
    if (type === "article") {
      const newArticleImages = articleImages.filter(img => img.uid !== file.uid)
      setArticleImages(newArticleImages);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleBeforeUpload = (file, type) => {
    const isImage = file.type.indexOf("image/") === 0;
    const isJpg = file.type.includes("jpg");
    const isJpeg = file.type.includes("jpeg");
    const isPng = file.type.includes("png");
    const isWebp = file.type.includes("webp");

    if(!isJpg && !isJpeg && !isPng && !isWebp){
      message.error("Sólo se permite formato jpg, jpeg, png y webp!", 3);
      return Upload.LIST_IGNORE;
    }

    if (!isImage) {
      message.error("Solo se pueden subir imagenes!", 3);
      return Upload.LIST_IGNORE;
    }
    const isLt5M = file.size / 1024 / 1024 < 2;
    if (!isLt5M) {
      message.error("La imagen debe ser menor a 2MB!", 3);
      return Upload.LIST_IGNORE;
    }
    if (type === "icon") setIconImage(file);
    if (type === "promotion") setPromotionImage(file);
    if (type === "article") setArticleImages([...articleImages, file]);
    return false;
  };

  return (
    <div className='container-event-reg'>
      <Spin size="large" spinning={loading}>
        <Form
          name="register"
          layout="vertical"
          onFinish={onFinish}
          requiredMark={false}
        >
          <Form.Item
            label="Título"
            name="title"
            rules={[
              {
                required: true,
                message: "Ingrese el título de la rifa",
              },
            ]}
          >
            <Input className='inputRegister' />
          </Form.Item>
          <Form.Item
            label="Descripción"
            name="description"
            rules={[
              {
                required: true,
                message: "Ingrese la descripción",
              },
            ]}
          >
            <Input.TextArea
              className='inputDesc'
              rows={4}
            />
          </Form.Item>
          <div className='container-row-event-reg'>
            <div className='container-col-event-reg'>
              <Form.Item
                label="Total de boletos"
                name="totalTickets"
                rules={[
                  {
                    required: true,
                    message: "Ingrese el total de boletos",
                  },
                ]}
              >
                <InputNumber
                  className='inputRegister'
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  min={1}
                  onChange={setTickets}
                />
              </Form.Item>
              <Form.Item
                label="Costo del boleto"
                name="ticketPrice"
                rules={[
                  {
                    required: true,
                    message: "Ingrese el costo del boleto",
                  },
                ]}
              >
                <InputNumber
                  className='inputRegister'
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  min={1}
                  onChange={setTicketPrice}
                />
              </Form.Item>
              <Form.Item
                label="Mínimo de boletos vendidos"
                name="minimumSellTicket"
                rules={[
                  {
                    required: true,
                    message: "Ingrese el mínimo de boletos vendidos",
                  },
                ]}
              >
                <InputNumber
                  className='inputRegister'
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  min={1}
                  onChange={setMinimumSellTickets}
                  max={tickets}
                />
              </Form.Item>
              <div className='ant-row ant-form-item-row'>
                <div className='ant-col ant-form-item-label'>
                  <label>Total a recaudar</label>
                </div>
                <div>
                  <input
                    className='inputDisabledP'
                    disabled={true}
                    value={`$ ${tickets * ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </div>
              </div>
              <div className='ant-row ant-form-item-row'>
                <div className='ant-col ant-form-item-label'>
                  <label>Mínimo a recaudar</label>
                </div>
                <div>
                  <input
                    className='inputDisabledG'
                    disabled={true}
                    value={`$ ${minimumSellTickets * ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </div>
              </div>
            </div>
            <div className='container-col-event-reg'>
              <Form.Item
                label="Categoría"
                name="category"
                rules={[
                  {
                    required: true,
                    message: "Seleccione la categoría",
                  },
                ]}
              >
                <Select>
                  {categories.map((item, index) => {
                    return (
                      <Select.Option value={item} key={index}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {!endsWithNationalLottery && (
                <>
                  <Form.Item
                    label="Fecha inicio"
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese la fecha de inicio",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      className="inputRegister"
                      format={"DD/MM/YYYY"}
                      locale={locale}
                      onChange={onChange}
                      disabledDate={d => !d || d.isBefore(subDays(new Date(), 1))}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Fecha fin"
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese la fecha de fin",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      className="inputRegister"
                      format={"DD/MM/YYYY"}
                      locale={locale}
                      disabledDate={d => !d || d.isBefore(dateEvent)}
                    />
                  </Form.Item>
                </>
              )}
              {/* <Checkbox onChange={() => setEndsWithNationalLottery(!endsWithNationalLottery)}>Termina con la loteria nacional</Checkbox> */}
            </div>
            <div className='container-col-event-reg'>
              <Form.Item
                label="Imágen de promoción"
                name="promotionImage"
                valuePropName="promotionImage"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Debe subir una imágen promocional",
                  },
                ]}
              >
                <Upload
                  beforeUpload={(file) => handleBeforeUpload(file, "promotion")}
                  onRemove={(file) => handleRemove(file, "promotion")}
                  maxCount={1}
                  listType="picture"
                >
                  <Button className="inputRegister" icon={<UploadOutlined />}>
                    Seleccione una imagen
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                label="Imágen del articulo"
                name="articleImages"
                valuePropName="articleImages"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Debe subir imágenes del articulo",
                  },
                ]}
              >
                <Upload
                  beforeUpload={(file) => handleBeforeUpload(file, "article")}
                  onRemove={(file) => handleRemove(file, "article")}
                  listType="picture"
                >
                  <Button className="inputRegister" icon={<UploadOutlined />}>
                    Seleccione una imagen
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                label="Imágen del logo"
                name="iconImage"
                valuePropName="iconImage"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Debe subir una imágen del logo",
                  },
                ]}
              >
                <Upload
                  beforeUpload={(file) => handleBeforeUpload(file, "icon")}
                  onRemove={(file) => handleRemove(file, "icon")}
                  maxCount={1}
                  listType="picture"
                >
                  <Button className="inputRegister" icon={<UploadOutlined />}>
                    Seleccione un logo
                  </Button>
                </Upload>
              </Form.Item>
              <Checkbox style={{marginBottom: "1em"}} onChange={() => setPromotionEvent(!promotionEvent)} name='promotion'>Promocionar Rifa</Checkbox>
            </div>
          </div>
          <div className='container-row-event-reg'>
            <div className='container-col-event-reg'></div>
            <div className='container-col-event-reg'></div>
            <div className='container-col-event-reg'>
              <Form.Item>
                <Button htmlType="submit" className='btnCreateRifa'>
                  Crea tu rifa
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  )
};

export default RegisterEvent;
