import React from 'react';
import { Spin } from 'antd';

const Loading = () => {
  return (
    <div
      style={{
        height: "100vh", 
        width: "100vw", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center"}}
      >
      <Spin size='large' tip="Cargando...">
      </Spin>
    </div>  
  )
};

export default Loading;