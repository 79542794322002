import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Alert,
  message,
  Modal,
  Divider,
  Checkbox,
} from "antd";
import "./Login.css";
import { passwordValidator } from "../utils/validations";
import { useAuth } from "../context/context";
import { createUser, getUser } from "../functions/user";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";
// import facebookLogo from "../assets/facebook.png";
import googleLogo from "../assets/google.png";
import phone from "../assets/phone.png";
import { useLocation, useNavigate, Link } from "react-router-dom";


const Login = () => {

  const { loginWithGoogle, login, getUserInfo } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [errorLogin, setErrorLogin] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailModal, setEmailModal] = useState("");
  const [modalError, setModalError] = useState(false);

  const redirectUser = (uid) => {
    getUserInfo(uid);
    message.success("Sesión iniciada con exito", 5);
    if (location.state?.from?.pathname) {
      navigate(location.state.from.pathname);
      return;
    } else {
      return navigate("/landing", { replace: true });
    }
  };

  const registerGoogle = async () => {
    try {
      const userCredential = await loginWithGoogle();
      const uid = userCredential.user.uid;
      const user = await getUser(uid);
      const userExists = user.exists();
      if (userExists) {
        if(!user.data().isActive) {
          return;
        }
        return redirectUser(uid);
      }
      const values = {
        email: userCredential.user.email,
        fullName: userCredential.user?.displayName || null
      }
      await createUser(values, "customer", uid);
      return redirectUser(uid);
    } catch (error) {
      message.error(error, 7);
    }
  };

  // const registerFacebook = async () => {
  //   try {
  //     const userCredential = await loginWithFacebook();
  //     const uid = userCredential.user.uid;
  //     const user = await getUser(uid);
  //     const userExists = user.exists();     
  //     if (userExists) {
  //       if(!user.data().isActive) {
  //         return;
  //       }
  //       return redirectUser(uid);
  //     }
  //     const values = {
  //       email: userCredential.user.email,
  //       fullName: userCredential.user?.displayName || null
  //     }
  //     await createUser(values, "customer", uid);
  //     return redirectUser(uid);
  //   } catch (error) {
  //     message.error(error, 7);
  //   }
  // };

  const loginForm = async (values) => {
    setErrorLogin("");
    try {
      const userCredential = await login(values.email, values.password);
      const uid = userCredential.user.uid;
      const user = await getUser(uid);
      if(!user.data().isActive) {
        return;
      }
      return redirectUser(uid);
    } catch (err) {
      console.log(err.message);
      if (err.code === "auth/wrong-password") {
        setErrorLogin("Constraseña incorrecta");
      } else if (err.code === "auth/user-not-found") {
        setErrorLogin("Correo o contraseña son incorrectos");
      } else {
        setErrorLogin(err.message);
      }
    }
  };

  const sendEmailPassword = () => {
    if (emailModal === "") {
      setModalError(true);
      return;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailModal)) {
      setModalError("Dirección de correo inválida");
      return;
    }
    auth.languageCode = "es";
    sendPasswordResetEmail(auth, emailModal)
      .then(() => {
        setModalError(false);
        setEmailModal("");
        setIsModalVisible(false);
        message.success("Email de recuperación de contraseña enviado");
      })
      .catch((error) => {
        setModalError(error.message);
      });
  };

  return (
    <div className="containerRegister">
      <div className="containerRegisterForm">
        <div className="registerForm">
          <Form
            name="register"
            layout="vertical"
            onFinish={loginForm}
            requiredMark={false}
          >
            <div className="registerForm-row">
              <div className="registerForm-col">
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese su email",
                    },
                  ]}
                  normalize={(value) => (value || "").toLowerCase()}
                >
                  <Input className="inputRegister" id="emailRegister" />
                </Form.Item>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese su contraseña",
                    },
                    {
                      validator: passwordValidator,
                    },
                  ]}
                >
                  <Input.Password className="inputRegister" />
                </Form.Item>
                <Form.Item
                  name="checkbox"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <div>
                    <Checkbox style={{ color: "#888888" }}>Recordarme</Checkbox>
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className="registerForm-col containerFooter">
              {errorLogin && (
                <Alert
                  className="alertMessage"
                  message="Error"
                  description={errorLogin}
                  type="error"
                  showIcon
                  closable
                />
              )}
              <Form.Item style={{ marginBottom: 0 }}>
                <Button className="btnLoginL" htmlType="submit">
                  Ingresa
                </Button>
              </Form.Item>
              <p
                className="recoveryPassword"
                style={{ marginTop: "20px" }}
                onClick={() => setIsModalVisible(true)}
              >
                Restablecer contraseña
              </p>
              <Link to="/register" state={{ from: { pathname: location.state?.from?.pathname }}} >
                <p
                  className="recoveryPassword"
                >
                  ¿Aún no tienes cuenta? Regístrate
                </p>
              </Link>
              <Divider style={{ backgroundColor: "#8338EC" }} />
              <Form.Item style={{ marginBottom: 0 }}>
              <Link to="/phone-login" state={{ from: { pathname: location.state?.from?.pathname }}}>
                  <Button
                    className="btnLoginSocialR"
                    style={{ marginTop: 0 }}
                    icon={<img src={phone} alt="google-logo" style={{ heigth: 35, width: 35, paddingRight: "10px", paddingBottom: "3px" }} />}
                  >
                    Ingresa con número telefónico
                  </Button>
                </Link>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  className="btnLoginSocialR"
                  onClick={registerGoogle}
                  icon={<img src={googleLogo} alt="google-logo" style={{ heigth: 35, width: 35, paddingRight: "10px", paddingBottom: "3px" }} />}
                >
                  Ingresa con Google
                </Button><br></br><br></br>
              </Form.Item>
              {/* <Form.Item style={{ marginBottom: 0 }}>
              <Button
                  className="btnLoginSocialR"
                  style={{ marginTop: 0 }}
                  onClick={registerFacebook}
                  icon={<img src={facebookLogo} alt="fb-logo" style={{ heigth: 35, width: 35, paddingRight: "10px", paddingBottom: "3px" }} />}
                >
                  Ingresa con Facebook
                </Button>
              </Form.Item> */}
            </div>
          </Form>
        </div>
      </div>
      <Modal
        title="Ingrese su email para recuperar su cuenta"
        centered
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setModalError(false);
          setEmailModal("");
        }}
        onOk={sendEmailPassword}
        footer={[
          <Button key="submit" type="primary" onClick={sendEmailPassword}>
            Enviar
          </Button>,
        ]}
      >
        <p>Email</p>
        <Input
          value={emailModal}
          onChange={(e) => {
            setEmailModal(e.target.value);
          }}
          className="inputRegister"
          type="email"
        />
        {modalError && (
          <span style={{ marginTop: "1rem", color: "#ff0000" }}>
            Ingrese un email valido
          </span>
        )}
      </Modal>
    </div>
  )
};

export default Login;
