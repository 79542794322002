import React, { useEffect, useState } from "react";
import { Table, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  query,
  collection,
  getDocs,
  deleteDoc,
  doc
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import { message, Spin } from "antd";
import { ref, deleteObject } from "firebase/storage";
import { statusEvent } from "../../utils/contants";
import "./EventListAdmin.css";

const EventListAdmin = () => {
  const navigate = useNavigate();

  const [rifas, setRifas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const queryEvents = query(
        collection(db, "event")
      );
      const querySnapshot = await getDocs(queryEvents);
      let newDocs = [];
      querySnapshot.forEach((doc) => {
        newDocs.push({ ...doc.data(), id: doc.id });
      });
      setRifas(newDocs);
    };
    getData();
  }, []);

  const deleteEvent = async (event) => {
    if (event.purchasedTickets.length > 0) {
      message.error("No puedes eliminar la rifa porque ya se han vendido tickets", 3);
      return;
    }

    setLoading(true);

    try {
      for (let i = 0; i < event.articleUrls.length; i++) {
        const fileRef = ref(storage, event.articleUrls[i]);
        await deleteObject(fileRef);
      }

      const urlIconRef = ref(storage, event.urlIcon);
      await deleteObject(urlIconRef);

      const urlPromotionRef = ref(storage, event.urlPromotion);
      await deleteObject(urlPromotionRef);

      await deleteDoc(doc(db, "event", event.id));
      message.success("Rifa eliminada con exito", 3);
      const newDocs = rifas.filter(rifa => rifa.id !== event.id);
      setRifas(newDocs);
      setLoading(false);
    } catch (error) {
      message.error("Error al eliminar rifa", 3);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Titulo',
      dataIndex: 'title',
      key: 'title',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div>
            <Input
              placeholder=""
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <button
              onClick={() => {
                confirm();
              }}
              className="btnSearch"
            >
              Buscar
            </button>
            <button
              onClick={() => {
                clearFilters();
                confirm({ closeDropdown: true });
              }}
              className="btnClear"
            >
              Limpiar
            </button>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        if (record.title != null) {
          return record.title.toLowerCase().includes(value.toLowerCase());
        }

      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: "Pendiente",
          value: "pending",
        },
        {
          text: "Proceso",
          value: "open",
        },
        {
          text: "Cerrada",
          value: "closed",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (value, record, index) => {
        const status = statusEvent[value];
        return (
          <p style={{ color: status.color }}>
            {status.title}
          </p>
        );
      },
    },
    {
      title: "Fecha y Hora de Sorteo",
      key: "endDate",
      dataIndex: "endDate",
      responsive: ["lg"],
      render: (value, record, index) => {
        let endDate = record.endDate;
        let time;
        const fireBaseTime = new Date(
          endDate.seconds * 1000 + endDate.nanoseconds / 1000000
        );
        const date = fireBaseTime.toLocaleDateString("es-MX");
        const atTime = fireBaseTime.toLocaleTimeString("es-MX");
        time = atTime.slice(0, 5);
        return (
          <>
            {date} a las {time}
          </>
        );
      },
    },
    {
      title: "Opciones",
      key: "action",
      render: (text, record) => (
        <>
          <Button
            onClick={() =>
              navigate(`/admin-events/${record.id}`, {
                state: {
                  ...record,
                },
              })
            }
            type="link"
          >
            Ver detalles
          </Button>
          <Button
            onClick={() => {
              deleteEvent(record);
            }}
            type="link"
            danger
            icon={<DeleteOutlined />}
          ></Button>
        </>
      ),
    },
  ];

  return (
    <div className="container-event-list-admin">
      <Spin size="large" spinning={loading}>
        <h1>Rifas</h1>
        <Table dataSource={rifas} columns={columns} rowKey="id" pagination={{ pageSize: 10, showSizeChanger: false }}/>
      </Spin>
    </div>
  )
};

export default EventListAdmin;
