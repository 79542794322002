import React from "react";
import NavigateBack from "../../components/NavigateBack/NabigateBack";
import { useLocation } from 'react-router-dom';
import CardTicketInactive from "./components/CardTicketInactive";

const InactiveTickets = () => {
  let { state } = useLocation();
  const events = state.items;
  return (
    <div className="container-body">
      <div className="container-info-tickets">
        <NavigateBack txt="Mi cuenta" />
        <p className="txt-title-profile-tickets">Boletos inactivos</p>
        <div className="container-cards">
          {events.filter(event => event.activeTickets.length > 0).slice(0, 5).map((item, index) => {
            return (
              <CardTicketInactive event={item} index={index} />
            )
          })}
        </div>
      </div>
      
    </div>
  )
};

export default InactiveTickets;
