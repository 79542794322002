import React from "react";
import NavigateBack from "../../components/NavigateBack/NabigateBack";
import { useLocation } from 'react-router-dom';
import CardTicketPending from "./components/CardTicketPending";

const PendingTickets = () => {
  let { state } = useLocation();
  const events = state.items;
  return (
    <div className="container-body">
      <div className="container-info-tickets">
        <NavigateBack txt="Mi cuenta" />
        <p className="txt-title-profile-tickets">Boletos por confirmar</p>
        <div className="container-cards">
          {events.filter(event => event.pendingTickets.length > 0).slice(0, 5).map((item, index) => {
            return (
              <div key={index}><CardTicketPending event={item} index={index} /></div>
              
            )
          })}
        </div>
      </div>      
    </div>
  )
};

export default PendingTickets;
