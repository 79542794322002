import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../context/context';
import "./LandingPage.css";
import { query, collection, getDocs, where } from "firebase/firestore";
import { db } from "../../firebase";
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import ItemHowWorks from "./components/ItemHowWorks";
import { Carousel } from 'react-responsive-carousel';
import secure from "../../assets/secure.png";
import calendar from "../../assets/calendar.png";
import userHowWorks from "../../assets/user-how-works.png";
import reward from "../../assets/reward.png";
import { Collapse } from 'antd';
import expandIcon from "../../assets/expand.png";
import cellphone from "../../assets/cellphone.png";
import TitleSection from "./components/TitleSection";
import CardEventItem from "./components/CardEventItem";
import CardWinnerItem from "./components/CardWinnerItem";
import Loading from "../../components/Loading";
const { Panel } = Collapse;

const LandingPage = () => {
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const [events, setEvents] = useState([]);
  const [closedEvents, setClosedEvents] = useState([]);
  const [counter, setCounter] = useState([]);
  const [nextEvent, setNextEvent] = useState([]);

  useEffect(() => {
    const docRef = doc(db, `Counter/VXlRnxjtx7vKjdNe630y`);
    const getData = async () => {
      const queryEvents = query(
        collection(db, "event"), where("status", "==", "open")
      );
      const queryClosedEvents = query(
        collection(db, "event"), where("status", "==", "closed")
      );
      const queryNextEvents = query(
        collection(db, "event"), where("promotionEvent", "==", true)
      );
      const docSnap = await getDoc(docRef);
      const counterData = { ...docSnap.data() };
      const querySnapshot = await getDocs(queryEvents);
      let newDocs = [];
      querySnapshot.forEach((doc) => {
        newDocs.push({ ...doc.data(), id: doc.id });
      });
      const querySnapshotClosed = await getDocs(queryClosedEvents);
      let closedDocs = [];
      querySnapshotClosed.forEach((doc) => {
        closedDocs.push({ ...doc.data(), id: doc.id });
      });
      const querySnapshotNextEvent = await getDocs(queryNextEvents    );
      let nextEventsDocs = [];
      querySnapshotNextEvent.forEach((doc) => {
        if(doc.data().status === "open") {
          nextEventsDocs.push({ ...doc.data(), id: doc.id });
        }        
      });
      setEvents(newDocs);
      setClosedEvents(closedDocs);
      setCounter(counterData.count);
      setNextEvent(nextEventsDocs)
    };
    getData();
  }, []);

  const goRegisterEvent = async () => {
    if (userInfo === null || userInfo?.type === "customer") {
      const data = {
        count: counter + 1,
      };
      await updateDoc(doc(db, `Counter/VXlRnxjtx7vKjdNe630y`), data);
      navigate("/register-event-customer");
    }

    if (userInfo?.type === "admin") {
      navigate("/register-event");
    }
  };

  if (!nextEvent) {
    return (
      <Loading />
    )
  }

  return (
    <div>
      {/* header */}
      <div className="container-header">
        <div style={{paddingBottom: "15px"}}>
          <Carousel
              showStatus={false}
              showArrows={false}
              showThumbs={false}
              autoPlay={true}
              centerMode={false}
              interval={3000}
              emulateTouch={true}
              transitionTime={3}
            >
              {nextEvent.map((item, index) => {
                  return (
                    <div
                      key={index} 
                      className="container-item-carrousel" 
                      onClick={() => navigate(`/events/${item.id}`, {
                        state: {
                          ...item,
                        },
                      })}
                    >
                      <div style={{display: "flex", flexDirection: "row", flex: 1}}>
                        <div style={{ display: "flex", flex: 1, justifyContent: "flex-start", flexDirection: "column"}}>
                          <div className="btn-container-item">
                            <p className="txt-nex-item">Próximamente</p>
                          </div>
                          <p className="txt-next-item-title">{item.title}</p>
                        </div>
                        <div className="container-img-next">
                        <img style={{objectFit: "contain"}} src={item.urlIcon} alt={item.urlIcon} className="img-next-item"/>
                        </div>
                      </div>                      
                    </div>
                  )
                })
              }
          </Carousel>
        </div>

        <div className="container-info-header">

          {/* New Create Event */}
          <div className="container-create-event" onClick={goRegisterEvent}>
            <div className="container-text-create-event">
              <p className="txt-create-event"><b>¡Hay Rifa!</b> Es la plataforma que te ayuda a generar y administrar tu rifa entre amigos</p>
              <button className="btn-create-event" onClick={goRegisterEvent}>Crear rifa</button>
            </div>
            <div className="container-img-create-event">
              <img src={cellphone} alt="cellphone" className="img-create-event"/>
            </div>
            
          </div>


          <div className="txt-secure-row">
            <img src={secure} alt="secure icon" className="img-secure"/>
            <p className="txt-secure-p">Plataforma <span style={{fontWeight: 700}}>100% segura y confiable</span></p>
          </div>
        </div>
      </div>
      {/* Rifas body */}
      <div className="container-events">
        {events.length > 0 && (
          <>
            <TitleSection title="Rifas" linkTxt="Ver todas" section="/events" tabIndex={0}/>
            <div className="scrolling-wrapper-flexbox">
              {events.slice(0, 5).map((item, index) => {
                return (
                  <div key={index}><CardEventItem item={item} /></div>
                )
              })}
            </div>
          </>          
        )}
        {closedEvents.length > 0 && (
          <>
            <TitleSection title="Ganadores" linkTxt="Ver todas" section="/winners"/>
            <div className="scrolling-wrapper-flexbox">
              {closedEvents.slice(0, 5).map((item, index) => {
                return (
                  <div key={index}><CardWinnerItem item={item} /></div>
                )           
              })}
            </div>
          </>
        )}        
      </div>
      {/* How it works */}
      <div className="container-how-works">
        <h4 className="txt-subtitle-how-works">¿Cómo funciona?</h4>
        <div className="row-how-works">
          <ItemHowWorks image={calendar} desc="La fecha de comienzo de cada sorteo comenzará a la fecha de inicio según se detalle" alt="calendar"/>
          <ItemHowWorks image={reward} desc="Los premios consistirán en los productos que se describen en detalle en cada sorteo individual" alt="reward"/>
          <ItemHowWorks image={userHowWorks} desc="Todos los ganadores y todas las ganadoras se elegirán mediante selección automática" alt="userHowWorks"/>
        </div>
        <button onClick={() => navigate("/preguntas-respuestas")} className="btn-rules">Ver reglas</button>
      </div>
      {/* FAQ */}
      <div className="container-faq">
        <h4 className="txt-subtitle-faq">Preguntas frecuentes</h4>
        <Collapse
          expandIconPosition='end'
          expandIcon={() => <img src={expandIcon} style={{width: "10px", height: "10px"}} alt="expand icon"/>}
          style={{
            backgroundColor: "#F1F3F6",
            borderRadius: "20px"
          }}
          bordered={false}
        >
          <Panel header="¿Cómo participo en una rifa o sorteo?" key="1" className="txt-question-faq">
            <p>Lo primero que tienes que hacer es, dentro de la plataforma de HayRifa, ir a la página del
              sorteo y seleccionar tu número de la suerte, apartarlo y pagar tu boleto, después debes de
              enviarnos tu comprobante de pago y listo, ya estarás participando. </p>
          </Panel>
          <Panel header="¿Cómo se selecciona un ganador?" key="2" className="txt-question-faq">
            <p>En HayRifa utilizamos un sistema de asignación de ganador de forma aleatoria utilizando
              algoritmos computacionales de última generación, lo que garantiza un número aleatorio sin
              ventajas para nadie. </p>
          </Panel>
          <Panel header="¿Cómo sé si gané una rifa o sorteo?" key="3" className="txt-question-faq">
            <p>El personal de HayRifa te contactará mediante tu información de contacto en la plataforma
              (email/celular).</p>
          </Panel>
          <button onClick={() => navigate("/preguntas-respuestas")} className="btn-go-to-faq">
            Ver más
          </button>
        </Collapse>
      </div> 
    </div>
  )
};

export default LandingPage;
