import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { useAuth } from "../context/context";
import email from "../assets/email.png";
import { message, Form, Input } from "antd";
import "./Login.css";
import { createUser, getUser } from "../functions/user";

function PhoneLogin() {
    const location = useLocation();
    const [error, setError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState();
    const [flag, setFlag] = useState(false);
    const [otp, setOtp] = useState("");
    const [result, setResult] = useState("");
    const { setUpRecaptcha, getUserInfo } = useAuth();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [countDown, setCountDown] = useState(60);
    const timerId = useRef();
    const [blockResendBttn, setBlockResendBttn] = useState(false);

    const redirectUser = (uid) => {
        getUserInfo(uid);
        message.success("Sesión iniciada con exito", 5);
        if (location.state?.from?.pathname) {
            navigate(location.state.from.pathname);
            return;
        } else {
            return navigate("/landing", { replace: true });
        }
    };

    const getOtp = async (value) => {
        console.log(value)
        let phoneNumberMx = "+52" + value.phoneNumber
        setPhoneNumber(value.phoneNumber)
        setError("");
        if (phoneNumber === "" || value.phoneNumber === undefined) {
            return setError("Ingresa tu número!");
        }

        try {
            const response = await setUpRecaptcha(phoneNumberMx);
            setResult(response);
            setFlag(true);
        } catch (err) {
            switch (err.code) {
                case "auth/invalid-phone-number":
                    setError("¡Número de teléfono no valido, compruebalo de nuevo!");
                    console.log(err.message);
                    break;
                default:
                    window.location.reload(false);
                    setError("Error inessperado");
            }
        }
        handleOpen();
    };

    const verifyOtp = async (e) => {
        e.preventDefault();
        setError("");
        if (otp === "" || otp === null) return;
        result.confirm(otp).then(async (result) => {
            const uid = result.user.uid
            const userValid = await getUser(uid);
            const userExists = userValid.exists();
            if (userExists) {
                if(!userValid.data().isActive) {
                    return;
                }
                return redirectUser(uid);
            }
            const values = {
                phoneNumber: result.user.phoneNumber,
                fullName: null,
                email: null
            }
            await createUser(values, "customer", uid);
            return redirectUser(uid);
        }).catch((error) => {
            console.log(error)
        });
    };

    const resendOtp = async (e) => {
        e.preventDefault();
        setError("");
        setBlockResendBttn(true);
        try {
            const response = await setUpRecaptcha(phoneNumber);
            setResult(response);
        } catch (err) {
            switch (err.code) {
                case "auth/invalid-phone-number":
                    setError("¡Número de teléfono no valido, compruebalo de nuevo!");
                    console.log(err.message);
                    break;
                default:
                    //window.location.reload(false);
                    setError("Error inesperado");
            }
        }
    };

    //Timer resend otp
    useEffect(() => {
        if (open) {
            timerId.current = setInterval(() => {
                setCountDown(prev => prev - 1);
            }, 1000)
            return () => clearInterval(timerId.current);
        }
    }, [open]);

    useEffect(() => {
        if (countDown <= 0) {
            clearInterval(timerId.current);
            handleClose();
        }
    }, [countDown]);

    return (
        <>
            <Container style={{marginBottom:"13em"}}
             component="main" maxWidth="sm">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Box noValidate sx={{ mt: 5, width: 1 }}
                        style={{ display: !flag ? "block" : "none" }}>
                        {error && <Alert severity="error" sx={{ mt: 0, mb: 4 }}>{error}</Alert>}
                        <Form
                            name="verifyNumber"
                            layout="vertical"
                            onFinish={getOtp}
                            requiredMark={false}>
                            <Form.Item style={{ marginBottom: 0 }}>
                            </Form.Item>
                            <Form.Item
                                label="Número télefonico"
                                name="phoneNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese su número de télefono",
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject();
                                            }
                                            if (isNaN(value)) {
                                                return Promise.reject("Solo debe contener números");
                                            }
                                            if (value.toString().length !== 10) {
                                                return Promise.reject(
                                                    "El número debe ser de 10 dígitos"
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    className="inputRegister"
                                    prefix="+52"
                                />
                            </Form.Item>
                            &nbsp;
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                size="large"
                                sx={{ mt: 3, mb: 3 }}
                                style={{
                                    color: "#FFFFFF",
                                    borderRadius: 28,
                                    backgroundColor: "#8338EC",
                                    padding: "10px 36px",
                                    fontSize: "14px"
                                }}
                            >
                                Iniciar sesión
                            </Button>
                            <Link to="/login">
                                <Button
                                    className="btnLoginSocialR"
                                    sx={{ mt: 0, mb: 2 }}
                                    style={{ marginTop: 0 }}
                                    icon={<img src={email} alt="fb-logo" style={{ heigth: 35, width: 35, paddingRight: "10px", paddingBottom: "3px" }} />}
                                >
                                    Ingresa con correo electrónico
                                </Button>
                            </Link>
                            <Grid container direction="row" alignItems="center" justifyContent="center">
                                <Grid item xs={12} container direction="column" alignItems="center" justifyContent="center">
                                    <Typography style={{ textAlign: "center", }}>
                                        ¿No estás registrado?
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Link to="/phone-sign-up">
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color={'secondary'}
                                    size={'small'}
                                    sx={{ mt: 2, mb: 8 }}
                                    style={{
                                        color: "#000000",
                                        background:"#FFFFFF",
                                        borderRadius: 28,
                                        border: "solid .02px",
                                        padding: "10px 36px",
                                        fontSize: "14px"
                                    }}
                                >
                                    Regístrate
                                </Button>
                            </Link>
                            <div id="recaptcha-container"></div>
                        </Form>
                        </Box>
                        <Box component="form" onSubmit={verifyOtp} noValidate sx={{ mt: 5 }}
                            style={{ display: flag ? "block" : "none" }}>
                            {error && <Alert severity="error">{error}</Alert>}
                            <Typography sx={{ mb: 2 }} style={{ textAlign: "center", }}>
                                Ingresa el código de verificación que enviamos a tu teléfono {phoneNumber}
                            </Typography>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="otp"
                                label="Código de verificación"
                                type="otp"
                                id="otp"
                                onChange={(e) => setOtp(e.target.value)}
                            />
                            {open ?
                                <Typography sx={{ mt: 2 }} textAlign="center" component="h1" variant="h5" align="center" style={{ fontSize: "14px", color: 'GrayText' }}>
                                    Reenviar código : {countDown}
                                </Typography> :
                                <>
                                    {!blockResendBttn ?
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            sx={{ mt: 3, mb: 3 }}
                                            style={{
                                                borderRadius: 22,
                                                color: "#603B9A",
                                                padding: "8px 24px",
                                                fontSize: "12px"
                                            }}
                                            onClick={(e) => resendOtp(e)}
                                        >
                                            Reenviar código
                                        </Button> :
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            disabled
                                            sx={{ mt: 3, mb: 3 }}
                                            style={{
                                                borderRadius: 22,
                                                padding: "8px 24px",
                                                fontSize: "12px"
                                            }}
                                        >
                                            Código reenviado
                                        </Button>
                                    }
                                </>
                            }
                            &nbsp;
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 8 }}
                                style={{
                                    color: "#FFFFFF",
                                    borderRadius: 28,
                                    backgroundColor: "#603B9A",
                                    padding: "10px 36px",
                                    fontSize: "14px"
                                }}
                            >
                                Continuar
                            </Button>
                            <div id="recaptcha-container-resend" />
                        </Box>
                    </Box>
            </Container>
        </>
    );
};

export default PhoneLogin;
