// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig;
if(process.env.REACT_APP_ENV === "dev") {
  firebaseConfig = {
    apiKey: "AIzaSyAZXwx5YsokdQG0iJI1Fo5_umTLQ9S2Owo",
    authDomain: "hayrifa-dev.firebaseapp.com",
    projectId: "hayrifa-dev",
    storageBucket: "hayrifa-dev.appspot.com",
    messagingSenderId: "383801161619",
    appId: "1:383801161619:web:f2df6181ef226cdaee5a66"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyBJb3ARX5sTpo11D1AV5tOJze7T4yQs0rc",
    authDomain: "hayrifa-53d1c.firebaseapp.com",
    projectId: "hayrifa-53d1c",
    storageBucket: "hayrifa-53d1c.appspot.com",
    messagingSenderId: "731960947594",
    appId: "1:731960947594:web:ce281a015b335d53b44e73",
    measurementId: "G-1GJ2CKLQJE"
  };
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore();
export const storage = getStorage();
