import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/context";
import { Modal, message } from "antd";
import { Link } from "react-router-dom";
import cellphoneIcon from "../../assets/cellphone-profile.png";
import emailIcon from "../../assets/email-profile.png";
import addressIcon from "../../assets/address-profile.png";
import TitleSection from "../landingPage/components/TitleSection";
import { CopyOutlined } from '@ant-design/icons';
import CardTicketPending from "./components/CardTicketPending";
import CardTicketActive from "./components/CardTicketActive";
import CardWinnerItem from "../landingPage/components/CardWinnerItem";
import CardTicketInactive from "./components/CardTicketInactive";
import { db } from "../../firebase";
import { getDocs, query, collection, where, getDoc, doc } from "firebase/firestore";
import Loading from "../../components/Loading";
import { copyText } from "../../utils/general";
import { paymentMethods } from "../../utils/contants";
import './Profile.css';

const Profile = () => {
  const { userInfo, user } = useAuth();

  const [modalVisible, setModalVisible] = useState(false);
  const [earnedEvents, setEarnedEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [closedEvents, setClosedEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasActiveTickets, setHasActiveTickets] = useState(false);
  const [hasPendingTickets, setHasPendingTickets] = useState(false);
  const [hasInactiveTickets, setHasInactiveTickets] = useState(false);

  const getData = async () => {
    setLoading(true);
    let queryRifasIds = query(collection(db, `user/${user.uid}/events`));
    try {
      const querySnapshot = await getDocs(queryRifasIds);
      let idRifas = [];
      querySnapshot.forEach((doc) => {
        idRifas.push({ ...doc.data(), id: doc.id, pendingTickets: [], activeTickets: [] });
      });

      let eventsClosed = [];
      let eventsOpen = [];
      for (let i = 0; i < idRifas.length; i++) {
        const docRef = doc(db, "event", idRifas[i].id);
        const docSnap = await getDoc(docRef);
        const status = docSnap.data().status;
        if (status === "closed") {
          eventsClosed.push({ id: docSnap.id, ...docSnap.data() });
        } else {
          eventsOpen.push({ id: docSnap.id, ...docSnap.data() });
        }
      }

      for (let i = 0; i < eventsOpen.length; i++) {
        const userTicketsRef = collection(db, `user/${user.uid}/events/${eventsOpen[i].id}/tickets`);
        const queryTickets = query(userTicketsRef);
        const querySnapshot = await getDocs(queryTickets);

        let pendingTickets = [];
        let activeTickets = [];

        querySnapshot.forEach((doc) => {
          const ticket = doc.data();
          if (ticket.payment_status === "paid") {
            activeTickets.push({
              ...doc.data(),
              id: doc.id
            });
          } else if (ticket.payment_status === "process") {
            pendingTickets.push({
              ...doc.data(),
              id: doc.id
            });
          }
        });

        if (pendingTickets.length > 0) setHasPendingTickets(true);
        if (activeTickets.length > 0) setHasActiveTickets(true);

        eventsOpen[i].pendingTickets = pendingTickets;
        eventsOpen[i].activeTickets = activeTickets;
      }
      setEvents(eventsOpen);

      if (eventsClosed.length > 0) {
        setHasInactiveTickets(true);
        for (let i = 0; i < eventsClosed.length; i++) {
          const userTicketsRef = collection(db, `user/${user.uid}/events/${eventsClosed[i].id}/tickets`);
          const queryTickets = query(userTicketsRef);
          const querySnapshot = await getDocs(queryTickets);

          let activeTickets = [];

          querySnapshot.forEach((doc) => {
            activeTickets.push({
              ...doc.data(),
              id: doc.id
            });
          });
          eventsClosed[i].activeTickets = activeTickets;
        }

        setClosedEvents(eventsClosed);
      }
    } catch (err) {
      console.log("Error", err);
      message.error(`Error ${err}`, 3);
    }
  };

  const getEarnedEvents = async () => {
    const queryEarnedEvents = query(
      collection(db, "event"), where("idUserWinner", "==", user.uid)
    );
    const querySnapshotEarned = await getDocs(queryEarnedEvents);
    let closedDocs = [];
    querySnapshotEarned.forEach((doc) => {
      closedDocs.push({ ...doc.data(), id: doc.id });
    });
    setEarnedEvents(closedDocs);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    getEarnedEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Loading />
    )
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="principal-profile">

        {/* First column */}
        <div className="col-1">
          <div className="profile-header-background">
            <div className="profile-header">
              <span className="txt-profile-username">
                Hola {userInfo?.fullName}
              </span>
              <Link
                className="btn-edit-account"
                to="/edit-profile"
              >
                Editar
              </Link>
            </div>
            <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
              <div className="profile-body-container">
                <div className="row-profile-container">
                  <img src={cellphoneIcon} alt="cellphone" className="img-icons-container" />
                  <p className="txt-profile-text">{userInfo.phoneNumber}</p>
                </div>
                <div className="row-profile-container">
                  <img src={emailIcon} alt="cellphone" className="img-icons-container" />
                  <p className="txt-profile-text">{userInfo.email}</p>
                </div>
                <div className="row-profile-container">
                  <img src={addressIcon} alt="cellphone" className="img-icons-container" />
                  <p className="txt-profile-text">{userInfo.address}</p>
                </div>
              </div>
            </div>
            <div style={{ paddingLeft: "49px", marginTop: "16px", paddingBottom: "18px" }}>
              <button className="btn-edit-account" onClick={() => setModalVisible(true)}>
                Información para depósitos
              </button>
            </div>
          </div>

          <div className="container-tickets">
            {hasPendingTickets && (
              <>
                <TitleSection title="Boletos por confirmar" linkTxt="Ver todos" section="/pending-tickets" items={events} />
                <div className="scrolling-wrapper-profile">
                  {events.filter(event => event.pendingTickets.length > 0).slice(0, 4).map((item, index) => {
                    return (
                      <CardTicketPending event={item} index={index} style={{ marginRight: "19px" }} />
                    )
                  })}
                </div>
              </>
            )}

            {hasActiveTickets && (
              <>
                <TitleSection title="Boletos activos" linkTxt="Ver todos" section="/active-tickets" items={events} />
                <div className="scrolling-wrapper-profile">
                  {events.filter(event => event.activeTickets.length > 0).slice(0, 4).map((item, index) => {
                    return (
                      <CardTicketActive event={item} index={index} style={{ marginRight: "19px" }} />
                    )
                  })}
                </div>
              </>
            )}
          </div>
        </div>



        {/* Second column */}
        <div className="col-2">
          <div className="container-tickets">

            {earnedEvents?.length > 0 && (
              <>
                <TitleSection title="Mis premios" linkTxt="Ver todos" section="/my-prizes" items={earnedEvents} />
                <div className="scrolling-wrapper-flexbox">
                  {earnedEvents.slice(0, 1).map((item) => {
                    return (
                      <CardWinnerItem item={item} />
                    )
                  })}
                </div>
              </>
            )}

            {hasInactiveTickets && (
              <>
                <TitleSection title="Boletos inactivos" linkTxt="Ver todos" section="/inactive-tickets" items={closedEvents} />
                <div className="scrolling-wrapper-profile">
                  {closedEvents.filter(event => event.activeTickets.length > 0).slice(0, 1).map((item, index) => {
                    return (
                      <CardTicketInactive event={item} index={index} style={{ marginRight: "19px" }} />
                    )
                  })}
                </div>
              </>
            )}
          </div>
        </div>

        <Modal
          open={modalVisible}
          closable={true}
          onCancel={() => setModalVisible(false)}
          footer={null}
        >
          <div>
            <h2 className='title-checkout'>Opciones de pago</h2>
            <div className='title-payment-option'>
              <p>Transferencia electrónica (SPEI)</p>
            </div>
            <div className='container-payment-option'>
              <div style={{ borderBottom: "solid 1px #ededed" }}>
                <div className="checkout-body-left">
                  <span>CLABE</span>
                </div>
                <div className="checkout-body-right">
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <span>{paymentMethods.spei}</span>
                    </div>
                    <div>
                      <p
                        className='copy-item-checkout'
                        onClick={() => copyText(paymentMethods.spei)}
                      >
                        <CopyOutlined />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ borderBottom: "solid 1px #ededed", paddingTop: ".4em" }}>
                <div className="checkout-body-left">
                  <span>Beneficiario </span>
                </div>
                <div className="checkout-body-right">
                  <span>LEOPOLDO MORALES GONZALEZ</span>
                </div>
              </div>
              <div style={{ paddingTop: ".4em" }}>
                <div className="checkout-body-left">
                  <span>Banco</span>
                </div>
                <div className="checkout-body-right">
                  <span>BBVA</span>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: "2em" }}>
              <div className='title-payment-option'>
                <p>Efectivo en puntos de pago</p>
              </div>
              <div className='container-payment-option'>
                <div style={{ borderBottom: "solid 1px #ededed" }}>
                  <div className="checkout-body-left">
                    <span>No. de tarjeta</span>
                  </div>
                  <div className="checkout-body-right">
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%" }}>
                        <span>{paymentMethods.oxxo}</span>
                      </div>
                      <div>
                        <p
                          className='copy-item-checkout'
                          onClick={() => copyText(paymentMethods.oxxo)}
                        >
                          <CopyOutlined />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingTop: ".4em" }}>
                  <div className="checkout-body-left">
                    <span>Comercio </span>
                  </div>
                  <div className="checkout-body-right">
                    <span>Oxxo</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='container-send-invoice'>
              <a
                href="https://api.whatsapp.com/send?phone=+523314683131&text=Buen día, les comparto el comprobante de mi boleto"
                target="_blank" 
                rel="noreferrer" 
                style={{width: "100%"}}
              >
                <button className='btn-send-invoice'>
                  Enviar comprobante de pago
                </button>
              </a>            
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Profile;
