import React, { useState } from "react";
import {
  Button,
  Drawer,
} from "antd";
import LeftMenu from "./LeftMenu";
import { MenuOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../assets/logo_purple.png";
import profileIcon from "../../assets/user.png";
import logoWhite from "../../assets/logo_white.png";
import { useAuth } from "../../context/context";
import "./Navbar.css";

const Navbar = ({ isUserAuthenticated }) => {
  const navigate = useNavigate();
  const { userInfo } = useAuth();

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(!visible);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  return (
    <nav className="navbar">
      <div className="nav-menu">
        <Button className="menuButton" type="text" onClick={showDrawer}>
          <MenuOutlined style={{ color: "#000000" }} />
        </Button>
      </div>
      <div className="nav-logo">
        <Link to="/">
          <img src={Logo} alt="hay rifa logo" className="logo-image"/>
        </Link>
      </div>
      <div className="nav-profile">
        {isUserAuthenticated
          ? <img className="img-profile" alt="profile img" src={profileIcon} onClick={() => navigate("/profile")} />
          : <Link className="text-signin" to="/login">Ingresar</Link>
        }
      </div>

      <Drawer
        className="sidebar"
        placement="left"
        onClose={showDrawer}
        open={visible}
        closeIcon
        style={{ zIndex: 99999 }}
        bodyStyle={{ backgroundColor: "#FFFFFF" }}
        width={300}
        height="100%"
        title={
          <div style={{display: "flex", flex: 1, flexDirection: "column"}}>
            <div style={{flex: 1}}>
              <img src={logoWhite} alt="logo hay rifa" className="img-logo-drawer"/>
            </div>
            {isUserAuthenticated && (
              <div style={{flex: 1, paddingTop: "20px"}}>
                <p className="txt-username">Hola {userInfo?.fullName}</p>
                <Link className="txt-account" to="/profile" onClick={closeDrawer}>Mi cuenta</Link>
              </div>
            )}
            
          </div>
        }      
      >
        <LeftMenu showDrawer={showDrawer} isUserAuthenticated={isUserAuthenticated} />
      </Drawer>
    </nav>
  );
};

export default Navbar;