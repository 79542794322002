import React from 'react';
import { Collapse } from 'antd';
import expandIcon from "../assets/expand.png";
const { Panel } = Collapse;

const PreguntasRespuestas = () => {

  return (
    <div style={{padding: "25px"}}>
      <Collapse
        expandIconPosition='end'
        expandIcon={() => <img alt='expandIcon' src={expandIcon} style={{width: "10px", height: "10px"}}/>}
        style={{
          backgroundColor: "#F1F3F6",
          borderRadius: "20px"
        }}
        bordered={false}
      >
        <Panel header="¿Cómo participo en una rifa o sorteo?" key="1">
          <p>Lo primero que tienes que hacer es, dentro de la plataforma de HayRifa, ir a la página del
            sorteo y seleccionar tu número de la suerte, apartarlo y pagar tu boleto, después debes de
            enviarnos tu comprobante de pago y listo, ya estarás participando. </p>
        </Panel>
        <Panel header="¿Cómo recupero mi contraseña?" key="2">
          <p>Para recuperar tu contraseña es necesario que te pongas en comunicación vía
            <a
              target="_blank"
              rel="noreferrer"
              style={{ paddingLeft: "4px" }}
              href="https://api.whatsapp.com/send?phone=+523314683131&text=Hola, olvidé mi contraseña. ¿Cómo puedo recuperarla? "
            >WhatsApp
            </a> o por email (
            <a
              target="_blank"
              rel="noreferrer"
              style={{ paddingLeft: "4px" }}
              href="mailto:contacto@hayrifa.mx?Subject=Olvidé%20mi%20contraseña"
            >contacto@hayrifa.mx
            </a> ) y nos proporciones tu email con el que te registraste, nosotros te
            haremos llegar tu correo de verificación con un enlace para que cambies tu contraseña.</p>
        </Panel>
        <Panel header="¿Cómo se selecciona un ganador?" key="3">
          <p>En HayRifa utilizamos un sistema de asignación de ganador de forma aleatoria utilizando
            algoritmos computacionales de última generación, lo que garantiza un número aleatorio sin
            ventajas para nadie. </p>
        </Panel>
        <Panel header="¿Cómo sé si gané una rifa o sorteo?" key="4">
          <p>El personal de HayRifa te contactará mediante tu información de contacto en la plataforma
            (email/celular).</p>
        </Panel>
        <Panel header="¿Dónde puedo ver el ganador de una rifa o sorteo?" key="5">
          <p>En la sección de<a
            target="_blank"
            rel="noreferrer"
            style={{ paddingLeft: "4px" }}
            href="/events"
          >rifas pasadas
          </a>, dentro del detalle de cada rifa, puedes ver el boleto ganador.
          </p>
        </Panel>
        <Panel header="¿Quién pude participar en los sorteos?" key="6">
          <p>Todas las personas que vivan en México y que sean amigos de la persona que organiza la rifa o
            sorteo.</p>
        </Panel>
        <Panel header="¿Cuándo se realizan los sorteos?" key="7">
          <p>Los sorteos se realizan una vez que se llegue al número total de boletos vendidos y/o que se
            cumpla la fecha final límite para realizar el sorteo. A las 8:00PM (Hora del centro de México) y
            serán trasmitidos en vivo por nuestro canal de YouTube o Facebook live. </p>
        </Panel>
        <Panel header="Luego de apartar mis boletos y pagarlos, ¿tengo que validar mi pago?" key="8">
          <p>Sí, después de apartar tus boletos tienes 48 horas para pagarlos y enviarnos el comprobante vía
            <a
              target="_blank"
              rel="noreferrer"
              style={{ paddingLeft: "4px" }}
              href="https://api.whatsapp.com/send?phone=+523314683131&text="
            >WhatsApp
            </a> o dentro del chat de ayuda en la plataforma. De no hacerlo dentro de las 48 horas, el
            sistema automáticamente hará disponible esos números.</p>
        </Panel>
        <Panel header="Si gano, ¿Cuánto tiempo tarda en recibir mi premio?" key="9">
          <p>Su premio será entregado personalmente por un miembro del equipo de HayRifa dentro de los
            30 días posteriores al anuncio del ganador. Tu entrega será totalmente gratuita.</p>
        </Panel>
        <Panel header="Si gano, ¿podré tener oportunidad de ganar en otros sorteos?" key="10">
          <p>Sí, cada sorteo es independiente y entre más boletos compres de cada sorteo más
            oportunidades tienes de ganar.</p>
        </Panel>
        <Panel header="¿Qué pasa si no me quieren dar mi premio?" key="11">
          <p>Eso no pasará, el servicio de HayRifa consiste en recaudar el dinero de la venta de los
            boletos y entregarle al organizador de la rifa o sorteo el dinero recaudado a contra entrega del premio, de esta forma garantizamos que todos los premios de los sorteos sean entregados.</p>
        </Panel>
        <Panel header="¿Qué pasa si el sorteo no llega al 100% de boletos vendidos cuando se acaba el tiempo?" key="12">
          <p>Se reprogramará el sorteo para una nueva fecha y de esta forma darle la oportunidad al
            organizador del sorteo de vender todos los boletos faltantes. Esto podrá ocurrir un máximo de
            dos veces. Si pasado este tiempo no se ha podido llegar a la meta el sorteo será cancelado y el
            dinero de los boletos será regresado a los participantes en forma de créditos dentro de la
            plataforma para que puedan ser utilizados en otro sorteo. </p>
        </Panel>
        <Panel header="¿Cómo funciona HayRifa?" key="13">
          <p>HayRifa funciona como un intermediario entre los organizadores de la Rifa o sorteo y los
            compradores de boleto, con la intención de dar certeza de que no existirá ningún favoritismo o
            parcialidad al momento de realizar el sorteo.</p>
        </Panel>
        <Panel header="¿Cómo gana dinero HayRifa?" key="14">
          <p>HayRifa cobra un porcentaje del dinero recaudado como pago por el servicio, este porcentaje
            es pagado por el organizador de la Rifa. </p>
        </Panel>
        <Panel header="¿Cómo puedo comprar un boleto?" key="15">
          <p>Lo primero que tienes que hacer es ir a la página del sorteo y seleccionar tu número de la suerte,
            apartarlo y pagar tu boleto, luego enviarnos tu comprobante de pago y listo, ya estarás participando. </p>
        </Panel>
        <Panel header="¿Dónde puedo ver mis boletos?" key="16">
          <p>Después de iniciar sesión en la plataforma de HayRifa, debes ir al menú que se encuentra en
            la parte superior derecha y presionar Mis tickets, ahí se mostraran los diferentes sorteos en los
            que estás participando, puedes presionar el botón “ver detalles” en la columna de Opciones,
            para ver los boletos que tienes y el estatus de su pago.</p>
        </Panel>
        <Panel header="¿Cómo puedo hacer una cancelación de mi boleto?" key="17">
          <p>Puedes cancelar tu participación en una rifa o sorteo siempre y cuando éste no se haya
            realizado o esté a menos de 72 horas de realizarse. El dinero del costo del boleto se quedará
            como abono para futuras rifas o sorteos en los que quieras participar dentro de la plataforma.</p>
        </Panel>
        <Panel header="¿Cómo puedo contactar a HayRifa?" key="18">
          <p>Siempre será un gusto que te quieras poner en comunicación con nosotros, puedes
            contactarnos vía <a
              target="_blank"
              rel="noreferrer"
              style={{ paddingLeft: "4px" }}
              href="https://api.whatsapp.com/send?phone=+523314683131&text="
            >WhatsApp
            </a> o mandándonos un correo a <a
              target="_blank"
              rel="noreferrer"
              style={{ paddingLeft: "4px" }}
              href="mailto:contacto@hayrifa.mx?Subject=Soporte%20"
            >contacto@hayrifa.mx
            </a></p>
        </Panel>

      </Collapse>
    </div>
  );
};

export default PreguntasRespuestas;