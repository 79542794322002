import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './views/Login';
import LandingPage from './views/landingPage/LandingPage';
import RegisterEvent from './views/event/RegisterEvent';
import EventList from './views/event/EventList';
import EventDetails from './views/event/EventDetails';
import SelectPaymentType from './views/checkout/SelectPaymentType';
import Profile from './views/profile/Profile';
import Tickets from './views/tickets/tickets';
import TicketsDetails from './views/tickets/TicketsDetails';
import Register from './views/register/Register';
import './App.css';
import dayjs from "dayjs";
import "dayjs/locale/es";
import { useAuth } from './context/context';
import ProtectedRoute from './components/ProtectedRoutes';
import Navbar from './components/Navbar';
import Loading from './components/Loading';
import EventListAdmin from './views/admin/EventListAdmin';
import EventListUsersAdmin from './views/admin/EventListUsersAdmin';
import EventDetailsAdmin from './views/admin/EventDetailsAdmin';
import UserDetailsAdmin from './views/admin/UserDetailsAdmin';
import EditEventAdmin from './views/admin/EditEventAdmin';
import ScrollToTop from './components/ScrollToTop';
import Dashboard from './views/admin/Dashboard';
import PhoneSignUp from './views/PhoneSignUp';
import RegisterEventCustomer from './views/event/RegisterEventCustomer';
import PhoneLogin from './views/PhoneLogin';
import Footer from './views/Footer';
import PreguntasRespuestasView from './views/PreguntasRespuestasView';
import TerminosCondiciones from './views/admin/TerminosCondiciones';
import PoliticaPrivacidad from './views/PoliticaPrivacidad'
import PreguntasRespuestas from './views/PreguntasRespuestas';
import Winners from './views/event/Winners';
import EditProfile from './views/profile/EditProfile';
import ActiveTickets from './views/profile/ActiveTickets';
import PendingTickets from './views/profile/PendingTicktes';
import MyPrizes from './views/profile/MyPrizes';
import InactiveTickets from './views/profile/InactiveTickets';
import EventDetailsWinners from './views/event/EventDetailsWinners';
import PendingTicketsDetails from './views/profile/PendingTicketsDetails';
import PurchasedTicketDetails from './views/profile/PurchasedTicketDetails';
import Movements from './views/profile/Movements';
import PartnerDashboard from './views/partner/Dashboard';
import PartnerEventsDetails from './views/partner/EventDetails';

dayjs.locale("es");

function App() {
  const { user, loading, userInfo } = useAuth();
  const isUserAuthenticated = user === null ? false : true;

  // var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/63d045a347425128790f745d/1gnip01im';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();

  return (
    <BrowserRouter>
      {loading && <Loading />}
      <Navbar isUserAuthenticated={isUserAuthenticated} />
      <ScrollToTop />
      <Routes>
        <Route index element={<LandingPage />} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/phone-sign-up" element={<PhoneSignUp />} />
        <Route path="/phone-login" element={<PhoneLogin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/winners" element={<Winners />} />
        <Route path="/terminos-condiciones" element={<TerminosCondiciones />} />
        <Route path="/politica-privacidad" element={<PoliticaPrivacidad />} />
        <Route path="/preguntas-respuestas" element={<PreguntasRespuestas />} />
        <Route path="/register-event-customer" element={<RegisterEventCustomer />} />
        <Route element={<ProtectedRoute isAllowed={!!isUserAuthenticated} />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/movements" element={<Movements />} />
          <Route path="/active-tickets" element={<ActiveTickets />} />
          <Route path="/pending-tickets" element={<PendingTickets />} />
          <Route path="/pending-tickets/:eventId" element={<PendingTicketsDetails />} />
          <Route path="/my-prizes" element={<MyPrizes />} />
          <Route path="/inactive-tickets" element={<InactiveTickets />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/tickets/:eventId" element={<TicketsDetails />} />
          <Route path="/purchase-ticket-details/:eventId" element={<PurchasedTicketDetails />} />
        </Route>
        <Route element={<ProtectedRoute isAllowed={!!isUserAuthenticated} redirectTo={"/login"} />}>
          <Route path="/select-payment-type" element={<SelectPaymentType />} />
        </Route>
        <Route path="/events" element={<EventList />} />
        <Route path="/events/:eventId" element={<EventDetails />} />
        <Route path="/events-winners/:eventId" element={<EventDetailsWinners />} />

        {/* Partner & Admin Views */}
        <Route element={<ProtectedRoute isAllowed={!!isUserAuthenticated} userType={userInfo?.type} validRoles={["partner", "admin"]}/>}>
          <Route path="/register-event" element={<RegisterEvent />} />
        </Route>

        {/* Partner Views */}
        <Route element={<ProtectedRoute isAllowed={!!isUserAuthenticated} userType={userInfo?.type} validRoles={["partner"]}/>}>
          <Route path="/partner-dashboard" element={<PartnerDashboard />} />
          <Route path="/partner-events/:eventId" element={<PartnerEventsDetails />} />
        </Route>

        {/* Admin Views */}
        <Route element={<ProtectedRoute isAllowed={!!isUserAuthenticated} userType={userInfo?.type} validRoles={["admin"]}/>}>
          <Route path="/register-event" element={<RegisterEvent />} />
          <Route path="/admin-events" element={<EventListAdmin />} />
          <Route path="/admin-events/:eventId" element={<EventDetailsAdmin />} />
          <Route path="/admin-events/:eventId/edit" element={<EditEventAdmin />} />
          <Route path="/admin-events-users" element={<EventListUsersAdmin />} />
          <Route path="/admin-dashboard" element={<Dashboard />} />
          <Route path="/admin-events-users/:userId" element={<UserDetailsAdmin />} />
        </Route>
      </Routes>
      <div className='footer'>
      <PreguntasRespuestasView/>
      </div>
      <div className='footer'>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
