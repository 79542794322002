import React from "react";
import { useNavigate } from 'react-router-dom';
import "./CardTicketPending.css";

const CardTicketPending = ({event, index, style}) => {
  const navigate = useNavigate();
  const onClickCard = () => {
    navigate(`/pending-tickets/${event.id}`, {
      state: {
        ...event,
      },
    })
  }

  return(
    <div 
      className="card-ticket-pending" 
      key={index} 
      style={style}
      onClick={onClickCard}
    >
      <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
        <div style={{display: "flex", flex: 1, flexDirection: "column"}}>
          <span className="txt-title-card-ticket">{event.title}</span>
          <p className="txt-price-card-ticket">{`$${event.ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        </div>
        <div style={{display: "flex", flex: 1, flexDirection: "column", alignItems: "center"}}>
          <img src={event.urlIcon} alt="imgtest" style={{height: "60px", width: "60px", objectFit: "contain"}}/>
        </div>
      </div>
      <div style={{display: "flex", flex: 1, flexDirection: "row", paddingTop: "20px"}}>
        <div className="col-left-container-tickets">
          <span className="txt-title-container-tickets">Tus números</span>
          <p className="txt-desc-container-tickets">
            {event.pendingTickets.map((item, index) => {        
                return (index ? ', ' : '') + item.id
              })            
            }          
          </p>
        </div>
        <div className="col-right-container-tickets">
          <span className="txt-title-container-tickets">Costo total</span>
          <p className="txt-desc-container-tickets">{`$${event.pendingTickets.length * event.ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        </div>
      </div>
    </div>
  )
};

export default CardTicketPending;
