import React, { useState } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Button,
  Checkbox,
  DatePicker,
  message,
  Spin,
  Upload,
  Select
} from 'antd';
import locale from "antd/lib/date-picker/locale/es_ES";
import moment from "moment";
import { db, storage } from "../../firebase";
import {
  Timestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { metadata } from "../../utils/contants";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import ReturnBtn from '../../components/ReturnBtn';
import { categories } from '../../utils/contants';
import "../event/RegisterEvent.css";

message.config({
  top: 100,
  duration: 2,
});

const EditEventAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const event = location.state;
  const currentDate = new Date().getTime();
  let eventHasStarted = false;
  if (currentDate > event.fireBaseStartDate
    || event.purchasedTickets.length > 0) {
    eventHasStarted = true;
  }
  const currentPromotionImage = [{ url: event.urlPromotion }];
  const currentIconImage = [{ url: event.urlIcon }];
  const currentArticleImages = event.articleUrls.map((item, index) => { 
    return { url: item, uid: uuidv4() }
  })

  const [tickets, setTickets] = useState(event.totalTickets);
  const [ticketPrice, setTicketPrice] = useState(event.ticketPrice);
  const [minimumSellTickets, setMinimumSellTickets] = useState(event.minimumSellTicket);
  const [endsWithNationalLottery, setEndsWithNationalLottery] = useState(event.nationalLotteryEven);
  const [loading, setLoading] = useState(false);
  const [iconImage, setIconImage] = useState({});
  const [promotionImage, setPromotionImage] = useState({});
  const [promotionEvent, setPromotionEvent] = useState (event.promotionEvent);
  const [articleImagesToDelete, setArticleImagesToDelete] = useState([]);
  const [articleImagesToUpload, setArticleImagesToUpload] = useState([])

  const handleRemove = (file, type) => {
    if (type === "icon") setIconImage({});
    if (type === "promotion") setPromotionImage({});
    if (type === "article") {
      if(file.hasOwnProperty("url")){
        setArticleImagesToDelete([...articleImagesToDelete, file.url]);
      } else {
        const newArticleImages = articleImagesToUpload.filter(img => img.uid !== file.uid)
        setArticleImagesToUpload(newArticleImages);
      }
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleBeforeUpload = (file, type) => {
    const isImage = file.type.indexOf("image/") === 0;
    if (!isImage) {
      message.error("Solo se pueden subir imagenes!", 3);
      return Upload.LIST_IGNORE;
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("La imagen debe ser menor a 5MB!", 3);
      return Upload.LIST_IGNORE;
    }
    if (type === "icon") setIconImage(file);
    if (type === "promotion") setPromotionImage(file);
    if (type === "article") setArticleImagesToUpload([...articleImagesToUpload, file]);
    return false;
  };

  const onFinish = async (values) => {
    setLoading(true);
    let startDate, endDate;
    if (!endsWithNationalLottery) {
      const mStartDate = moment(values.startDate).add(1, 'days').format("YYYY-MM-DD");
      startDate = new Date(mStartDate);
      const mEndDate = moment(values.endDate).add(1, 'days').format("YYYY-MM-DD");
      endDate = new Date(mEndDate);
    }

    let data = {};

    if (Object.keys(iconImage).length !== 0) {
      const storageRefIcon = ref(storage, `event/${event.id}/icon.png`);
      await uploadBytes(storageRefIcon, iconImage, metadata);
      const urlFile = await getDownloadURL(storageRefIcon);
      data.iconImage = urlFile;
    }

    if (Object.keys(promotionImage).length !== 0) {
      const storageRefPromotion = ref(storage, `event/${event.id}/promotion.png`);
      await uploadBytes(storageRefPromotion, promotionImage, metadata);
      const urlFile = await getDownloadURL(storageRefPromotion);
      data.urlPromotion = urlFile;
    }

    let articleUrls = event.articleUrls;

    if(articleImagesToDelete.length > 0) {
      for(let i = 0; i < articleImagesToDelete.length; i++){
        const fileRef = ref(storage, articleImagesToDelete[i]);        
        await deleteObject(fileRef);
        const index = articleUrls.indexOf(articleImagesToDelete[i]);
        articleUrls.splice(index, 1);
      }
    }
    

    if(articleImagesToUpload.length > 0) {
      for(let i = 0; i < articleImagesToUpload.length; i++){
        const idFile = uuidv4();
        let storageRefArticle = ref(storage, `event/${event.id}/article-${idFile}.png`);
        await uploadBytes(storageRefArticle, articleImagesToUpload[i], metadata);
        let urlArticle = await getDownloadURL(storageRefArticle);
        articleUrls.push(urlArticle);
      }
    }

    data.articleUrls = articleUrls;

    if (eventHasStarted) {
      const newData = {
        ...data,
        title: values.title,
        description: values.description,
        category: values.category,
        endDate: endsWithNationalLottery ? '' : Timestamp.fromDate(endDate),
        promotionEvent: promotionEvent,
        status: values.status,
      };
      try {
        await updateDoc(doc(db, `event/${event.id}`), newData);
        message.success("Rifa actualizada con exito");
        navigate(`/admin-events/${event.id}`);
        return;
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    }

    const totalTickets = values.totalTickets;
    const totalDigits = totalTickets.toString().length;
    let availableTickets = [];
    for (let i = 0; i <= totalTickets; i++) {
      const currentNumber = i;
      const restTotal = totalDigits - currentNumber.toString().length;
      const ticket = restTotal === 0 ? `${currentNumber}` : `${"0".repeat(restTotal)}${currentNumber}`;
      availableTickets.push(ticket);
    }

    const newData = {
      ...data,
      title: values.title,
      description: values.description,
      totalTickets: values.totalTickets,
      ticketPrice: values.ticketPrice,
      availableTickets,
      minimumSellTicket: values.minimumSellTicket,
      nationalLotteryEven: endsWithNationalLottery,
      startDate: endsWithNationalLottery ? '' : Timestamp.fromDate(startDate),
      endDate: endsWithNationalLottery ? '' : Timestamp.fromDate(endDate),
      category: values.category,
      promotionEvent: promotionEvent
    };

    try {
      await updateDoc(doc(db, `event/${event.id}`), newData);
      message.success("Rifa actualizada con exito");
      navigate(`/admin-events/${event.id}`);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container-event-reg'>
      <Spin size="large" spinning={loading}>
        <ReturnBtn fn={() => navigate(`/admin-events/${event.id}`)}/>
        <Form
          name="register"
          layout="vertical"
          onFinish={onFinish}
          requiredMark={false}
          initialValues={{
            title: event.title,
            description: event.description,
            totalTickets: event.totalTickets,
            ticketPrice: event.ticketPrice,
            minimumSellTicket: event.minimumSellTicket,
            startDate: moment(event.startDateFormated, "DD/MM/YYYY"),
            endDate: moment(event.endDateFormated, "DD/MM/YYYY"),
            promotionImage: currentPromotionImage,
            iconImage: currentIconImage,
            articleImages: currentArticleImages,
            category: event.category,
            promotionEvent: event.promotionEvent,
            status: event.status,
          }}
        >
          <Form.Item
            label="Título"
            name="title"
            rules={[
              {
                required: true,
                message: "Ingrese el título de la rifa",
              },
            ]}
          >
            <Input className='inputRegister' />
          </Form.Item>
          <Form.Item
            label="Descripción"
            name="description"
            rules={[
              {
                required: true,
                message: "Ingrese la descripción",
              },
            ]}
          >
            <Input.TextArea
              className='inputDesc'
              rows={4}
            />
          </Form.Item>
          <div className='container-row-event-reg'>
            <div className='container-col-event-reg'>
              <Form.Item
                label="Total de boletos"
                name="totalTickets"
                rules={[
                  {
                    required: true,
                    message: "Ingrese el total de boletos",
                  },
                ]}
              >
                <InputNumber
                  className='inputRegister'
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  min={1}
                  onChange={setTickets}
                  disabled={eventHasStarted}
                />
              </Form.Item>
              <Form.Item
                label="Costo del boleto"
                name="ticketPrice"
                rules={[
                  {
                    required: true,
                    message: "Ingrese el costo del boleto",
                  },
                ]}
              >
                <InputNumber
                  className='inputRegister'
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  min={1}
                  onChange={setTicketPrice}
                  disabled={eventHasStarted}
                />
              </Form.Item>
              <Form.Item
                label="Mínimo de boletos vendidos"
                name="minimumSellTicket"
                rules={[
                  {
                    required: true,
                    message: "Ingrese el costo del boleto",
                  },
                ]}
              >
                <InputNumber
                  className='inputRegister'
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  min={1}
                  onChange={setMinimumSellTickets}
                  disabled={eventHasStarted}
                />
              </Form.Item>
              <div className='ant-row ant-form-item-row'>
                <div className='ant-col ant-form-item-label'>
                  <label>Total a recaudar</label>
                </div>
                <div>
                  <input
                    className='inputDisabledP'
                    disabled={true}
                    value={`$ ${tickets * ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </div>
              </div>
              <div className='ant-row ant-form-item-row'>
                <div className='ant-col ant-form-item-label'>
                  <label>Mínimo a recaudar</label>
                </div>
                <div>
                  <input
                    className='inputDisabledG'
                    disabled={true}
                    value={`$ ${minimumSellTickets * ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </div>
              </div>
            </div>
            <div className='container-col-event-reg'>
              <Form.Item
                  label="Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "Seleccione la categoría",
                    },
                  ]}
              >
                <Select disabled={event.status === "open"}>
                  <Select.Option value="pending" key="pending">
                    Pendiente
                  </Select.Option>
                  <Select.Option value="open" key="open">
                    Activa
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                  label="Categoría"
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: "Seleccione la categoría",
                    },
                  ]}
              >
                <Select>
                  {categories.map((item, index) => {
                    return (
                      <Select.Option value={item} key={index}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {!endsWithNationalLottery && (
                <>
                  <Form.Item
                    label="Fecha inicio"
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese la fecha de inicio",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      className="inputRegister"
                      format={"DD/MM/YYYY"}
                      locale={locale}
                      disabled={eventHasStarted}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Fecha fin"
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese la fecha de fin",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      className="inputRegister"
                      format={"DD/MM/YYYY"}
                      locale={locale}
                    />
                  </Form.Item>
                </>
              )}
              <Checkbox onChange={() => setEndsWithNationalLottery(!endsWithNationalLottery)}>Termina con la loteria nacional</Checkbox>
            </div>
            <div className='container-col-event-reg'>
              <Form.Item
                label="Imágen de promoción"
                name="promotionImage"
                valuePropName="promotionImage"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Debe subir una imágen promocional",
                  },
                ]}
              >
                <Upload
                  beforeUpload={(file) => handleBeforeUpload(file, "promotion")}
                  onRemove={(file) => handleRemove(file, "promotion")}
                  maxCount={1}
                  listType="picture"
                  defaultFileList={currentPromotionImage}
                >
                  <Button className="inputRegister" icon={<UploadOutlined />}>
                    Seleccione una imagen
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                label="Imágen del articulo"
                name="articleImages"
                valuePropName="articleImages"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Debe subir imágenes del articulo",
                  },
                ]}
              >
                <Upload
                  beforeUpload={(file) => handleBeforeUpload(file, "article")}
                  onRemove={(file) => handleRemove(file, "article")}
                  listType="picture"
                  defaultFileList={currentArticleImages}
                >
                  <Button className="inputRegister" icon={<UploadOutlined />}>
                    Seleccione una imagen
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                label="Imágen del logo"
                name="iconImage"
                valuePropName="iconImage"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Debe subir una imágen del logo",
                  },
                ]}
              >
                <Upload
                  beforeUpload={(file) => handleBeforeUpload(file, "icon")}
                  onRemove={(file) => handleRemove(file, "icon")}
                  maxCount={1}
                  listType="picture"
                  defaultFileList={currentIconImage}
                >
                  <Button className="inputRegister" icon={<UploadOutlined />}>
                    Seleccione un logo
                  </Button>
                </Upload>
              </Form.Item>
              <Checkbox checked={promotionEvent} style={{marginBottom: "1em"}} onChange={() => setPromotionEvent(!promotionEvent)} name='promotionEvent'>Promocionar Rifa</Checkbox>
            </div>
          </div>
          <div className='container-row-event-reg'>
            <div className='container-col-event-reg'></div>
            <div className='container-col-event-reg'></div>
            <div className='container-col-event-reg'>
              <Form.Item>
                <Button htmlType="submit" className='btnCreateRifa'>
                  Guardar cambios
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  )
};

export default EditEventAdmin;
