import React from 'react';
import { useLocation } from 'react-router-dom';
import { Table } from 'antd';
import dayjs from 'dayjs';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { statusTickets } from "../../utils/contants";
import { copyText } from '../../utils/general';
import shareIcon from "../../assets/compartir.png";
import './PendingTicketsDetails.css';

const PurchasedTicketDetails = () => {
  let { state } = useLocation();

  const fireBaseTime = new Date(
    state.endDate.seconds * 1000 +
    state.endDate.nanoseconds / 1000000
  );
  state.day = dayjs(fireBaseTime).format("dddd D");
  state.month = dayjs(fireBaseTime).format("MMMM");
  state.year = dayjs(fireBaseTime).format("YYYY");
  state.time = dayjs(fireBaseTime).format("h:mm a");

  const columns = [
    {
      title: 'Ticket',
      dataIndex: 'ticket',
      key: 'ticket',
    },
    {
      title: 'Status del Pago',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (value, record, index) => {
        const status = statusTickets[value];
        return (
          <p style={{ color: status.color }}>
            {status.title}
          </p>
        );
      },
    },
    {
      title: "Fecha y Hora de Compra",
      key: "buy_date",
      dataIndex: "buy_date",
      render: (value, record, index) => {
        let buy_date = record.buy_date;
        let time;
        const fireBaseTime = new Date(
          buy_date.seconds * 1000 + buy_date.nanoseconds / 1000000
        );
        const date = fireBaseTime.toLocaleDateString("es-MX");
        const atTime = fireBaseTime.toLocaleTimeString("es-MX");
        time = atTime.slice(0, 5);
        return (
          <>
            {date} a las {time}
          </>
        );
      },
    },
  ];

  return (
    <div className='container-body-pending'>
      <div className='container-row-pending'>
        <h1 className='title-pending-mobile'>{state.title}</h1>
        <div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: "column" }}>
          {/* Carrousel */}
          <div className='size-carrousel-pending'>
            <div className='container-pending-carrousel'>
              <Carousel
                showStatus={false}
              >
                {state.articleUrls.map((item, index) => {
                  return (
                    <div key={index}>
                      <img style={{ width: "88%" }} src={item} alt={index} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
        {/* Tickets */}
        <div style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "flex-start" }}>
          <div className='container-desc-pending'>
            <h1 className='title-pending-desktop'>{state.title}</h1>
            <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
              <p className='event-pending-price'>{`$${state.ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
              <img alt='icon share' src={shareIcon} className='icon-pending-share' onClick={() => copyText("https://hayrifa.mx/events/" + state.id)} />
            </div>            
            {state.status === "closed" && (
              <>
                <p className='txt-ticktets-pending'>Ganador: <b> {state.nameWinner}</b></p>
                <p className='txt-ticktets-pending'>Ticket ganador: <b> {state.ticketWinner}</b></p>
                <p className='text-tickets'>Finalizó el <b>{state.day} de {state.month} {state.year}</b></p>
                <p className='txt-ticktets-pending' style={{ paddingBottom: "20px" }}>Creado por <b>{state.nameOwner}</b></p>
              </>              
            )}            
            {state.status === "open" && (
              <>
                <p className='txt-ticktets-pending'>{state.totalTickets} boletos, <b>quedan {state.availableTickets.length}</b></p>
                <p className='txt-ticktets-pending'>Sorteo el <b>{state.day} de {state.month} {state.year}</b></p>
                <p className='txt-ticktets-pending' style={{ paddingBottom: "20px" }}>Creado por <b>{state.nameOwner}</b></p>
              </>              
            )}            
            <Table dataSource={state.activeTickets} columns={columns} rowKey="id" pagination={{ pageSize: 5, showSizeChanger: false }} />
            <div className='container-pending-total'>
              <p className='txt-total-pending'>Costo total</p>
              <p className='txt-calc-pending'>{`$${state.activeTickets.length * state.ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PurchasedTicketDetails;
