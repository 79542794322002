import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUser } from "../../functions/user";
import Loading from "../../components/Loading";
import { collection, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Form, Modal, Input, Select, message } from "antd";
import cellphoneIcon from "../../assets/cellphone-profile.png";
import emailIcon from "../../assets/email-profile.png";
import addressIcon from "../../assets/address-profile.png";
import userIcon from "../../assets/user.png";
import "./UserDetailsAdmin.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


const UserDetailsAdmin = () => {
  let { userId } = useParams();
  const [user, setUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [formEdit] = Form.useForm();

  const getData = async () => {
    const data = await getUser(userId);
    setUser({
      ...data.data(),
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancelEdit = () => {
    formEdit.resetFields();
    setOpenModal(false);
  };

  const onEditUser = () => {
    formEdit.validateFields().then((values) => {
      editUser(values);
    });
  };

  const editUser = async (values) => {
    const phoneNumber = `+52${values.phoneNumber.replace(/\s/g, '')}`;
    try {
      let newData =  {...values};
      newData.phoneNumber = user?.phoneNumber;
      if (user?.phoneNumber !== phoneNumber) {
        const queryCellphone = query(
          collection(db, "user"),
          where("phoneNumber", "==", phoneNumber)
        );
        const querySnapshot = await getDocs(queryCellphone);
        if (!querySnapshot.empty) {
          message.error("El número de celular ya esta en uso", 3);
          return;
        }
        newData.phoneNumber = phoneNumber;
      }
      await updateDoc(doc(db, "user", userId), newData);
      setOpenModal(false);
      setUser({...user, ...newData});
      message.success("Usuario actualizado");
    } catch (err) {
      message.error(err, 5);
    }
  }

  const showEditUser = () => {
    formEdit.setFieldsValue({
      fullName: user?.fullName,
      phoneNumber: user?.phoneNumber !== null ? user.phoneNumber.slice(3) : user.phoneNumber,
      address: user?.address,
      type: user?.type
    });
    setOpenModal(true);
  };

  if (!user) {
    return (
      <Loading />
    )
  }

  return (
    <div style={{ display: "flex" }}>
      <div className="principal-profile">

        {/* First column */}
        <div className="col-1">
          <div className="profile-header-background">
            <div className="profile-header">
              <span className="txt-profile-username">
                {user?.fullName}
              </span>
              <button
                className="btn-edit-account"
                onClick={() => showEditUser()}
              >
                Editar
              </button>
            </div>
            <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
              <div className="profile-body-container">
                <div className="row-profile-container">
                  <img src={cellphoneIcon} alt="cellphone" className="img-icons-container" />
                  <p className="txt-profile-text">{user?.phoneNumber}</p>
                </div>
                <div className="row-profile-container">
                  <img src={emailIcon} alt="cellphone" className="img-icons-container" />
                  <p className="txt-profile-text">{user?.email}</p>
                </div>
                <div className="row-profile-container">
                  <img src={addressIcon} alt="cellphone" className="img-icons-container" />
                  <p className="txt-profile-text">{user?.address}</p>
                </div>
                <div className="row-profile-container">
                  <img src={userIcon} alt="cellphone" className="img-icons-container" />
                  <p className="txt-profile-text">{user?.type}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Edit */}
      <Modal
        open={openModal}
        onCancel={onCancelEdit}
        footer={null}
      >
        <Form
          form={formEdit}
          layout="vertical"
          requiredMark={false}
          onFinish={onEditUser}
          name="form_edit"
        >
          <Form.Item
            label="Nombre"
            name="fullName"
          >
            <Input
              style={{ backgroundColor: "#fff", color: "#000000" }}
              className="inputRegister"
            />
          </Form.Item>
          <Form.Item
            label="Dirección"
            name="address"
          >
            <Input className="inputRegister" />
          </Form.Item>
          <Form.Item
            label="Teléfono"
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Ingrese su número de télefono",
              },
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject();
                  }
                  if (isNaN(value)) {
                    return Promise.reject("Solo debe contener números");
                  }
                  if (value.toString().length !== 10) {
                    return Promise.reject(
                      "El número debe ser de 10 dígitos"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="inputRegister"
              prefix="+52"
            />
          </Form.Item>
          <Form.Item
            name="type"
            label="Rol"
            rules={[
              {
                required: true,
                message: "Seleccione un rol",
              },
            ]}
          >
            <Select>
              <Select.Option value={"admin"} key={"user-admin"}>
                Admin
              </Select.Option>
              <Select.Option value={"partner"} key={"user-partner"}>
                Partner
              </Select.Option>
              <Select.Option value={"customer"} key={"user-customer"}>
                Customer
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <button
              className="btn-save-edit"
              htmlType="submit"
            >
              Guardar
            </button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
};

export default UserDetailsAdmin;
