import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Loading from "../../components/Loading";
import dayjs from 'dayjs';
import { useAuth } from '../../context/context';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Carousel } from 'react-responsive-carousel';
import { Table, Modal, message } from 'antd';
import { statusTickets } from "../../utils/contants";
import { CopyOutlined } from '@ant-design/icons';

const TicketsDetails = () => {
  const { state } = useLocation();
  const { user } = useAuth();

  const [event, setEvent] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const getData = async () => {
    const userTicketsRef = collection(db, `user/${user.uid}/events/${state.id}/tickets`);
    const queryTickets = query(userTicketsRef);
    const querySnapshot = await getDocs(queryTickets);
    let newDocs = [];

    const currentDate = new Date();
    querySnapshot.forEach((doc) => {
      const ticket = doc.data();
      let expiredTicket = false;
      const buyDate = ticket.buy_date.toDate();
      const buyDateConverted = dayjs(buyDate);
      const buyDateWithDaysAdded = buyDateConverted.add(2, 'day');

      if (currentDate > buyDateWithDaysAdded && ticket.payment_status === "process") {
        expiredTicket = true;
      }

      newDocs.push({
        ...doc.data(),
        id: doc.id,
        payment_status: expiredTicket ? "expired" : ticket.payment_status,
      });
    });

    setTickets(newDocs);

    let data = state;
    const fireBaseTime = new Date(
      data.endDate.seconds * 1000 +
      data.endDate.nanoseconds / 1000000
    );
    const day = dayjs(fireBaseTime).format("dddd D");
    const month = dayjs(fireBaseTime).format("MMMM");
    const year = dayjs(fireBaseTime).format("YYYY");
    const time = dayjs(fireBaseTime).format("h:mm a");
    setEvent({
      ...data,
      day,
      month,
      year,
      time
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Ticket',
      dataIndex: 'ticket',
      key: 'ticket',
    },
    {
      title: 'Status del Pago',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (value, record, index) => {
        const status = statusTickets[value];
        return (
          <p style={{ color: status.color }}>
            {status.title}
          </p>
        );
      },
    },
    {
      title: "Fecha y Hora de Compra",
      key: "buy_date",
      dataIndex: "buy_date",
      render: (value, record, index) => {
        let buy_date = record.buy_date;
        let time;
        const fireBaseTime = new Date(
          buy_date.seconds * 1000 + buy_date.nanoseconds / 1000000
        );
        const date = fireBaseTime.toLocaleDateString("es-MX");
        const atTime = fireBaseTime.toLocaleTimeString("es-MX");
        time = atTime.slice(0, 5);
        return (
          <>
            {date} a las {time}
          </>
        );
      },
    },
  ];

  const copyPaymentOption = (text) => {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    message.success("Copiado en el portapapeles!");
  };

  if (!event) {
    return (
      <Loading />
    )
  }

  return (
    <div className='container-event-details'>
      {/* Head */}
      <div className='containerHead'>
        {/* Carrousel */}
        <div className='containerCarrousel'>
          <Carousel
            showStatus={false}
          >
            {event.articleUrls.map((item, index) => {
              return (
                <div key={index}>
                  <img src={item} alt={index} />
                </div>
              )
            })}
          </Carousel>
        </div>
        {/* Description */}
        <div className='containerDescription'>
          <h1 className='title'>{event.title}</h1>
          <div className='containerRow'>
            <div className='container1'>
              <span className='description'>{event.description}</span>
            </div>
            <div className='containerInfo'>
              <p className='dateDescription'>Sorteo {event.day} de {event.month} {event.year} {event.time}</p>
              {event.status === "closed" && (<p className='dateDescription'>Ticket ganador: <span style={{ color: "black" }}>{event.ticketWinner}</span></p>)}
            </div>
          </div>
        </div>
      </div>

      <div className='rowButtons'>
        <div className='colButtons'>
          <span className='title'>Tus boletos</span>
        </div>
        <div className='colTotal'>
          <button className='btnManual' style={{marginBottom: "1rem"}} onClick={() => setModalVisible(true)}>
            Referencias de pago
          </button>
        </div>
      </div>
      <Table dataSource={tickets} columns={columns} rowKey="id" pagination={{ pageSize: 10, showSizeChanger: false }}/>

      <Modal
        title="Referencias de pago"
        open={modalVisible}
        closable={false}
        footer={[
          <div style={{ textAlign: "center" }}>
            <button
              key="submit"
              className='btnChooseThis'
              onClick={() => { setModalVisible(false) }}
            >
              Cerrar
            </button>
          </div>
        ]}
      >
        <div style={{ textAlign: "center" }}>
          <h2 className='title-checkout' style={{ paddingTop: "5px" }}>Opciones de pago</h2>
          <div className='container-payment-option'>
            <p>Transferencia electrónica (SPEI)</p>
            <div className='container-option-row'>
              <div className='container-option-column-desc'>
                <span className='text-option-payment'>CLABE: <span className='span-option-payment'>0123 2002 8868 823823</span></span>
                <span className='text-option-payment'>Beneficiario: <span className='span-option-payment'>LEOPOLDO MORALES GONZALEZ</span></span>
                <span className='text-option-payment'>Banco: <span className='span-option-payment'>BBVA</span></span>
              </div>
              <div className='container-option-column-copy'>
                <p
                  className='copy-item-checkout'
                  onClick={() => copyPaymentOption("0123 2002 8868 823823")}
                >
                  <CopyOutlined />
                </p>
              </div>
            </div>
          </div>
          <div className='container-payment-option'>
            <p>Efectivo en puntos de pago</p>
            <div className='container-option-row'>
              <div className='container-option-column-desc'>
                <span className='text-option-payment'>Número de tarjeta: <span className='span-option-payment'>4152 3138 7943 8286</span></span>
                <span className='text-option-payment'>Comercio: <span className='span-option-payment'>OXXO</span></span>
              </div>
              <div className='container-option-column-copy'>
                <p
                  className='copy-item-checkout'
                  onClick={() => copyPaymentOption("4152 3138 7943 8286")}
                >
                  <CopyOutlined />
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
};

export default TicketsDetails;
