
import "./TerminosCondiciones.css";

const TerminosCondiciones = () => {

    return (
        <div className='container-terminos'>
            <div className='container-text'>
                <p style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold" }}>
                    Términos y Condiciones de Uso - hayrifa.mx
                </p>
                <p style={{ fontWeight: "bold" }}>

                    Fecha de entrada en vigor: 10 Julio 2023
                </p>
                <p>
                    Lea atentamente estos Términos y Condiciones de Uso antes de utilizar nuestro sitio web de Rifas y Sorteos. Al acceder o utilizar el sitio web, usted acepta estar legalmente vinculado por estos términos y condiciones.
                </p>
                <p style={{ fontWeight: "bold" }}>


                    1. Uso del sitio web.

                </p>
                <p>
                    (a) Usted declara ser mayor de edad y tener capacidad legal para participar en rifas y sorteos según las leyes de su jurisdicción. Si es menor de edad, debe obtener el consentimiento de un padre o tutor antes de utilizar nuestro sitio web.
                </p>
                <p>
                    (b) El uso del sitio web está sujeto a todas las leyes y regulaciones aplicables. Usted acepta utilizar el sitio web únicamente con fines legales y de acuerdo con estos términos y condiciones.
                </p>
                <p>
                    (c) Usted se compromete a proporcionar información precisa, completa y actualizada al registrarse en el sitio web. Es su responsabilidad mantener la confidencialidad de su cuenta y contraseña, y acepta notificarnos de inmediato cualquier uso no autorizado de su cuenta.
                </p>

                <p style={{ fontWeight: "bold" }}>


                    2. Rifas y sorteos.
                </p>
                <p>
                    (a) Nuestro sitio web ofrece la posibilidad de participar en rifas y sorteos. Al participar, usted acepta cumplir con las reglas y condiciones específicas establecidas para cada rifa o sorteo, que se encuentran disponibles en la página correspondiente.
                </p>
                <p>
                    (b) Todas las rifas y sorteos están sujetos a cambios, cancelación o modificación sin previo aviso. Nos reservamos el derecho de tomar decisiones finales en caso de disputas, fraudes o violaciones de las reglas.
                </p>
                <p>
                    (c) Usted reconoce y acepta que los premios ofrecidos en las rifas y sorteos pueden estar sujetos a impuestos y responsabilidades adicionales según las leyes fiscales de su jurisdicción. Es su responsabilidad cumplir con todas las obligaciones fiscales aplicables.
                </p>
                <p style={{ fontWeight: "bold" }}>
                    3. Responsabilidad.
                </p>
                <p>
                    (a) Usted utiliza el sitio web bajo su propio riesgo. No garantizamos la disponibilidad ininterrumpida, segura o libre de errores del sitio web, ni la exactitud, integridad o confiabilidad de la información obtenida a través del mismo.
                </p>

                <p>
                    (b) No seremos responsables por daños directos, indirectos, incidentales, especiales, consecuentes o punitivos derivados del uso o la imposibilidad de uso del sitio web, incluyendo, entre otros, pérdida de beneficios, datos, reputación o cualquier otra pérdida intangible.
                </p>

                <p>
                    (c) Usted acepta indemnizarnos y eximirnos de responsabilidad frente a cualquier reclamación, demanda, daño o pérdida, incluyendo honorarios razonables de abogados, que surjan de su uso del sitio web o su incumplimiento de estos términos y condiciones.
                </p>
                <p style={{ fontWeight: "bold" }}>


                    4. Propiedad intelectual.
                </p>
                <p>
                    (a) Todos los derechos de propiedad intelectual relacionados con el sitio web, incluyendo, entre otros, derechos de autor, marcas comerciales y patentes, son propiedad nuestra o de nuestros licenciantes.)
                </p>
                <p>
                    (b) Usted se compromete a no copiar, reproducir, modificar, distribuir, transmitir, mostrar, vender, licenciar ni explotar de ninguna manera los contenidos del sitio web sin nuestro consentimiento previo por escrito.)
                </p>
                <p style={{ fontWeight: "bold" }}>

                    5. Modificaciones y terminación.
                </p>
                <p>
                    Nos reservamos el derecho de modificar, suspender o terminar el sitio web, así como estos términos y condiciones, en cualquier momento y sin previo aviso. Usted acepta que no seremos responsables frente a usted ni ante ningún tercero por cualquier modificación, suspensión o terminación del sitio web.
                </p>
                <p style={{ fontWeight: "bold" }}>

                    6. Ley aplicable y jurisdicción.
                </p>
                <p>

                    Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes del Estado de Jalisco México. Cualquier disputa que surja de estos términos y condiciones estará sujeta a la jurisdicción exclusiva de los tribunales del Estado de Jalisco México.

                </p>
                <p>

                    Si tiene alguna pregunta sobre estos Términos y Condiciones de Uso, puede ponerse en contacto con nosotros a través de los medios proporcionados en nuestro sitio web.
                </p>
            </div>
        </div>
    )
};

export default TerminosCondiciones;
