import React from "react";
import arrowBack from "../../assets/arrow-back.png";
import { useNavigate } from "react-router-dom";
import "./NavigateBack.css";

const NavigateBack = ({txt}) => {

  const navigate = useNavigate();

  return (
    <div className="row-navigate-back" onClick={() => navigate(-1)}>
      <img src={arrowBack} alt="arrow back" className="img-arrow-back"/>
      <span className="txt-arrow-back">{txt}</span>
    </div>
  )
};

export default NavigateBack;
