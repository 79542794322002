import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import { getEvent } from "../../functions/event";
import Loading from "../../components/Loading";
import dayjs from 'dayjs';
import { Carousel } from 'react-responsive-carousel';
import { Table, Input, Switch, message, InputNumber, Modal, Spin, Button } from "antd";
import { collection, getDocs, updateDoc, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from "react-router-dom";
import ReturnBtn from "../../components/ReturnBtn";
import { SearchOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, FileExcelOutlined } from "@ant-design/icons";
import { statusTickets } from "../../utils/contants";
import { copyText } from "../../utils/general";
import shareIcon from "../../assets/compartir.png";
import Sheet from 'react-modal-sheet';
import { getUser } from "../../functions/user";
import "./EventDetailsAdmin.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const EventDetailsAdmin = () => {
  let { eventId } = useParams();
  const navigate = useNavigate();

  const [event, setEvent] = useState(null);
  const [purchasedTickets, setPurchasedTickets] = useState(null);
  const [showButtonLiberate, setShowButtonLiberate] = useState(false);
  const [showGenerateWinner, setShowGenerateWinner] = useState(false);
  const [showModalTickets, setShowModalTickets] = useState(false);
  const [ticketsTotal, setTicketsTotal] = useState([]);
  const [showModalWinner, setShowModalWinner] = useState(false);

  const [ticketWinner, setTicketWinner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userWinner, setUserWinner] = useState({});
  const [showButton, setShowButton] = useState(true);

  const getData = async () => {
    const eventRef = collection(db, `event/${eventId}/enrolled`);
    const querySnapshot = await getDocs(eventRef);
    let newDocs = [];

    const currentDate = new Date();
    let showGenWinner = false;
    let showBtnLiberate = false;
    let arePendingTickets = false;
    let currentPaidTickets = 0;    

    querySnapshot.forEach((doc) => {
      const ticket = doc.data();
      let expiredTicket = false;
      const buyDate = ticket.buy_date.toDate();
      const buyDateConverted = dayjs(buyDate);
      const buyDateWithDaysAdded = buyDateConverted.add(2, 'day');

      if (ticket.payment_status === "paid") {
        currentPaidTickets++;
      } else {
        arePendingTickets = true;
        showGenWinner = false;
        if (currentDate > buyDateWithDaysAdded && ticket.payment_status !== "paid") {
          expiredTicket = true;
          showBtnLiberate = true;
        }
      }

      newDocs.push({
        ...doc.data(),
        id: doc.id,
        payment_status: expiredTicket ? "expired" : ticket.payment_status,
      });
    });

    let usersIds = [];

    for(let i = 0; i < newDocs.length; i++) {
      if(!usersIds.includes(newDocs[i].user_id)){
        usersIds.push(newDocs[i].user_id)
      }
    }

    let users = [];

    for(let i = 0; i < usersIds.length; i++) {
      const response = await getUser(usersIds[i]);
      const user = {...response.data(), id: usersIds[i]};
      users.push(user);
    }

    for(let i = 0; i < newDocs.length; i++) {
      const currentUserData = users.find(u => u.id === newDocs[i].user_id);
      newDocs[i].email = currentUserData.email;
      newDocs[i].fullName =  currentUserData.fullName;
      newDocs[i].phoneNumber = currentUserData.phoneNumber;
    }

    setPurchasedTickets(newDocs);
    setShowButtonLiberate(showBtnLiberate);

    let data = await getEvent(eventId);
    if (!showBtnLiberate && currentPaidTickets >= data.minimumSellTicket && !arePendingTickets) {
      showGenWinner = true;
    }

    setShowGenerateWinner(showGenWinner);

    if (!data.nationalLotteryEven) {
      const fireBaseEndDate = new Date(
        data.endDate.seconds * 1000 +
        data.endDate.nanoseconds / 1000000
      );
      const fireBaseStartDate = new Date(
        data.startDate.seconds * 1000 +
        data.startDate.nanoseconds / 1000000
      );
      const eventsDates = {
        day: dayjs(fireBaseEndDate).format("dddd D"),
        month: dayjs(fireBaseEndDate).format("MMMM"),
        year: dayjs(fireBaseEndDate).format("YYYY"),
        time: dayjs(fireBaseEndDate).format("h:mm a"),
        startDateFormated: data.startDate.toDate().toLocaleDateString("es-MX"),
        endDateFormated: data.endDate.toDate().toLocaleDateString("es-MX"),
        fireBaseStartDate
      };
      Object.assign(data, eventsDates);
    }

    setTicketsTotal(data.availableTickets);

    setEvent({
      ...data,
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTicketStatus = async (checked, record) => {
    const payment_status = checked ? "paid" : "process";
    try {
      await updateDoc(doc(db, `event/${event.id}/enrolled`, record.id), {
        payment_status,
      });

      await updateDoc(doc(db, `user/${record.user_id}/events/${event.id}/tickets`, record.id), {
        payment_status,
      });

      let expiredTicket = false;
      const currentDate = new Date();
      const buyDate = record.buy_date.toDate();
      const buyDateConverted = dayjs(buyDate);
      const buyDateWithDaysAdded = buyDateConverted.add(2, 'day');

      if (currentDate > buyDateWithDaysAdded && payment_status !== "paid") {
        expiredTicket = true;
      }

      const status = expiredTicket ? "expired" : payment_status;
      const ticket = { ...record, payment_status: status };

      const index = purchasedTickets.findIndex(element => element.id === record.id);
      let newPurchasedTickets = [...purchasedTickets];
      newPurchasedTickets[index] = ticket;
      setPurchasedTickets(newPurchasedTickets);
      
      let showGenWinner = false;
      let showBtnLiberate = false;
      let arePendingTickets = false;
      let currentPaidTickets = 0;
      for (let i = 0; i <= newPurchasedTickets.length; i++) {
        if (newPurchasedTickets[i]?.payment_status === "expired") {
          showBtnLiberate = true;
          showGenWinner = false;
        } else if (newPurchasedTickets[i]?.payment_status === "process") {
          showBtnLiberate = false;
          showGenWinner = false;
          arePendingTickets = true;
        }

        if (newPurchasedTickets[i]?.payment_status === "paid") {
          currentPaidTickets++;
        }
      }

      if (!showBtnLiberate && currentPaidTickets >= event.minimumSellTicket && !arePendingTickets) {
        showGenWinner = true;
      }

      setShowButtonLiberate(showBtnLiberate);
      setShowGenerateWinner(showGenWinner);

      if(payment_status === "paid") {
        const bodyData = {
          email: record.email,
          title: event.title,
          ticket: record.ticket,
          date: dayjs(currentDate).format("DD/MM/YYYY"),
          time: dayjs(currentDate).format("HH:mm")
        };
        
        fetch(`${process.env.REACT_APP_API_URL}/send-email-confirm-purchase`, {
          method: "POST",
          body: JSON.stringify(bodyData),
          headers:{
            'Content-Type': 'application/json'
          }
        });
      }

      message.success("Status actualizado", 3);
    } catch (error) {
      message.error(error.message, 3);
    }
  };

  const liberateTickets = async () => {
    let availableTickets = event.availableTickets;
    let newPurchasedTickets = event.purchasedTickets;
    for (let i = 0; i < purchasedTickets.length; i++) {
      if (purchasedTickets[i].payment_status === "expired") {
        availableTickets.push(purchasedTickets[i].ticket);
        newPurchasedTickets = newPurchasedTickets.filter(ticket => ticket !== purchasedTickets[i].ticket);
        // Delete ticket from enrolled
        await deleteDoc(doc(db, `event/${event.id}/enrolled`, purchasedTickets[i].id));
        // Update status in user tickets
        await updateDoc(doc(db, `user/${purchasedTickets[i].user_id}/events/${event.id}/tickets`, purchasedTickets[i].id), {
          payment_status: "deleted",
        });
      }
    }
    availableTickets.sort();
    await updateDoc(doc(db, `event/${event.id}`), {
      availableTickets,
      purchasedTickets: newPurchasedTickets
    });
    getData();
  };

  const liberateTicket = async (values) => {
    let availableTickets = event.availableTickets;
    let newPurchasedTickets = event.purchasedTickets;
    availableTickets.push(values.ticket);
    newPurchasedTickets = newPurchasedTickets.filter(ticket => ticket !== values.ticket);

    // Delete ticket from enrolled
    await deleteDoc(doc(db, `event/${event.id}/enrolled`, values.id));
    // Update status in user tickets
    await updateDoc(doc(db, `user/${values.user_id}/events/${event.id}/tickets`, values.id), {
       payment_status: "deleted",
    });
    
    availableTickets.sort();
    await updateDoc(doc(db, `event/${event.id}`), {
      availableTickets,
      purchasedTickets: newPurchasedTickets
    });
    getData();
  };

  const handleOnExport = () => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(purchasedTickets);

    utils.book_append_sheet(wb, ws, "usuarios");

    writeFile(wb, "usuarios.xlsx");
  };

  const columns = [
    {
      title: 'Boleto',
      dataIndex: 'ticket',
      key: 'ticket',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div>
            <Input
              placeholder=""
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <button
              onClick={() => {
                confirm();
              }}
              className="btnSearch"
            >
              Buscar
            </button>
            <button
              onClick={() => {
                clearFilters();
                confirm({ closeDropdown: true });
              }}
              className="btnClear"
            >
              Limpiar
            </button>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.ticket.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      responsive: ["md"],
    },
    {
      title: 'Nombre',
      dataIndex: 'fullName',
      key: 'fullName',
      responsive: ["md"],
    },
    {
      title: 'Teléfono',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      responsive: ["lg"],
    },
    {
      title: 'Status del pago',
      dataIndex: 'payment_status',
      key: 'payment_status',
      filters: [
        {
          text: "Pagado",
          value: "paid",
        },
        {
          text: "Proceso",
          value: "process",
        },
        {
          text: "Vencido",
          value: "expired",
        },
      ],
      onFilter: (value, record) => record.payment_status.indexOf(value) === 0,
      render: (value, record, index) => {
        const status = statusTickets[value];
        return (
          <p style={{ color: status.color }}>
            {status.title}
          </p>
        );
      },
    },
    {
      title: "Fecha de compra",
      key: "buy_date",
      dataIndex: "buy_date",
      responsive: ["lg"],
      render: (value, record, index) => {
        let buy_date = record.buy_date;
        let time;
        const fireBaseTime = new Date(
          buy_date.seconds * 1000 + buy_date.nanoseconds / 1000000
        );
        const date = fireBaseTime.toLocaleDateString("es-MX");
        const atTime = fireBaseTime.toLocaleTimeString("es-MX");
        time = atTime.slice(0, 5);
        return (
          <>
            {date} a las {time}
          </>
        );
      },
    },
    {
      title: 'Cambiar status del pago',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (value, record) => {
        const status = value === "paid" ? true : false;
        return (
          <div className="center-options-div">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={status}
              onChange={() => handleTicketStatus(!status, record)}
            />
          </div>          
        );
      }
    },
    {
      title: "Cancelar boleto",
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (value, record) => {
        if(value === "paid") return;
        return (
          <div className="center-options-div">
            <Button
              onClick={() => {
                liberateTicket(record);
              }}
              type="link"
              danger
              icon={<DeleteOutlined />}
            ></Button>
          </div>
        )
      }
    },
  ];

  const columnsWithoutActions = [
    {
      title: 'Boleto',
      dataIndex: 'ticket',
      key: 'ticket',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div>
            <Input
              placeholder=""
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <button
              onClick={() => {
                confirm();
              }}
              className="btnSearch"
            >
              Buscar
            </button>
            <button
              onClick={() => {
                clearFilters();
                confirm({ closeDropdown: true });
              }}
              className="btnClear"
            >
              Limpiar
            </button>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.ticket.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Nombre',
      dataIndex: 'fullName',
      key: 'fullName',
      responsive: ["md"],
    },
    {
      title: 'Teléfono',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      responsive: ["md"],
    },
    {
      title: 'Status del pago',
      dataIndex: 'payment_status',
      key: 'payment_status',
      responsive: ["lg"],
      render: (value, record, index) => {
        const status = statusTickets[value];
        return (
          <p style={{ color: status.color }}>
            {status.title}
          </p>
        );
      },
    },
    {
      title: "Fecha de compra",
      key: "buy_date",
      dataIndex: "buy_date",
      responsive: ["lg"],
      render: (value, record, index) => {
        let buy_date = record.buy_date;
        let time;
        const fireBaseTime = new Date(
          buy_date.seconds * 1000 + buy_date.nanoseconds / 1000000
        );
        const date = fireBaseTime.toLocaleDateString("es-MX");
        const atTime = fireBaseTime.toLocaleTimeString("es-MX");
        time = atTime.slice(0, 5);
        return (
          <>
            {date} a las {time}
          </>
        );
      },
    },
  ];

  const handleCancel = () => {
    setShowModalTickets(false);
  };

  const searchTicket = (value) => {
    if (value != null) {
      const filter = event.availableTickets.filter((str) => str.indexOf(value) !== -1);
      setTicketsTotal(filter);
    } else {
      setTicketsTotal(event.availableTickets);
    }
  };

  const generateRandomWinner = async () => {
    setTicketWinner(null);
    setLoading(true);
    setShowButton(false);
    setShowGenerateWinner(false);
    setTimeout(async () => {
      const ticket = event.purchasedTickets[Math.floor(Math.random() * event.purchasedTickets.length)];
      const docRef = doc(db, `event/${event.id}/enrolled`, ticket);
      try {
        const docSnap = await getDoc(docRef);
        const ticketDetails = { ...docSnap.data(), id: docSnap.id };
        const userRef = doc(db, "user", ticketDetails.user_id);
        const userSnap = await getDoc(userRef);
        const userDetails = { ...userSnap.data(), id: userSnap.id };
        const data = {
          ticketWinner: ticket,
          nameWinner: userDetails.fullName || null,
          idUserWinner: userDetails.id,
          status: "closed",
          promotionEvent: false
        };
        await updateDoc(doc(db, `event/${event.id}`), data);
        const bodyData = {
          email: userDetails.email,
          title: event.title,
          ticket: ticket,
          name: userDetails.fullName || null
        }

        fetch(`${process.env.REACT_APP_API_URL}/send-email-winner`, {
          method: "POST",
          body: JSON.stringify(bodyData),
          headers:{
            'Content-Type': 'application/json'
          }
        });

        setUserWinner(userDetails);
        setTicketWinner(ticket);
        setEvent({...event, ...data});   
        setLoading(false);
      } catch (error) {
        message.error(error.message);
        setLoading(false);
      }
    }, 2000);
  };

  if (!event) {
    return (
      <Loading />
    )
  }

  return (
    <div className='container-body-pending'>
      <ReturnBtn fn={() => navigate(`/admin-events`)} />
      <div className='container-row-pending'>
        <div className="row-container-title-mobile">
          <h1 className='title-pending-mobile'>{event.title}</h1>
          {event.status !== "closed" && (
            <Link
              className="btn-edit-event"
              to={`/admin-events/${eventId}/edit`}
              state={event}
            >
              Editar
            </Link>
          )}          
        </div>

        {/* Carrousel */}
        <div style={{ display: "flex", flex: 1, alignItems: "center", flexDirection: "column" }}>
          <div className='size-carrousel-pending'>
            <div className='container-pending-carrousel'>
              <Carousel
                showStatus={false}
              >
                {event.articleUrls.map((item, index) => {
                  return (
                    <div key={index}>
                      <img src={item} alt={index} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
        {/* Description */}
        <div style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "flex-start" }}>
          <div className='container-desc-pending'>
            <div className="row-container-title-desktop">
              <h1 className='title-pending-desktop'>{event.title}</h1>
              {event.status !== "closed" && (
                <Link
                  className="btn-edit-event"
                  to={`/admin-events/${eventId}/edit`}
                  state={event}
                >
                  Editar
                </Link>
              )} 
            </div>
            <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "baseline" }}>
              <p className='event-pending-price'>{`$${event.ticketPrice}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
              <img alt='icon share' src={shareIcon} className='icon-pending-share' onClick={() => copyText("https://hayrifa.mx/events/" + event.id)} />
            </div>
            <p className='txt-ticktets-pending'>{event.totalTickets} boletos, <b>quedan {event.availableTickets.length}</b></p>
            <p className='txt-ticktets-pending'>Sorteo el <b>{event.day} de {event.month} {event.year}</b></p>
            <p className='txt-ticktets-pending'>Mínimo de boletos vendidos para rifar el ganador: <b>{event.minimumSellTicket}</b></p>
            <p className='txt-ticktets-pending'>Termina con la loteria nacional: <b>{event.nationalLotteryEven ? 'Si' : 'No'}</b></p>
            <p className='txt-ticktets-pending'>Creado por: <b>{event.nameOwner}</b></p>
            <p className='txt-ticktets-pending'>{event.description}</p>
            {event.status === "closed" && (<p className='txt-ticktets-pending'>Ticket ganador: <b>{event.ticketWinner}</b></p>)}

            {event.status !== "closed" && (
              <div className='rowButtons'>
                <div className='colButtons'>
                  {showGenerateWinner && (
                    <button
                      className='btnManual'
                      onClick={() =>
                        setShowModalWinner(true)
                      }
                    >
                      Generar ganador
                    </button>
                  )}
                  <button
                    className='btnManual'
                    onClick={() => {
                      setTicketsTotal(event.availableTickets);
                      setShowModalTickets(true);
                    }}
                  >
                    Boletos disponibles
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      <div className="container-event-admin-actions">
        <div style={{flex: 2}}>
          <span className='subtitle-event-admin'>Boletos Comprados</span>
        </div>
        <div className="container-event-admin-actions-btns">
          <button 
            className="btn-export-xlsx" 
            style={{marginRight: "5px"}} 
            onClick={handleOnExport}>
            Exportar a XLSX <FileExcelOutlined />
          </button>
          {(event.status !== "closed" && showButtonLiberate) && (
            <button className="btn-liberate-tickets" onClick={liberateTickets}>
              Liberar boletos vencidos
            </button>
          )}
        </div>        
      </div>

      <div>
        {event.status !== "closed"
          ? <Table dataSource={purchasedTickets} columns={columns} rowKey="id"/>
          : <Table dataSource={purchasedTickets} columns={columnsWithoutActions} rowKey="id"/>
        }
      </div>

      <Sheet isOpen={showModalTickets} onClose={() => setShowModalTickets(false)}>
        <Sheet.Container
          style={{ borderRadius: "30px 30px 0 0" }}>
          <Sheet.Header />
          <div style={{ fontSize: "19px", paddingLeft: "2em", paddingRight: "2em" }}>
            <p>Boletos</p>
          </div>
          <Sheet.Content>
            <div style={{ paddingLeft: "2em", paddingRight: "2em" }}>
              <div style={{ paddingBottom: "2em" }}>
                <InputNumber
                  style={{ paddingBottom: "2em" }}
                  className='inputRegister'
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={searchTicket}
                  placeholder='Buscador' />
              </div>
              <div style={{ paddingBottom: "2em", textAlign: "center" }}>
                <div className='containerFooter'>
                  <div className='containerNumbers'>
                    {ticketsTotal.map((item, index) => {
                      return (
                        <button
                          className={'btn-ticket-available-ed'}
                          key={`btn-${index}`}
                          disabled={true}
                        >
                          {item}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className='manual-btn'>
                <button key="back" className='btnCancel2' onClick={handleCancel}>
                  Cerrar
                </button>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>

      <Modal
        open={showModalWinner}
        closable={true}
        onCancel={() => setShowModalWinner(false)}
        footer={null}
      >
        <div className='container-random-winner'>
          <span className="txt-modal-winner">{event.title}</span>
          {showButton && (
            <button 
              className='btnChooseThis' 
              onClick={generateRandomWinner}>
              Rifar 
            </button>
          )}          
          <Spin
            spinning={loading}
            tip="Generando ganador..."
            style={{ paddingTop: "2rem" }}
          >
          </Spin>
          {ticketWinner && (
            <div className="container-winner">
              <p className="txt-modal-winner">El ticket <span className="ticket-winner-modal">{ticketWinner}</span> ha ganado</p>
              <p className="txt-modal-winner">¡Felicidades{userWinner?.fullName !== null ? ` ${userWinner.fullName}` : null}!</p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
};

export default EventDetailsAdmin;
