import React, { useState, useEffect } from "react";
import CardEventItem from "../landingPage/components/CardEventItem";
import "../landingPage/LandingPage.css";
import { query, collection, getDocs, where } from "firebase/firestore";
import { db } from "../../firebase";

const CurrentEvents = () => {
    const [events, setEvents] = useState([]);
    useEffect(() => {
        const getData = async () => {
            const queryEvents = query(
                collection(db, "event"), where("status", "==", "open")
            );
            const querySnapshot = await getDocs(queryEvents);
            let newDocs = [];
            querySnapshot.forEach((doc) => {
                newDocs.push({ ...doc.data(), id: doc.id });
            });
            setEvents(newDocs);

        };
        getData();
    }, []);

    return (
        <div className="container-all-events">
            <div style={{background:"#f1f3f6"}}>
            <div className="scrolling-wrapper-vertical">
                {events.map((item, index) => {
                    return (
                        <div key={index}><CardEventItem item={item} /></div>
                    )
                })}
            </div>
            </div>
        </div>
    )
};

export default CurrentEvents;
