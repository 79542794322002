import styled from 'styled-components';

export const Box = styled.div`
	padding: 20px 60px;
background: linear-gradient(75.69deg, #9343F8 0.52%, #6843F8 89.84%);
position: absolute;
bottom: 1;
width: 100%;


@media screen and (max-width: 780px) {
	padding: 70px 0px;
	margin-top: 3em;
}
@media screen and (min-width: 781px) {
	padding: 42px 0px;
}
`;
export const BoxT = styled.div`
padding: 20px 60px;
background: #6F44F9;
position: absolute;
bottom: 1;
display:block;
width: 100%;


@media screen and (min-width: 781px) and (max-width: 3000px) {
	display: flex;
	padding: 51px 0px;
}

@media (max-width: 780px) {
	display: block;
	padding: 51px 0px;
}
`;

export const footerLinkFlex = styled.div`
padding-left: 5em;
padding-right: 5em;
align-items: center;
padding-bottom: 2em;
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 3000px;
	margin: 0 auto;
	padding-top: 1em;
	/* background: red; */
`



export const Column = styled.div`
display: flex;
flex-direction: column;
text-align: center;
@media (min-width: 780px) {
	text-align: left;
	display: flex;
}
`;

export const ColumnFlex = styled.div`
display: flex;
flex-direction: column-reverse;
text-align: center;
@media (min-width: 780px) {
	flex-direction: row-reverse;
	text-align: center;
	display: flex;
}
`;

export const ColumnFlexLogo = styled.div`
display: flex;
flex-direction: column;
text-align: center;
@media (min-width: 780px) {
	flex-direction: row-reverse;
	text-align: center;
	display: flex;
}
`;

export const flexDirection = styled.div`
padding-left: 5em;
padding-right: 5em;
align-items: center;
padding-bottom: 2em;
`;

export const Row = styled.div`
@media (max-width: 780px) {
	display: block;
    grid-gap: 20px;
	grid-template-columns: repeat(auto-fill,
						minmax(200px, 1fr));
}

@media screen and (min-width: 781px) and (max-width: 3000px) {
	display: flex;
    grid-gap: 20px;
	grid-template-columns: repeat(auto-fill,
						minmax(200px, 1fr));
}
`;

export const FooterLink = styled.a`

font-weight: 400;
font-size: 19px;
line-height: 19px;
color: #FFFFFF;

&:hover {
	color: #FEBE0B;
	transition: 200ms ease-in;
}
`;

export const Heading = styled.p`

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 27px;
color: #FFFFFF;
`;

export const HeadingContact = styled.p`

font-weight: 700;
font-size: 26px;
line-height: 27px;
color: #FFFFFF;
`;

export const Redes = styled.div`
paddingLeft: 13px;
paddingRight: 15px
`