import React from "react";
import { useNavigate } from 'react-router-dom';
import "./CardWinnerItem.css";
import "../LandingPage.css";

const CardWinnerItem = ({ item, index }) => {
  const navigate = useNavigate();

  return (
    <div>
    <div
      className="card-winners-item"
      onClick={() =>
        navigate(`/events-winners/${item.id}`, {
          state: {
            ...item,
          },
        })
      }
    >
      <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
        {/* Winner */}
        <div style={{ display: "flex", flexDirection: "column", flex: 1, }}>
          <div className="card-tag-name">
            <span className="txt-winner-card">Ganador</span>
            <span className="txt-winner-name-card">{item?.nameWinner}</span>
          </div>

          <div className="seccion-winner-ticket">
            <span className="txt-ticket-title">Boleto</span>
            <p className="txt-ticket-winner">{item.ticketWinner}</p>
          </div>
        </div>
        {/* Product */}
        <div style={{ display: "flex", flexDirection: "column", flex: 1, alignItems: "center" }}>
          <img src={item.urlIcon} className="img-card-winner-item" alt={index} />
          <p className="txt-title-winner-card">{item.title}</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CardWinnerItem;
