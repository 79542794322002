import React, { useState } from 'react';
import { Modal, message, Spin, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import { db } from "../../firebase";
import {
  doc,
  setDoc,
  getDoc,
  Timestamp,
  updateDoc,
  getDocs,
  query,
  collection,
  where
} from "firebase/firestore";
import { useAuth } from "../../context/context";
import { copyText } from '../../utils/general';
import infoIcon from "../../assets/info-icon.png";
import dayjs from 'dayjs';
import { paymentMethods } from '../../utils/contants';
import './SelectPaymentType.css';

const SelectPaymentType = () => {
  const [loading, setLoading] = useState(false);
  const [stringTickets, setStringTickets] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [showEditName, setShowEditName] = useState(false);
  const [showEditPhone, setShowEditPhone] = useState(false);
  const { user, cart, userInfo, getUserInfo } = useAuth();
  const navigate = useNavigate();
  var purchasedTickets;

  const validateUser = () => {
    let validEmail = userInfo?.email === null ? false : true;
    let validName = userInfo?.fullName === null ? false : true;
    let validPhone = userInfo?.phoneNumber === null ? false : true;
    if (!validEmail || !validName || !validPhone) {
      setShowEditEmail(!validEmail);
      setShowEditName(!validName);
      setShowEditPhone(!validPhone);
      setShowModalEdit(true)
      return;
    }
    handleSubmit(userInfo.fullName, userInfo.email, userInfo?.phoneNumber);
  };

  const editUser = async (values) => {
    const fullName = showEditName ? values.fullName : userInfo.fullName;
    const email = showEditEmail ? values.email : userInfo.email;
    const phoneNumber = showEditPhone ? `+52${values.phoneNumber.replace(/\s/g, '')}` : userInfo.phoneNumber;

    try {

      if (showEditPhone) {
        const queryCellphone = query(
          collection(db, "user"),
          where("phoneNumber", "==", phoneNumber)
        );
        const querySnapshot = await getDocs(queryCellphone);
        if (!querySnapshot.empty) {
          message.error("El número de celular ya esta en uso", 3);
          return;
        }
      }

      if (showEditEmail) {
        const queryEmail = query(
          collection(db, "user"),
          where("email", "==", email)
        );
        const querySnapshotEmail = await getDocs(queryEmail);
        if (!querySnapshotEmail.empty) {
          message.error("El email ya esta en uso", 3);
          return;
        }
      }

      const newData = {
        fullName,
        email,
        phoneNumber
      };
      await updateDoc(doc(db, "user", user.uid), newData);
      getUserInfo(user.uid);
      setShowModalEdit();
      handleSubmit(fullName, email, phoneNumber);
    } catch (error) {
      message.error(error, 5);
    }
  };

  const handleSubmit = async (fullName, email, phoneNumber) => {
    setLoading(true);

    const currentDate = new Date();
    const buyDate = Timestamp.fromDate(currentDate);    

    try {
      const selectedTickets = cart.selectedTickets;
      const eventRef = doc(db, "event", cart.id);
      const docSnap = await getDoc(eventRef);
      const data = docSnap.data();
      let availableTickets = data.availableTickets;
      purchasedTickets = data.purchasedTickets;

      for (let i = 0; i < selectedTickets.length; i++) {
        const ticketExists = data.availableTickets.includes(selectedTickets[i]);
        if (!ticketExists) {
          message.error(`El ticket ${selectedTickets[i]} ya no esta disponible`)
          setLoading(false);
          return;
        }
        availableTickets = availableTickets.filter((item) => item !== selectedTickets[i])
        purchasedTickets.push(selectedTickets[i]);
      }

      const userRegister = {
        event_id: cart.id,
      };

      const userRef = doc(db, `user/${user.uid}/events`, cart.id);

      await setDoc(userRef, userRegister);

      for (let i = 0; i < cart.selectedTickets.length; i++) {
        const newRegister = {
          buy_date: buyDate,
          user_id: user.uid,
          ticket: cart.selectedTickets[i],
          payment_status: "process",
          fullName,
          email,
          phoneNumber
        };

        const enrolledRef = doc(db, `event/${cart.id}/enrolled`, cart.selectedTickets[i]);
        const userRef = doc(db, `user/${user.uid}/events/${cart.id}/tickets`, cart.selectedTickets[i]);

        await setDoc(enrolledRef, newRegister);
        await setDoc(userRef, newRegister);
      }

      const newDataEvent = {
        availableTickets,
        purchasedTickets
      }

      await updateDoc(eventRef, newDataEvent);

      const bodyData = {
        email: userInfo.email,
        title: cart.title,
        tickets: cart.selectedTickets,
        date: dayjs(currentDate).format("DD/MM/YYYY"),
        time: dayjs(currentDate).format("HH:mm")
      };

      fetch(`${process.env.REACT_APP_API_URL}/send-email-purchase`, {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers:{
          'Content-Type': 'application/json'
        }
      });

      setLoading(false);
      setModalVisible(true);
      setStringTickets("https://api.whatsapp.com/send?phone=+523314683131&text=Hola, aparté los siguientes boletos: " + selectedTickets.toString() + ". Me gustaría saber, cómo puedo pagarlos?")
    } catch (error) {
      message.error(error.message, 4);
    }
  };

  return (
    <div className='container-payment'>
      <Spin size="large" spinning={loading}>
        <div className='container-checkout'>
          <div className='container-checkout-details'>
            <h1 className='title-checkout'>Resumen de tu compra</h1>
            <div className="container-checkout-body">
              <div style={{ borderBottom: "solid 1px #ededed", paddingTop: ".4em" }}>
                <div className="checkout-body-left">
                  <span>Evento</span>
                </div>
                <div className="checkout-body-right" style={{ width: "100%", justifyContent: "right" }}>
                  <span>{cart.title}</span>
                </div>
              </div>
              <div style={{ borderBottom: "solid 1px #ededed", paddingTop: ".4em" }}>
                <div className="checkout-body-left">
                  <span>Tickets </span><span style={{ fontWeight: "bolder" }}> ({cart.tickets}) </span>
                </div>
                <div className="checkout-body-right" style={{ width: "100%", justifyContent: "right" }}>
                  <span>
                    {cart.selectedTickets.map((item, index) => {
                      return (index ? ', ' : '') + item;
                    })}
                  </span>
                </div>
              </div>
              <div>
                <div className="checkout-body-left-total">
                  <span >Total:</span>
                </div>
                <div className="checkout-body-right-total" style={{ width: "100%", justifyContent: "right" }}>
                  <span>
                    {`$ ${cart.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </div>
              </div>
            </div>
            <div className='container-info-desktop'>
              <div className='info-desktop'>
                <div className='container-info-pending-tickets'>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <img src={infoIcon} alt="info icon" style={{ height: "18px", width: "18px" }} />
                    <span className='txt-title-info-pending'>Importante</span>
                  </div>
                  <p className='txt-description-info-pending'>
                    Después de aceptar la compra tienes <b>48 horas</b> para realizar el pago y enviar en el comprobante por medio de WhatsApp
                  </p>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: "1.5rem" }}>
              <button className='btnSelectPayment' onClick={validateUser}>
                Apartar
              </button>
            </div>
            <Modal
              open={modalVisible}
              closable={false}
              footer={null}
            >
              <p className='title-modal-payment'>¡Apartaste tu boleto exitosamente!</p>
              <p className='span-option-payment'>
                Realiza el pago de tu boleto vía SPEI o en los comercios participantes,
                recuerda que puedes consultar tu boleto y formas de pago desde el menú
                de esta página en la opción "Mis tickets."
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{ paddingLeft: "4px" }}
                  href={stringTickets}
                >
                </a>
              </p>
              <p className='span-option-payment'>
                Si tienes alguna duda puedes contactarnos por WhatsApp presionando
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{ paddingLeft: "4px" }}
                  href={stringTickets}
                >AQUÍ
                </a>, o en el chat de ayuda de ésta página.
              </p>
              <div className='container-buttons-footer-modal'>
                <div className='item'>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ paddingLeft: "4px" }}
                    href={stringTickets}
                  >
                    <button
                      key="submit"
                      className='btnSelectPaymentModal'
                      onClick={() => { navigate("/landing") }}
                    >
                      Envía un WhatsApp
                    </button>
                  </a>
                </div>
                <div className='item'>
                  <button
                    key="submit"
                    className='btnSelectPaymentModal'
                    onClick={() => { navigate("/landing") }}
                  >
                    Continuar
                  </button>
                </div>
              </div>
            </Modal>

            {/* Modal complete user information */}
            <Modal
              open={showModalEdit}
              onCancel={(e) => setShowModalEdit(false)}
              onOk={editUser}
              footer={null}
            >
              <h1>Completa tu información</h1>
              <Form
                layout="vertical"
                initialValues={{
                  fullName: userInfo?.fullName,
                  email: userInfo?.email,
                }}
                onFinish={editUser}
                requiredMark={false}
              >
                {showEditName && (
                  <Form.Item
                    label="Nombre"
                    name="fullName"
                    rules={[
                      {
                        required: true,
                        message: "Ingresa tu nombre",
                      },
                    ]}
                  >
                    <Input className="inputRegister" />
                  </Form.Item>
                )}

                {showEditEmail && (
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese su email",
                      },
                    ]}
                    normalize={(value) => (value || "").toLowerCase()}
                  >
                    <Input className="inputRegister" id="emailInput" />
                  </Form.Item>
                )}

                {showEditPhone && (
                  <Form.Item
                    label="Número télefonico"
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese su número de télefono",
                      },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject();
                          }
                          if (isNaN(value)) {
                            return Promise.reject("Solo debe contener números");
                          }
                          if (value.toString().length !== 10) {
                            return Promise.reject(
                              "El número debe ser de 10 dígitos"
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      className="inputRegister"
                      prefix="+52"
                    />
                  </Form.Item>
                )}

                <Form.Item>
                  <button
                    className="btnEditAccount"
                    htmlType="submit"
                  >
                    Guardar
                  </button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
          <div className='container-checkout-details-payment'>
            <h2 className='title-options-payment'>Opciones de pago</h2>
            <div>
              <div className='title-payment-option'>
                <p>Transferencia electrónica (SPEI)</p>
              </div>
              <div className='container-payment-option'>
                <div style={{ borderBottom: "solid 1px #ededed" }}>
                  <div className="checkout-body-left">
                    <span>CLABE</span>
                  </div>
                  <div className="checkout-body-right">
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%" }}>
                        <span>{paymentMethods.spei}</span>
                      </div>
                      <div>
                        <p
                          className='copy-item-checkout'
                          onClick={() => copyText(paymentMethods.spei)}
                        >
                          <CopyOutlined />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ borderBottom: "solid 1px #ededed", paddingTop: ".4em" }}>
                  <div className="checkout-body-left">
                    <span>Beneficiario </span>
                  </div>
                  <div className="checkout-body-right">
                    <span>LEOPOLDO MORALES GONZALEZ</span>
                  </div>
                </div>
                <div style={{ paddingTop: ".4em" }}>
                  <div className="checkout-body-left">
                    <span>Banco</span>
                  </div>
                  <div className="checkout-body-right">
                    <span>BBVA</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='title-payment-option'>
                <p>Efectivo en puntos de pago</p>
              </div>
              <div className='container-payment-option'>
                <div style={{ borderBottom: "solid 1px #ededed" }}>
                  <div className="checkout-body-left">
                    <span>No. de tarjeta</span>
                  </div>
                  <div className="checkout-body-right">
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%" }}>
                        <span>{paymentMethods.oxxo}</span>
                      </div>
                      <div>
                        <p
                          className='copy-item-checkout'
                          onClick={() => copyText(paymentMethods.oxxo)}
                        >
                          <CopyOutlined />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingTop: ".4em" }}>
                  <div className="checkout-body-left">
                    <span>Comercio </span>
                  </div>
                  <div className="checkout-body-right">
                    <span>Oxxo</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-info-mobile'>
          <div className='info-mobile'>
            <div className='container-info-pending-tickets'>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <img src={infoIcon} alt="info icon" style={{ height: "18px", width: "18px" }} />
                <span className='txt-title-info-pending'>Importante</span>
              </div>
              <p className='txt-description-info-pending'>
                Después de aceptar la compra tienes <b>48 horas</b> para realizar el pago y enviar en el comprobante por medio de WhatsApp
              </p>
            </div>
          </div>
        </div>
        <div>
          <div style={{ paddingTop: "1.5rem", paddingLeft: "em" }}>
            <button className='btnSelectPaymentMobile' onClick={validateUser}>
              Apartar
            </button>
          </div>
        </div>
      </Spin>
    </div>
  )
};

export default SelectPaymentType;