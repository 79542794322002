import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { getDocs, query, collection, doc, getDoc } from "firebase/firestore";
import { Table } from "antd";
import { useAuth } from "../../context/context";
import { statusTickets } from "../../utils/contants";
import Loading from "../../components/Loading";
import "./Movements.css";

const Movements = () => {
  const { user } = useAuth();
  const [movements, setMovements] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {    
    const getData = async () => {
      setLoading(true);
      let queryMovements = query(collection(db, `user/${user.uid}/events`));
      const querySnapshot = await getDocs(queryMovements);
      let idRifas = [];
      querySnapshot.forEach((doc) => {
        idRifas.push({ ...doc.data(), id: doc.id, pendingTickets: [], activeTickets: [] });
      });

      let events = [];

      for (let i = 0; i < idRifas.length; i++) {
        const docRef = doc(db, "event", idRifas[i].id);
        const docSnap = await getDoc(docRef);
        events.push({ id: docSnap.id, ...docSnap.data() });
      }

      let tickets = [];

      for (let i = 0; i < events.length; i++) {
        const userTicketsRef = collection(db, `user/${user.uid}/events/${events[i].id}/tickets`);
        const queryTickets = query(userTicketsRef);
        const querySnapshot = await getDocs(queryTickets);

        querySnapshot.forEach((doc) => {
          tickets.push({...doc.data(), id: doc.id, title: events[i].title });
        });
      }

      const newTickets = tickets.sort((a, b) => {
        const aTime = new Date(
          a.buy_date.seconds * 1000 +
          a.buy_date.nanoseconds / 1000000
        );
        const bTime = new Date(
          b.buy_date.seconds * 1000 +
          b.buy_date.nanoseconds / 1000000
        );
        let da = new Date(aTime),
            db = new Date(bTime);
        return  db - da;
      });

      setMovements(newTickets);
      setLoading(false);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Rifa',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Ticket',
      dataIndex: 'ticket',
      key: 'ticket',
    },
    {
      title: 'Status del Pago',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (value, record, index) => {
        const status = statusTickets[value];
        return (
          <p style={{ color: status.color, marginBottom: 0 }}>
            {status.title}
          </p>
        );
      },
    },
    {
      title: "Fecha y Hora de Apartado",
      key: "buy_date",
      dataIndex: "buy_date",
      responsive: ["lg"],
      render: (value, record, index) => {
        let buy_date = record.buy_date;
        let time;
        const fireBaseTime = new Date(
          buy_date.seconds * 1000 + buy_date.nanoseconds / 1000000
        );
        const date = fireBaseTime.toLocaleDateString("es-MX");
        const atTime = fireBaseTime.toLocaleTimeString("es-MX");
        time = atTime.slice(0, 5);
        return (
          <>
            {date} {time}
          </>
        );
      },
    },
  ];

  if (loading) {
    return (
      <Loading />
    )
  };

  return (
    <div className="body-movements">
      <h1>Mis Movimientos</h1>
      <Table dataSource={movements} columns={columns} rowKey="id" pagination={{ pageSize: 10, showSizeChanger: false }}/>
    </div>
  )
};

export default Movements;
