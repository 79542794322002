import React, { useState } from "react";
import "./Register.css";
import {
  Form,
  Input,
  Button,
  message,
  Divider,
} from "antd";
import { passwordValidator } from "../../utils/validations";
import { db } from "../../firebase";
import { query, collection, where, getDocs } from "firebase/firestore";
import { useAuth } from "../../context/context";
import { createUser, getUser } from "../../functions/user";
// import facebookLogo from "../../assets/facebook.png";
import googleLogo from "../../assets/google.png";
import phone from "../../assets/phone.png";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from '../../components/Loading';

const Register = () => {
  const [load, setLoad] = useState(false)
  const { signup, loginWithGoogle, getUserInfo } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  if (load) {
    return (
      <Loading />
    )
  }

  const redirectUser = (uid) => {
    getUserInfo(uid);
    setLoad(false)
    message.success("Sesión iniciada con exito", 5);
    if (location.state?.from?.pathname) {
      navigate(location.state.from.pathname);
      return;
    } else {
      return navigate("/landing", { replace: true });
    }
  };

  const onFinish = async (values) => {
    setLoad(true)
    const phoneNumber = "+52" + values.phoneNumber.replace(/\s/g, '');
    try {
      const queryCellphone = query(
        collection(db, "user"),
        where("phoneNumber", "==", phoneNumber)
      );
      const querySnapshot = await getDocs(queryCellphone);
      if (!querySnapshot.empty) {
        setLoad(false)
        message.error("El número de celular ya esta en uso", 3);
        return;
      }
      const queryEmail = query(
        collection(db, "user"),
        where("email", "==", values.email)
      );
      const querySnapshotEmail = await getDocs(queryEmail);
      if (!querySnapshotEmail.empty) {
        setLoad(false)
        message.error("El email ya esta en uso", 3);
        return;
      }
      const userCredential = await signup(values.email, values.password);
      const uid = userCredential.user.uid;
      await createUser({ ...values, phoneNumber }, "customer", uid);
      return redirectUser(uid);
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setLoad(false)
        message.error("El email ya esta en uso");
      } else {
        setLoad(false)
        message.error(error.message);
      }
    }
  };

  const registerGoogle = async () => {
    try {
      const userCredential = await loginWithGoogle();
      const uid = userCredential.user.uid;
      const user = await getUser(uid);
      const userExists = user.exists();      
      if (userExists) {
        if(!user.data().isActive) {
          return;
        }
        return redirectUser(uid);
      }
      const values = {
        email: userCredential.user.email,
        fullName: userCredential.user?.displayName || null
      }
      await createUser(values, "customer", uid);
      return redirectUser(uid);
    } catch (error) {
      message.error(error, 7);
    }
  };

  // const registerFacebook = async () => {
  //   try {
  //     const userCredential = await loginWithFacebook();
  //     const uid = userCredential.user.uid;
  //     const user = await getUser(uid);
  //     const userExists = user.exists();
  //     if (userExists) {
  //       if(!user.data().isActive) {
  //         return;
  //       }
  //       return redirectUser(uid);
  //     }
  //     const values = {
  //       email: userCredential.user.email,
  //       fullName: userCredential.user?.displayName || null
  //     }
  //     await createUser(values, "customer", uid);
  //     return redirectUser(uid);
  //   } catch (error) {
  //     message.error(error, 7);
  //   }
  // };

  return (
    <div className="containerRegister">
      <div className="containerRegisterForm">
        <div className="registerForm">
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
          >
            <div className="registerForm-row">
              <div className="registerForm-col">
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese su email",
                    },
                  ]}
                  normalize={(value) => (value || "").toLowerCase()}
                >
                  <Input className="inputRegister" id="emailInput" />
                </Form.Item>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese su contraseña",
                    },
                    {
                      validator: passwordValidator,
                    },
                  ]}
                >
                  <Input.Password className="inputRegister" />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirma tu contraseña"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Ingrese su contraseña",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Las contraseñas no coinciden")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password className="inputRegister" />
                </Form.Item>
                <Form.Item
                  label="Número télefonico"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese su número de télefono",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject();
                        }
                        if (isNaN(value)) {
                          return Promise.reject("Solo debe contener números");
                        }
                        if (value.toString().length !== 10) {
                          return Promise.reject(
                            "El número debe ser de 10 dígitos"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    className="inputRegister"
                    prefix="+52"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="registerForm-col containerFooter">
              <Form.Item style={{ marginBottom: 0 }}>
                <Button className="btnRegisterR" htmlType="submit">
                  Crea una cuenta
                </Button>
              </Form.Item>
              <Divider style={{ backgroundColor: "#8338EC" }} />
              <Form.Item style={{ marginBottom: 0 }}>
                <Link to="/phone-sign-up" state={{ from: { pathname: location.state?.from?.pathname }}}>
                  <Button
                    className="btnLoginSocialR"
                    style={{ marginTop: 0 }}
                    icon={<img src={phone} alt="google-logo" style={{ heigth: 35, width: 35, paddingRight: "10px", paddingBottom: "0px" }} />}
                  >
                    Regístrate con tu número telefónico
                  </Button>
                </Link>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  className="btnLoginSocialR"
                  onClick={registerGoogle}
                  icon={<img src={googleLogo} alt="google-logo" style={{ heigth: 35, width: 35, paddingRight: "10px", paddingBottom: "3px" }} />}
                >
                  Registrar con Google
                </Button>
              </Form.Item><br></br>
              {/* <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  className="btnLoginSocialR"
                  style={{ marginTop: 0 }}
                  onClick={registerFacebook}
                  icon={<img src={facebookLogo} alt="fb-logo" style={{ heigth: 35, width: 35, paddingRight: "10px", paddingBottom: "3px" }} />}
                >
                  Registrar con Facebook
                </Button>
              </Form.Item> */}
            </div>
          </Form>

        </div>
      </div>
    </div>
  )
};;

export default Register;